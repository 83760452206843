@font-face {
  font-family: 'Avenir-Medium-Installed';
  src:
    url('../font/Avenir-Medium/Avenir-Medium.woff2') format('woff2'),
    url('../font/Avenir-Medium/Avenir-Medium.woff') format('woff'),
    url('../font/Avenir-Medium/Avenir-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir-Heavy-Installed';
  src: url('../font/Avenir-Heavy/Avenir-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Verdana-Installed';
  src:
    url('../font/Verdana/Verdana.eot?#iefix') format('embedded-opentype'),
    url('../font/Verdana/Verdana.woff2') format('woff2'),
    url('../font/Verdana/Verdana.woff') format('woff'),
    url('../font/Verdana/Verdana.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Max Widths
$mobile: 481px;
$mobile-large: 641px;
$tablet: 961px;
$laptop: 1025px;
$desktop: 1281px;

$mobile-media: 'only screen and (max-width:#{$mobile})';
$mobile-large-media: 'only screen and (max-width:#{$mobile-large})';
$tablet-media: 'only screen and (max-width:#{$tablet})';
$laptop-media: 'only screen and (max-width:#{$laptop})';
$desktop-media: 'only screen and (max-width:#{$desktop})';
$desktop-large-media: 'only screen and (min-width:#{$desktop})';

* {
  font-family: 'Avenir', 'Lato', 'Avenir-Heavy-Installed', 'Avenir-Medium-Installed', 'Arial';
  // font-weight: bold;
  // font-weight: 500;
  box-sizing: inherit;
}

:focus {outline:0 !important;}

html {
  overscroll-behavior: none !important; /*   <--- the trick    */
  margin: 0;
  padding: 0;
  // height: 100%;
  box-sizing: border-box;
  background: #282828;
  width: 100% !important;

  height: 100% !important;
  min-height: 100% !important;
  // min-height: calc(100% + env(safe-area-inset-top)) !important;
  overflow: hidden !important; /* Prevents the address bar from hiding on scroll */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

body {
  // overflow: scroll !important;
  // height: 100vh !important;

  margin: 0;
  color: white;
  width: 100%;
  // height: 100%;
  // background: linear-gradient(90deg, #ffaa66, #ff5e62) !important;
  // min-height: 100% !important;
  // height: 100vh !important;
  // background: linear-gradient(-0.1turn, rgba(204, 17, 102, .72), #282828, #282828) !important;

  height: 100% !important; /* Ensures the body takes up the full screen height */
  // position: fixed; /* Prevents overscroll bounce */
  // overflow-y: scroll; /* Enables vertical scrolling */
  // -webkit-overflow-scrolling: touch; /* iOS velocity scrolling */
  overflow: hidden !important;
}

.spacer {
  padding: 2.5vh !important;
}

.text-align-left {
  text-align: left !important;
  float: left !important;
  padding-right: 1% !important;
}

.text-align-right {
  text-align: right !important;
  float: right !important;
  padding-left: 1% !important;
}

.mask-bg-3 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100% !important;
  max-height: 110vh;
  width: 100% !important;
  z-index: -1;
  background: #282828 !important;
  // background: linear-gradient(-0.1turn, rgba(204, 17, 102, 1), #282828, #282828) !important;
  // background: radial-gradient(110em at bottom right, #cc1166, #282828, #282828) !important;

  @media #{$tablet-media} {
    // background: radial-gradient(63em at bottom right, #cc1166, #282828, #282828) !important;
  }

  @media #{$mobile-large-media} {
    // background: radial-gradient(63em at bottom right, #cc1166, #282828, #282828) !important;
  }
}

::-webkit-scrollbar {
  display: none;
}

.mobile {
  @media #{$mobile-media} {
    text-align: center !important;
    margin: 0;
    padding: 0;
    width: 100%
  }
}

.mobile-large {
  @media #{$mobile-large-media} {
    text-align: center !important;
    margin: 0;
    padding: 0;
    width: 100%
  }
}

.full-width {
  width: 100% !important;
}

.tablet-full-width {
  @media #{$tablet-media} {
    width: 100% !important;
    text-align: center !important;
  }
}

.tablet {
  @media #{$tablet-media} {
    text-align: left;
  }
}

.laptop {
  @media #{$laptop-media} {
    text-align: left;
  }
}

.desktop {
  @media #{$desktop-media} {
    text-align: left;
  }
}

.desktop-large {
  @media #{$desktop-large-media} {
    text-align: left;
  }
}

.mobile-responsive {
  @extend .mobile;
  @extend .mobile-large;
}

.responsive {
  @extend .mobile-responsive;
  @extend .tablet;
  @extend .laptop;
  @extend .desktop;
  @extend .desktop-large;
}

.dot-flashing {
  width: 12px !important;
  height: 12px !important;;
  border-radius: 6px;
  background-color: rgba(204,17,102,.8);

  color: rgba(204,17,102,.8);
  animation: dotFlashing .75s infinite linear alternate;
  animation-delay: .25s;

  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 40% !important;
  -webkit-transform: translateY(-40%) !important;
  -ms-transform: translateY(-40%) !important;
  transform: translateY(-40%) !important;
  margin: 0 auto !important;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -18px;
  width: 13px;
  height: 13px;
  border-radius: 6.5px;
  background-color: rgba(204,17,102,.8);
  color: rgba(204,17,102,.8);
  animation: dotFlashing .75s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 18px;
  width: 13px;
  height: 13px;
  border-radius: 6.5px;
  background-color: rgba(204,17,102,.8);
  color: rgba(204,17,102,.8);
  animation: dotFlashing .75s infinite alternate;
  animation-delay: .75s;
}

@keyframes dotFlashing {
  0% {
    background-color: rgba(204,17,102,.8);
  }
  50%,
  100% {
    background-color: #fff;
  }
}

.modal {
  .dot-flashing {
    top: 50% !important;
    -webkit-transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
    margin: 0 auto !important;
  }
}

.fit-height {
  height: fit-content !important;
}

.super-super-nano {
  height: 1.4em !important;

  .dot-flashing {
    width: 7px !important;
    height: 7px !important;;
  }

  .dot-flashing::before {
    left: -10px !important;
    width: 7px !important;
    height: 7px !important;
  }

  .dot-flashing::after {
    left: 10px !important;
    width: 7px !important;
    height: 7px !important;
  }
}

.super-nano {
  height: fit-content !important;
}

.super-nano {
  border-radius: 5px !important;
  height: 5vh !important;
}

.nano {
  height: 120px !important;
  @media #{$tablet-media} {
    height: 105px !important;
  }

  @media #{$mobile-large-media} {
    height: 95px !important;
  }

  // .fantastic-card-row.card-type-loader {
  //   .fantastic-card {
  //     border: 2px solid transparent !important;
  //     border-top: 2px solid white !important;
  //     border-radius: 50% !important;
  //     width: 35px !important;
  //     height: 35px !important;
  //     padding-bottom: 2% !important;
  //     background: none !important;
  //   }
  // }

  .fantastic-card-collection {
    .fantastic-card-row.card-type-promo {
      .fantastic-card {
        background: none !important;
        padding-bottom: 1% !important;
      }
    }
  }
}

.micro {
  height: 17vh !important;
  // .fantastic-card-row.card-type-loader {
  //   .fantastic-card {
  //     border: 5px solid transparent !important;
  //     border-top: 5px solid white !important;
  //     border-radius: 50% !important;
  //     width: 70px !important;
  //     height: 70px !important;

  //     @media #{$tablet-media} {
  //       border: 5px solid transparent !important;
  //       border-top: 5px solid white !important;
  //       width: 60px !important;
  //       height: 60px !important;
  //     }

  //     @media #{$mobile-large-media} {
  //       border: 4px solid transparent !important;
  //       border-top: 4px solid white !important;
  //       width: 48px !important;
  //       height: 48px !important;
  //     }

  //     @media #{$mobile-media} {
  //       width: 35px !important;
  //       height: 35px !important;
  //     }
  //   }
  // }

  .fantastic-card-row.card-type-promo{
    .fantastic-card {
      background: none !important;
    }
  }
}

.mini {
  height: 32vh !important;
  // .fantastic-card-row.card-type-loader {
  //   .fantastic-card {
  //     border: 8px solid transparent !important;
  //     border-top: 8px solid white !important;
  //     border-radius: 50% !important;
  //     width: 135px !important;
  //     height: 135px !important;

  //     @media #{$tablet-media} {
  //       border: 12px solid transparent !important;
  //       border-top: 12px solid white !important;
  //       width: 115px !important;
  //       height: 115px !important;
  //     }

  //     @media #{$mobile-large-media} {
  //       border: 8px solid transparent !important;
  //       border-top: 8px solid white !important;
  //       width: 100px !important;
  //       height: 100px !important;
  //     }

  //     @media #{$mobile-media} {
  //       width: 80px !important;
  //       height: 80px !important;
  //     }
  //   }
  // }
}

.small {
  height: 40vh !important;
  // .fantastic-card-row.card-type-loader {
  //   .fantastic-card {
  //     border: 10px solid transparent !important;
  //     border-top: 10px solid white !important;
  //     border-radius: 50% !important;
  //     width: 170px !important;
  //     height: 170px !important;

  //     @media #{$tablet-media} {
  //       border: 12px solid transparent !important;
  //       border-top: 12px solid white !important;
  //       width: 150px !important;
  //       height: 150px !important;
  //     }

  //     @media #{$mobile-large-media} {
  //       border: 8px solid transparent !important;
  //       border-top: 8px solid white !important;
  //       width: 115px !important;
  //       height: 115px !important;
  //     }

  //     @media #{$mobile-media} {
  //       width: 100px !important;
  //       height: 100px !important;
  //     }
  //   }
  // }
}

.medium {
  height: 47vh !important;
  // .fantastic-card-row.card-type-loader {
  //   .fantastic-card {
  //     top: 25% !important;
  //     border: 12px solid transparent !important;
  //     border-top: 12px solid white !important;
  //     border-radius: 50% !important;
  //     width: 205px !important;
  //     height: 205px !important;

  //     @media #{$tablet-media} {
  //       border: 12px solid transparent !important;
  //       border-top: 12px solid white !important;
  //       width: 165px !important;
  //       height: 165px !important;
  //     }

  //     @media #{$mobile-large-media} {
  //       border: 8px solid transparent !important;
  //       border-top: 8px solid white !important;
  //       width: 130px !important;
  //       height: 130px !important;
  //     }

  //     @media #{$mobile-media} {
  //       width: 120px !important;
  //       height: 120px !important;
  //     }
  //   }
  // }
}

.large {
  height: 55vh !important;
  // .fantastic-card-row.card-type-loader {
  //   .fantastic-card {
  //     top: 30% !important;
  //     border: 15px solid transparent !important;
  //     border-top: 15px solid white !important;
  //     border-radius: 50% !important;
  //     width: 240px !important;
  //     height: 240px !important;

  //     @media #{$tablet-media} {
  //       border: 12px solid transparent !important;
  //       border-top: 12px solid white !important;
  //       width: 200px !important;
  //       height: 200px !important;
  //     }

  //     @media #{$mobile-large-media} {
  //       border: 8px solid transparent !important;
  //       border-top: 8px solid white !important;
  //       width: 160px !important;
  //       height: 160px !important;
  //     }

  //     @media #{$mobile-media} {
  //       width: 140px !important;
  //       height: 140px !important;
  //     }
  //   }
  // }
}

.x-large-3 {
  height: 64.5vh !important;
}

.x-large-2 {
  height: 62vh !important;
  // .fantastic-card-row.card-type-loader {
  //   .fantastic-card {
  //     top: 30% !important;
  //     border: 15px solid transparent !important;
  //     border-top: 15px solid white !important;
  //     border-radius: 50% !important;
  //     width: 240px !important;
  //     height: 240px !important;

  //     @media #{$tablet-media} {
  //       border: 12px solid transparent !important;
  //       border-top: 12px solid white !important;
  //       width: 200px !important;
  //       height: 200px !important;
  //     }

  //     @media #{$mobile-large-media} {
  //       border: 8px solid transparent !important;
  //       border-top: 8px solid white !important;
  //       width: 160px !important;
  //       height: 160px !important;
  //     }

  //     @media #{$mobile-media} {
  //       width: 140px !important;
  //       height: 140px !important;
  //     }
  //   }
  // }
}

.x-large {
  height: 70vh !important;

  .fantastic-card {
    .cardList {
      .titles {
        .title {
          padding: .5% !important;
        }
      }
    }
  }

  // .fantastic-card-row.card-type-loader {
  //   .fantastic-card {
  //     top: 35% !important;
  //     border: 15px solid transparent !important;
  //     border-top: 15px solid white !important;
  //     border-radius: 50% !important;
  //     width: 240px !important;
  //     height: 240px !important;

  //     @media #{$tablet-media} {
  //       border: 12px solid transparent !important;
  //       border-top: 12px solid white !important;
  //       width: 200px !important;
  //       height: 200px !important;
  //     }

  //     @media #{$mobile-large-media} {
  //       border: 8px solid transparent !important;
  //       border-top: 8px solid white !important;
  //       width: 160px !important;
  //       height: 160px !important;
  //     }

  //     @media #{$mobile-media} {
  //       width: 140px !important;
  //       height: 140px !important;
  //     }
  //   }
  // }
}

// Basic Grid
.row::after {
  content: "";
  clear: both;
  display: table;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

[class*="col-"] {
  float: left;
  min-height: 1px;
}

.paypalCheckoutContainer {
  background: #efefef !important;
  border-radius: 15px !important;
  padding: 25px !important;
  margin-top: 5px !important;
  margin-bottom: 20px !important;
}

.transparent {
  background: transparent !important;

  button {
    background: transparent !important;
  }
}

p.charCounter {
  position: absolute !important;
  right: 8px !important;
  bottom: 8px !important;
  font-weight: cold !important;

  font-size: 19.5px !important;

  @media #{$tablet-media} {
    font-size: 17.5px !important;
  }

  @media #{$mobile-large-media} {
    font-size: 16px !important;
  }
}

p.valid {
  color: #66cc11 !important;
}

p.invalid {
  color: #cc1111 !important;
}

.center {
  text-align: center !important;
  p {
    text-align: center !important;
  }
}

.muted, .muted.visible {
  opacity: .87 !important;

  button {
    background: rgba(70,70,70,.37) !important;
    box-shadow: none !important;
  }
}

.muted.transparent {
  opacity: .85 !important;
  background: transparent !important;

  button {
    background: transparent !important;
  }
}

.no-background {
  background: none !important;
  background-color: transparent !important;
}

.no-display {
  display: none !important;
}

.table-cell.visible {
  display: table-cell !important;

  @media #{$tablet-media} {
    display: block !important;
  }
}

.table-cell.expanded {
  width: 80%
}

.table-cell.minimized {
  width: 14%;

  div {
    display: none !important;
  }

  button {
    img {
      margin: 0px !important;
    }
  }

  @media #{$tablet-media} {
    div {
      // display: inline-block !important;
      // font-weight: bold !important;
    }

    button {
      img {
        // margin: 5px !important;
      }
    }
  }
}

.visible {
  display: block !important;
  opacity: 1 !important;
}

.visible.no-display {
  display: none !important;
}

.hidden {
  visibility: hidden !important;
  position: absolute !important; // HACK
  z-index: -1 !important; // HACK
}

.confetti {
  width: 10px !important;
  margin: 0 auto !important;
  z-index: 10 !important;
}

.logo-visible {
  .logo {
    display: block !important;
    opacity: 1 !important;
  }
}

.logo-hidden {
  .logo {
    display: none !important;
  }
}

.card {
  display: inline-block;
  margin: 0 auto;
  background-size: cover;
}

.logo {
  text-align: center;
  background: none !important;

  .logo-fan {
    // background-color: #ff5e62;
    background-color: #cc1166;
    padding: 2.5px !important;
    margin-right: .5px !important;
    border-radius: 4px;
    text-shadow: none !important;
  }

  .logo-insights {
    // background-color: #ff5e62;
    background-color: #cc1166;
    font-family: 'Verdana-Installed', 'Verdana' , 'Lato', 'Arial' !important;
    margin-left: 5px !important;
    border-radius: 4px;
    text-shadow: none !important;
    padding: 2.5px !important;
    font-weight: 900 !important;
    font-style: italic !important;
  }

  h4 {
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    padding: 0px;
    margin: 0px;
    margin-left: 3px;
  }

  h3 {
    padding: 0px;
    font-size: 22px;
    font-weight: 500;
    display: inline-block;
    margin: 0px;
    font-family: 'Verdana-Installed', 'Verdana', 'Lato', 'Arial' !important;
    text-align: center !important;
  }

  @media #{$tablet-media} {
    margin: 0px;
    padding: 0px;

    h4 {
      font-size: 11px;
      display: inline-block;
      margin-left: 3px;
    }
  }

  @media #{$mobile-large-media} {
    h4 {
      font-size: 7px;
      display: inline-block;
      margin-left: 3px;
    }
    h3 {
      font-size: 13px;
      display: inline-block;
      margin-top: 0px;
    }
  }
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: none !important;
}

button:focus {outline:0;}
input:focus {outline:0;}

button:hover {
  // text-shadow: 1px 1px 3px #fff !important;
}

button.iOSPWA-cancel {
  width: auto !important;
}

div.upload-file {
  padding-top: 7px !important;
  padding-bottom: 10px !important;
}

.legal-terms, .legal-privacy, .legal-copyright, .contact, .faq, .product-walkthrough, .documentation {
  height: 100%;
  overflow: scroll !important;
  padding-left: 25%;
  padding-right: 25%;

  h1, h2{
    padding-left: 5px !important;
    padding-right: 5px !important;

    // margin: 1%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;

    display: inline-block;
    border-radius: 5px;
    font-size: 31.5px;
    font-weight: bold !important;
    // background-color: #ff5e62;
    background-color: #cc1166;

    color: white !important;
  }

  @media #{$tablet-media} {
    height: 95%;
    padding: 6% !important;
    padding-top: 3% !important;

    h2, h3 {
      margin-top: 3%;
      margin-bottom: 3%;
    }
    p, li {
      font-size: 11px !important;
      margin-top: 2.5% !important;
      margin-bottom: 2.5% !important;
    }

    h1, h2{
      font-size: 29.5px !important;
    }
  }

  @media #{$mobile-large-media} {
    h1, h2{
      font-size: 27px !important;;
    }
  }

  h3 {
    text-align: left;
    text-decoration: underline;
  }

  h4 {
    text-align: left;
    text-decoration: underline;
  }

  h5 {
    text-align: left;
  }

  p, li {
    margin-top: .5%;
    margin-bottom: .5%;
    font-size: 15px;
  }
  li {
    text-align: left;
  }

  a {
    cursor: pointer;
    // color: #ee3d51;
    color: #dd2277;
    font-weight: bolder;
  }

  a:hover {
    text-decoration: underline !important;
  }
}

.faq, .contact, .product-walkthrough, .documentation {
  h3 {
    padding-top: 0 !important;
    margin-top: 3vh !important;
    margin-bottom: 2vh !important;
    text-decoration: none !important;
    font-size: 30px !important;
    font-weight: bold !important;
  }

  p {
    font-weight: #444 !important;
    font-size: 18px !important;
    margin-bottom: 20px !important;
    color: #dadada !important;
  }

  p.disclaimer {
    // margin-top: 30px !important;
    // font-size: 15px !important;
    // color: #bbb !important;
  }

  @media #{$tablet-media} {
    h3 {
      font-size: 27px !important;
      font-weight: bold !important;
    }

    p {
      font-weight: bold !important;
      font-size: 16px !important;
      margin-bottom: 20px !important;
    }

    p.disclaimer {
      // margin-top: 30px !important;
      // font-size: 13px !important;
      // color: #ccc !important;
    }
  }

  @media #{$mobile-large-media} {
    h3 {
      font-size: 25px !important;
      font-weight: bold !important;
    }

    p {
      font-weight: bold !important;
      font-size: 15px !important;
      margin-bottom: 20px !important;
    }

    p.disclaimer {
      // margin-top: 30px !important;
      // font-size: 12px !important;
      // color: #ccc !important;
    }
  }
}

.fantastic-header{
  // display: none !important;

  z-index: 1;

  position: fixed !important;
  top: 0vh !important;
  left: 0vh !important;
  right: 0vh !important;
  // margin-top: 1.5vh !important;
  // margin-right: 1.5vh !important;
  // margin-left: 1.5vh !important;

  text-align: left !important;
  margin: 1vh !important;
  margin-top: 0vh !important;
  margin-left: 0vh !important;
  margin-right: 0vh !important;
  padding-left: 1vh !important;
  padding-right: 1vh !important;
  padding-top: .75vh !important;
  padding-bottom: .75vh !important;
  // border-bottom: 1px solid #333 !important;
  // padding: 1vh;

  // background: #202020 !important;

  @media #{$tablet-media} {
    z-index: 1000 !important;
    background: #202020 !important;
  }

  @media #{$mobile-large-media} {
    // width: 100%;
  }

  .options {
    // position: absolute !important;
    // right: 0 !important;
    // top: 0 !important;

    float: right !important;
    background: none !important;
    border-radius: 9px !important;
    width: fit-content !important;
    opacity: 1 !important;
    display: inline-block !important;

    .option {
      display: inline-block;

      img {
        box-shadow: none !important;
        // background: rgba(0,0,0, .25) !important;
        background: none !important;
        display: inline-block !important;
        margin: 0 auto !important;
        padding: 3.25px !important;
        border-radius: 20%;

        height: 28px !important;
        width: 28px !important;
        vertical-align: middle !important;
      }

      margin-top: 0px !important;
      padding-top: 3px !important;
      padding-bottom: 3px !important;

      padding-left: 5px !important;
      padding-right: 5px !important;
      // background: rgba(150, 150, 150, 0.1) !important;
      border-radius: 10px !important;
      font-weight: bold !important;

      text-align: center;
      cursor: pointer;
      font-size: 19.05px !important;

      // vertical-align: top !important;
      margin-right: 7px !important;
    }

    .option.show-background {
      // background: rgba(0,0,0,.3) !important;
      // border-radius: 10px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      background: #cc1166 !important;
      font-weight: bold !important;
    }

    .logo.option {
      padding: 0px !important;
      background: none !important;
    }

    .option:last-of-type {
      // padding-right: 0px !important;
      margin-right: 0px !important;
    }

    @media #{$tablet-media} {
      .option {
        padding-top: 1px !important;
        padding-bottom: 1px !important;

        img {
          // background: rgba(0,0,0, .25) !important;
          width: 25px !important;
          height: 25px !important;
          // padding: 5px !important;
        }

        font-size: 18px !important;
        border-radius: 8px !important;
      }
    }

    @media #{$mobile-large-media} {
      .option {
        img {
          // background: rgba(0,0,0, .25) !important;
          width: 22px !important;
          height: 22px !important;
          // padding: 5px !important;
        }

        font-size: 13px !important;
        border-radius: 6px !important;
      }
    }
  }

  .options.header {
    left: 0 !important;
    right: auto !important;
    position: relative !important;
    float: none !important;
  }

  .logo {
    // width: auto !important;
    // position: absolute !important;
    // right: auto !important;
    // left: 0 !important;
    // top: 0 !important;

    h3 {
      border-radius: 8px !important;
      font-size: 25px !important;
    }

    @media #{$tablet-media} {
      h3 {
        border-radius: 6px;
        font-size: 20px !important;
      }
    }

    @media #{$mobile-large-media} {
      h3 {
         font-size: 15px !important;
      }
    }
  }
}

.fantastic-footer{
  min-width: 260px;

  position: fixed;
  margin: 0 auto;
  // padding: 5px;
  // padding-left: 10px;
  // padding-right: 10px;
  bottom: 1vh;
  left: 1vh;
  right: 1vh;

  @media #{$tablet-media} {
    bottom: 1.5%;
  }

  .brand {
    margin-top: 3px !important;
    display: inline-block;
    float: left !important;

    @media #{$tablet-media} {
      width: 100%;
      text-align: center;
      h3 {
        padding: 0px !important;
      }
    }

    h3 {
      font-size: 14px;
      display: inline-block;
      padding: 0px;
      margin: 0px;
      // padding: 7px !important;
    }

    @media #{$mobile-large-media} {
      h3 {
        font-size: 12px;
        display: inline-block;
        padding: 0px;
        margin-top: 0px;
      }
    }
  }

  .options {
    position: relative !important;
    text-align: center !important;
    float: right !important;

    @media #{$tablet-media} {
      width: auto !important;
      float: none !important
    }

    display: block !important;
    font-size: 16px;

    @media #{$mobile-large-media} {
      font-size: 12px;
    }

    .option {
      display: inline-block !important;
      text-align: center !important;
    }
  }
}

.inputImages {
  white-space: nowrap !important;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 80px;
  width: 100%;
  border-radius: 2px;
  overflow-x: scroll;
  overflow-y: hidden;

  background: rgba(0,0,0,.25) !important;
  padding: 1%;

  .inputImage {
    cursor: pointer;
    padding: 5px;
    background: #000;
    height: 75px !important;
    display: inline-block;
    overflow: hidden;

    img {
      height: 70px;
      // box-shadow: 1px 1px 5px #222;
    }
  }

  .inputImage.selected {
    border: solid 3px #fff;
  }

  @media #{$tablet-media} {
    height: 65px;
    .inputImage {
      height: 60px;
      img {
        height: 55px;
      }
    }
  }
}

.inputPost {
  display: inline-block !important;
  border-radius: 5px;
  cursor: pointer;
  width: 100% !important;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat no-repeat !important;
}

.inputPosts {
  scroll-snap-type: x mandatory;
  border-radius: 0px !important;
  margin-bottom: 12px !important;

  white-space: nowrap;

  @media #{$mobile-large-media} {
    margin-bottom: 8px !important;
  }

  p {
    padding: 2px;
  }
}

.inputLink {
  display: block !important;
  padding: 1px;
  border-bottom: 1px solid rgba(100,100,100,.6);
}

.inputLinks, .inputPosts{
  width: 100%;
  border-radius: 15px !important;
}

.inputSuggestions, .inputPosts {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.card-type-info {
  .inputSuggestion {
    background: rgba(75,75,75,.4) !important;
  }
}

.inputSuggestion {
  // box-shadow: 1px 1px 5px #222 !important;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  border-radius: 9px !important;
  padding: 9% !important;
  padding-top: 7vh !important;
  padding-bottom: 2% !important;
  // text-shadow: #444 0px 0px 5px;
  // margin: 8px !important;
  margin-right: 6px !important;

  font-size: 28px !important;

  @media #{$tablet-media} {
    border-radius: 10px !important;
    margin: 3px !important;
    margin-right: 1px !important;
    font-size: 20px !important;
    .plus {
      font-size: 25px;
    }
  }

  @media #{$mobile-media} {
    border-radius: 8px !important;
    font-size: 25px !important;
    .plus {
      font-size: 16px;
    }
  }

  .plus {
    font-size: 20px;
  }

  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat no-repeat !important;

  p {
    opacity: .38 !important;
    font-size: 10.5px !important;
    text-align: center !important
  }

  // background: rgba(0,0,0,.4) !important;
  background: rgba(90,90,90,.4) !important;
}

.inputSuggestion.isUser {
  width: 90% !important;
}

.inputLink {
  width: 100% !important;
  white-space: normal !important;
  overflow: hidden;
  position: relative;

  .inputImage {
    cursor: pointer;
    padding: 5px;
    background: #000;
    height: 76% !important;
    width: 28% !important;
    margin-right: 1.5%;
    display: inline-block;
    overflow-x: hidden;
    overflow-y: hidden;

    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat no-repeat !important;

    position: absolute;
    left: 0;
    border-radius: 5px;

    img {
      height: 70px;
      // box-shadow: 0px 0px 8px 0px #999;
    }
  }

  .info {
    width: 68% !important;
    margin-left: 32% !important;
    overflow-x: scroll;
  }
};

.inputLink, .inputPost {
  cursor: pointer;
  font-weight: bold;
  margin-right: 3px;
  color: white;
  overflow: hidden;

  font-size: 24px;
  @media #{$tablet-media} {
    font-size: 20px;
  }
  @media #{$mobile-large-media} {
    font-size: 20px;
  }
  @media #{$mobile-media} {
    font-size: 20px;
  }

  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat no-repeat !important;

  padding: 8px;

  h3 {
    font-size: 17px;
    margin: 0;
    padding-top: 4px;
    padding-bottom: 3px;
  }

  p.link {
    font-size: 12px !important;
    text-decoration: underline;
    margin-bottom: 10px;
    opacity: .8;
  }

  p.description {
    display: none !important;
  }

  p {
    font-size: 14px;
  }

  padding-top: 10px;
  padding-bottom: 10px;

  @media #{$mobile-large-media} {
    padding: 5px;

    h3 {
      font-size: 15px;
      margin: 0;
      padding-top: 4px;
      padding-bottom: 1px;
    }

    p.link {
      font-size: 11px;
      text-decoration: underline;
      margin-bottom: 5px;
      opacity: .7;
    }

    p {
      font-size: 11px;
    }

    padding-top: 7px;
    padding-bottom: 7px;
  }
}

p.row.input {
  color: #dfdfdf !important;
  // opacity: .8 !important;
  padding-top: 25px !important;
  padding-bottom: 3px !important;
  font-weight: bold !important;
}

p.row.input.highlight {
  // color: #cc1166 !important;
  color: #fff !important;
}

.linkInfo {
  display: none !important;
  // margin-top: 3px !important;
  margin-bottom: 3px !important;
  position: relative !important;

  overflow-x: scroll !important;
  white-space: nowrap !important;

  .linkIconImage {
    left: 0px !important;
    margin-left: 0px !important;
  };

  p.link {
    margin-left: 23px !important;
    // margin-top: 1% !important;
    color: #ccc !important;
    text-transform: lowercase !important;
    cursor: pointer !important;
    font-size: 16.5px !important;
    line-break: anywhere !important;

    @media #{$tablet-media} {
      margin-left: 19px !important;
      font-size: 15.5px;
    }
    @media #{$mobile-large-media} {
      font-size: 13.5px !important;
    }
  }

  p.link:hover {
    // text-decoration: underline !important;
  }
}
.price {
  color: #66CC11 !important;
}

.price-pending {
  color: #1166CC !important;
}

.price-completed {
  color: #CCC !important;
}

.linkIcon, .priceIcon {
  height: fit-content !important;

  img {
    height: 16.5px !important;
  }

  @media #{$tablet-media} {
    img {
      height: 12px !important;
      right: 4px;
    }
    // font-size: 18px;
  }

  @media #{$mobile-large-media} {
    img {
      height: 13px !important;
    }
  }

  @media #{$mobile-media} {
    img {
      height: 13px !important;
    }
    // left: 4px !important;
    // font-size: 17px;
  }

  position: absolute;
  font-weight: bold;
  color: white;
  padding-right: 2px;
  text-shadow: none;

  cursor: pointer;

  left: 0px !important;
  margin-left: 10px !important;

  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.linkIconImage {
  position: absolute !important;
  left: 0px !important;
  margin-left: 10px !important;

  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;

  height: 16.5px !important;

  @media #{$tablet-media} {
    height: 12px !important;
    right: 4px;
  }

  @media #{$mobile-large-media} {
    height: 13px !important;
  }

  @media #{$mobile-media} {
    height: 13px !important;
  }
}

.tagField {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: scroll;
  text-align: left;

  margin-top: 7px;
  margin-bottom: 7px;

  @media #{$tablet-media} {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .options {
    border-radius: 3px !important;
    margin: 0 auto !important;
    margin-top: 1.5% !important;
  }

  .cardTags {
    vertical-align: top;
    .cardInput {
      width: 100% !important;
      div.tag {
        background: rgba(0,0,0,.25) !important;
      }
    }
  }

  .inputSuggestions {
    position: relative !important;
    text-align: center !important;
    white-space: nowrap;
    overflow-x: scroll;
    height: fit-content;

    .inputSuggestion {
      margin-left: 3% !important;
      margin-right: 3% !important;
      margin-top: 2.8% !important;
      margin-bottom: 2.8% !important;
      padding: 4% !important;
      padding-top: 3% !important;
      padding-bottom: 3% !important;
    }

    border-radius: 15px !important;
    background: rgba(0,0,0,.25) !important;

    .cardPreview {
      display: block !important;
      position: relative !important;

      top: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      right: 0 !important;
      height: 100%;
      z-index: -1 !important;
      .mask-bg, .mask-bg-2, .mask-bg-mobile {
        display: none !important;
      }
    }

    margin-top: 10px;
  }

  .cardInputField {
    .inputSuggestions {
      height: auto !important;
      overflow: hidden !important;
    }
    width: 100% !important;
    margin: 0 !important;
  }

  .filterBar {
     position: relative !important;
     display: table;

     padding-left: 0px;
     padding-right: 0px;
     padding-top: 4px !important;
     padding-bottom: 4px !important;

     border-radius: 10px;
     width: 100%;

     @media #{$tablet-media} {
       padding-top: 1px !important;
       padding-bottom: 1px !important;
     }

     @media #{$mobile-media} {
       padding-left: 0px;
       padding-right: 0px;
     }

    .cardTags {
      padding: 0 !important;
      margin: 0 !important;
      direction: rtl;
      float: left;
      max-width: 0% !important;
      width: fit-content !important;
      display: block !important;
    }

    .cardTagsField {
      display: inline-block;
      transition: max-width .05s linear;
      float: left;
      margin: 4px !important;
      margin-left: 0px !important;
      margin-right: 0px !important;

      .clearButton {
        display: none;
      }

    }

    input {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      box-shadow: none !important;
      background: none !important;
    }

    background: rgba(0,0,0,.25) !important;

    .searchIcon {
      img {
        height: 17.5px !important;
      }
      @media #{$tablet-media} {
        img {
          height: 12px !important;
          right: 4px;
        }
        font-size: 18px;
      }
      @media #{$mobile-large-media} {
        img {
          height: 13px !important;
        }
      }
      @media #{$mobile-media} {
        img {
          height: 13px !important;
        }
        // left: 4px !important;
        font-size: 17px;
      }

      position: absolute;
      font-weight: bold;
      color: white;
      padding-right: 2px;
      text-shadow: none;

      cursor: pointer;
      font-size: 23px;

      left: 6px !important;
      margin-left: 4px !important;

      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .homeButton {
      img {
        height: 16px !important;
      }
      @media #{$tablet-media} {
        img {
          height: 12px !important;
          right: 4px;
        }
        font-size: 18px;
      }
      @media #{$mobile-large-media} {
        img {
          height: 13px !important;
        }
      }
      @media #{$mobile-media} {
        img {
          height: 13px !important;
        }
        right: 2px;
        font-size: 17px;
      }

      position: absolute;
      font-weight: bold;
      color: white;
      padding-right: 2px;
      text-shadow: none;

      cursor: pointer;
      font-size: 23px;

      right: 10px;
      margin-left: 4px !important;

      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .clearButton {
      position: absolute;
      font-weight: bold;
      color: white;
      // padding-right: 5px;
      text-shadow: none;

      cursor: pointer;
      font-size: 23px;

      right: 12px;

      @media #{$tablet-media} {
        img {
          height: 12px;
        }
        right: 10px;
        font-size: 18px;
      }

      @media #{$mobile-media} {
        img {
          height: 12px;
        }
        right: 10px;
        font-size: 17px;
      }

      margin-left: 4px !important;

      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .filterBar.withTags {
    .cardTags {
      max-width: 52% !important;
    }

    .cardTagsField {
      max-width: 40% !important;
    }
    input {
      text-align: left !important;
      padding-left: 0 !important;
    }
  }

  .filterBar.expand{
    .cardTagsField {
      max-width: 100% !important;
    }
  }

  .filterBar.expand.withTags {
    .cardTags {
      max-width: 32% !important;
    }

    .cardTagsField {
      max-width: 60% !important;
    }

  }

  .cardDismiss-top {
    display: none !important;
  }

  .cardDismiss-bottom {
    display: none !important;
  }
}

.tagField.center {
  input {
    text-align: left !important;
    padding-left: 40px !important;

    @media #{$mobile-large-media} {
      padding-left: 13px !important;
    }
  }

  input:focus {
    text-align: left !important;
    padding-left: 0px !important;
  }

  input:focus::-webkit-input-placeholder {
    color: transparent !important;
  }
}

.tagField.not-centered {
  input {
    text-align: left !important;
    padding-left: 5px !important;
  }

  input:focus {
    text-align: left !important;
    padding-left: 5px !important;
  }
}

.showLinkIcon, .showPriceIcon {
  // width: 50% !important;
  input, input:focus {
    padding-left: 35px !important;
  }

  @media #{$tablet-media} {
    input, input:focus {
      padding-left: 30px !important;
    }
  }
}

.tagField.showSearchIcon {
  input, input:focus {
    padding-left: 45px !important;
  }

  .filterBar.withTags {
    .cardTags {
      padding-left: 35px !important;
    }

    input, input:focus {
      padding-left: 0px !important;
    }
  }

  @media #{$tablet-media} {
    input, input:focus {
      padding-left: 40px !important;
    }

    .filterBar.withTags {
      .cardTags {
        padding-left: 25px !important;
      }

      input, input:focus {
        padding-left: 0px !important;
      }
    }
  }
}

.tagField.expand {
  .inputSuggestions {
    white-space: break-spaces !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }

  .inputSuggestions.nano {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }
}

.fantastic-tags{
  position: relative;
  min-width: 260px;
  width: fit-content !important;
  margin: 0 auto !important;
  // opacity: .9 !important;
  margin-top: 2.5vh !important;

  @media #{$tablet-media} {
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    margin-top: 2.5vh !important;
  }

  p:hover {
    cursor: pointer;
  }

  p {
    margin: 2px;
  }

  .cardInput {
    @media #{$tablet-media} {
      width: 100% !important;
    }
    height: 100%;

    input, textarea {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .inputSuggestions {
      display: none !important;
    }

    .cardDismiss-bottom {
      display: none !important;
      display: block;
      position: fixed;
      top: 1%;
      left: 1%;
      // right: auto;
      // bottom: 1%;

      @media #{$tablet-media} {
        top: auto;
        left: 0;
        right: 0%;
        bottom: 1.5%;
        padding-top: 15% !important;
        // background: linear-gradient(transparent, rgba(204, 17, 102, .75)) !important;
      }
    }

    .table-cell {
      height: 100%;
      button {
        height: 100% !important;
      }
    }

    .table-cell:first-of-type {
      padding-left: 0px;
      button {
        border-radius: 8px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }

    .table-cell:last-of-type {
      padding-right: 0px;
      button {
        border-radius: 8px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
    }

    @media #{$tablet-media} {
      .table-cell:first-of-type {
        padding-left: 0px;
        button {
          border-radius: 6px !important;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }

      .table-cell:last-of-type {
        padding-right: 0px;
        button {
          border-radius: 6px !important;
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }
      }
    }
  }

  .fantastic-card {
    .cardList {
      .titles {
        @media #{$tablet-media} {
           text-align: center !important;
        }
      }
    }

    .cardDropDown {
      background: none !important;
      p.row {
        display: none !important;
      }

      .selectDiv {
        float: none !important;
        width: 50% !important;
        margin-left: 24% !important;
        border: none !important;

        select {
          padding: 11px !important;
          border-radius: 20px !important;
          background: rgba(0, 0, 0, 0.30) !important;
          border: none !important;
          text-align-last: center !important;
          margin-top: 3px !important;

          font-size: 17px !important;

          @media #{$tablet-media} {
            font-size: 13px !important;
            padding: 7px;
          }

          @media #{$mobile-large-media} {
            font-size: 13px !important;
          }

          @media #{$mobile-media} {
            font-size: 13px !important;
          }
        }

        option {
          font-size: 20px !important;
          font-weight: bold !important;
          padding: 10px;
          color: #fff;
          opacity: .7;

          @media #{$tablet-media} {
            box-shadow: none !important;
            font-size: 14px !important;
            padding: 8px;
          }

          @media #{$mobile-large-media} {
            font-size: 14px !important;
            padding: 7px;
          }

          @media #{$mobile-media} {
            font-size: 14px !important;
          }
        }

        option:focus, option:active {
          outline:0 !important;
          border:none !important;
          box-shadow:none !important;
        }
      }

      .downArrow {
        right: 12px !important;
        top: 5px !important;
        font-size: 25px !important;
        opacity: .87 !important;
      }
    }
  }

  @media #{$tablet-media} {
    .options {
      float: none !important;
    }
  }

  .tagField {
    margin: 0 !important;

    .options {
      display: none !important;
    }

    .cardTags {
      div.tag.pressed {
        font-weight: bold !important;
        border: none !important;
      }
    }
  }

  .filterBar {
    // background: rgba(0,0,0,.12) !important;
    background: rgba(150,150,150,.20) !important;
    // backdrop-filter: blur(5px);
    border-radius: 12px;
  }

  button {
    border-radius: 3px;
    text-shadow: 0px 0px 3px #000;
    margin: 0px !important;

    // background-color: rgba(150,150,150,.25) !important;
    // background-color: rgba(150,150,150,.25) !important;
    font-size: 23px;

    // padding-left: 2px !important;
    // padding-right: 2px !important;

    padding: 0px !important;

    img {
      height: 12px;
    }
    @media #{$tablet-media} {
      font-size: 20px;
      img {
        height: 10px;
        max-width: 97%;
      }
    }
    @media #{$mobile-large-media} {
      box-shadow: none !important;

      font-size: 18px;
      img {
        height: 12px;
        max-width: 97%;
      }
    }
    @media #{$mobile-media} {
      font-size: 15px;
      img {
        height: 12px;
        max-width: 97%;
      }
    }

    .notification {
      margin-right: 5px;
      // background-color: #ff5e62;
      background-color: #cc1166;
      border-radius: 50%;
      height: 14px;
      width: 14px;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    @media #{$tablet-media} {
      .notification {
        height: 12px;
        width: 12px;
      }
    }

    .notification.hidden {
      visibility: hidden;
    }
  }

  .fantastic-card-collection {
    padding: 0px !important;
    margin: 0px !important;
  }

  .fantastic-card-row {
    padding: 0px !important;
    margin: 0px !important;

    .fantastic-card {
      // width: 25%;
      max-width: 625px;

      @media #{$tablet-media} {
        width: 100%;
        // padding: 6px;
        max-width: 100%;
      }

      @media #{$mobile-large-media} {
        width: 100%;
        max-width: 100%;
        // padding: 6px;
      }

      // background: transparent !important;

      .cardFooter {
        padding: 0px !important;
        margin: 0px !important;
      }

      .cardContent {
        display: none !important;
      }

      .cardMessage {
        display: none !important;
      }
    }
  }

  .options {
    // backdrop-filter: blur(10px);
    width: 82% !important;
    opacity: .985;
    border-radius: 5px;
    // background: rgba(0,0,0,.30) !important;
    // float: left !important;

    .option.feature {
      opacity: 1;
      // color: #bbb !important;
      background: rgba(0,0,0,.3o) !important;
    }

    .option {
      color: #fff !important;
      // border-left: 1px solid rgba(255,255,255,.5) !important;
      // border-right: 1px solid rgba(255,255,255,.5) !important;
      padding: 5px;
      // padding-left: 15px !important;
      // padding-right: 15px !important;
      border-radius: 8px;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      background: rgba(0,0,0,.3) !important;
    }

    .option:first-of-type {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .option:last-of-type {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    @media #{$tablet-media} {
      .option {
        border-radius: 6px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
    }
  }
}

.suggestions-visible {
  .inputSuggestions {
    display: block !important;
    background: none !important;
    border-radius: 0px !important;
    text-align: left !important;
    // position: fixed !important;

    // left: 0;
    // right: 0;
    // top: 20.5%;
    // // top: 50px;
    // bottom: 0;
    // margin-top: 0px !important;
    // // padding-bottom: 3.5% !important;

    // @media #{$tablet-media} {
    //   top: 25.5% !important;
    //   .cardDismiss-bottom {
    //     // background: linear-gradient(transparent, transparent, #ff5e62) !important;
    //     // background: linear-gradient(transparent, transparent, #282828) !important;
    //     background: linear-gradient(transparent, transparent, #cc1166) !important;
    //   }
    // }

    // background: none !important;
    .inputSuggestion {
      margin-right: 1% !important;
      margin-left: 0 !important;
      margin-top: 0% !important;
      margin-bottom: 2% !important;
      padding: 1% !important;
      // padding-top: 4.3% !important;
      // padding-bottom: 1.7% !important;
      // padding-top: 3% !important;
      // padding-bottom: 3% !important;
      font-size: 15px !important;
      // background: rgba(204, 17, 102) !important;
      // background: #ff5e62 !important;
      // background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), rgba(204,17,102,1) !important;
      // background: rgba(0,0,0,.45) !important;
    }

    .inputSuggestion.full-width {
      background: rgba(0,0,0,.35) !important;

      font-size: 25px !important;

      @media #{$tablet-media} {
        font-size: 22px !important;
      }

      @media #{$mobile-media} {
        font-size: 20px !important;
      }
    }

    // height: 95vh !important;

    @media #{$tablet-media} {
      // height: 100% !important;
      // top: 5.75% !important;

      .inputSuggestion {
        // margin-right: 3.5% !important;
        margin-top: 0% !important;
        margin-bottom: 3% !important;

        // padding: 4.5% !important;
        // padding-top: 4% !important;
        // padding-bottom: 3.8% !important;
        font-size: 13px !important;
      }

      // padding-bottom: 150px !important;
    }

    overflow-y: scroll !important;
  }
}

.fantastic-tags.suggestions-visible {
  // z-index: 1499 !important;
  // position: fixed !important;

  // height: 30vh !important;
  // padding-top: 7vh !important;

  // padding: calc(5vh + env(safe-area-inset-top)) calc(3vh + env(safe-area-inset-right)) env(safe-area-inset-bottom) calc(3vh + env(safe-area-inset-left)) !important;
  // top: 0 !important;
  // left: 0 !important;
  // right: 0 !important;
  // bottom: 0 !important;

  display: block !important;

  overflow-y: hidden !important;

  // @media #{$tablet-media} {
  //   padding: calc(15vh + env(safe-area-inset-top)) calc(3vh + env(safe-area-inset-right)) env(safe-area-inset-bottom) calc(3vh + env(safe-area-inset-left)) !important;
  //   margin: auto !important;
  //   // padding: 3vh !important;
  // }

  @media #{$mobile-large-media} {
    // padding: 2vh !important;
  }

  .mask-bg-2 {
    display: block !important;
    display: none !important;
    height: 100%;
    // background: linear-gradient(90deg, #ffaa66, #ff5e62) !important;
    // background: #282828 !important;
    background: linear-gradient(-0.1turn , #cc1166, #282828, #282828) !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .994 !important;
  }

  .cardDismiss-bottom {
    display: none !important;
  }

  .inputSuggestions {
    display: block !important;
  }
}

.fantastic-tags.options-visible {
  .inputSuggestions {
    margin-top: 2.5% !important;
    height: fit-content !important;

    @media #{$tablet-media} {
      margin-top: 3.5% !important;
      // margin-top: 8.5% !important;
    }
  }
}

.options {
  margin: 0 auto !important;
  margin-top: 3.3px !important;

  display: table;
  table-layout: fixed;

  .option {
    display: inline-block;

    img {
      // box-shadow: 1px 1px 5px #555 !important;
      display: inline-block !important;
      margin: 0 auto !important;
      background: rgba(0,0,0, .25);
      // padding: 5px;
      border-radius: 20%;
      height: 28px;
      width: 28px;
    }

    margin-top: 0px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    text-align: center;
    // margin-left: 5px;
    cursor: pointer;
    font-size: 18px !important;
    padding: 7px !important;

    .notifications {
      padding: 3px !important;
      background: #cc1166 !important;
      border-radius: 4px !important;
      font-size: 10px !important;
      margin-left: -10px !important;
    }
  }

  @media #{$tablet-media} {
    .option {
      padding-top: 1px !important;
      padding-bottom: 1px !important;

      img {
        background: none !important;
        box-shadow: none !important;
        width: 25px !important;
        height: 25px !important;
        padding: 0px !important;
      }

      font-size: 14px !important;
      padding: 5px !important;
    }
  }

  @media #{$mobile-large-media} {
    .option {

      img {
        background: none !important;
        width: 22px !important;
        height: 22px !important;
        padding: 0px !important;
      }

      font-size: 12px !important;
      padding: 5px !important;
    }
  }

  .option-account {
    border-radius: 50%;
  }

  .option {
    // opacity: .8;
  }

  .option.feature {
    opacity: 1;
  }
}

.option:hover {
  opacity: 1 !important;
  color: #fff !important;
  pointer: cursor !important;
  // text-shadow: 0px 1px 1px #fff !important;
}

.mask-bg {
  display: none !important;
}

.cardPreview {
  .fantastic-card-collection {
    margin: 0% !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0px !important;

    .fantastic-card-row.card-type-promo {
      border-radius: 0px !important;
    }
  }

  .fantastic-card-collection.active {
    scroll-snap-type: y mandatory !important;
    margin: 0% !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0px !important;
    padding-bottom: 0px !important;

    .fantastic-card-row.card-type-promo {
      border-radius: 0px !important;

      .fantastic-card {
        // background: linear-gradient(transparent, transparent, transparent, rgba(15,15,15,.98), rgba(10,10,10,1)) !important;
        .cardHeader {
          height: 65% !important;
        }
        .cardBody {
          height: 35% !important;
        }

        .cardContent {
          top: 0 !important;
        }
      }
    }

    .fantastic-card-row {
      .cardShare, .cardActions {
        display: none !important;
      }

      .mask-bg, .mask-bg-2, .mask-bg-mobile {
        // backdrop-filter: none !important;
        @media #{$tablet-media} {
          background-position: center center !important;
          background-size: auto 100% !important;
        }
      }

      .mask-bg, .mask-bg-mobile {
        display: none !important;
      }

      .fantastic-card {
        .cardMessage {
          p {
            display: -webkit-inline-box !important;
            -webkit-line-clamp: 10 !important;
            -webkit-box-orient: vertical !important;
            text-overflow: ellipsis !important;
            overflow: hidden !important;
          }

          .name {
            font-weight: bold;
            padding: 5px;
            padding-bottom: 0px !important;

            font-size: 17px !important;

            @media #{$tablet-media} {
              font-size: 15px !important;
            }

            @media #{$mobile-large-media} {
              font-size: 13px !important;
            }

            // background: rgba(0,0,0,.25) !important;
            border-radius: 5px !important;
            display: table; //TODO: kc, hack
          }

          // .message {
          //   font-weight: 100 !important;
          //   font-size: 15.5px !important;

          //   @media #{$tablet-media} {
          //     font-size: 14.5px !important;
          //   }

          //   @media #{$mobile-large-media} {
          //     font-size: 11px !important;
          //   }
          // }
        }
      }
    }
  }
}

.cardListElements {
  padding: 2% !important;

  .activity, .notifications, .following, .saved {
    text-align: left !important;

    .toView {
      display: inline-block !important;
      vertical-align: top !important;
      margin-right: 5px;
      // background-color: #ff5e62;
      background-color: #cc1166;
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }

    .toView.hidden {
      visibility: hidden;
    }

    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-top: 5px;
    height: auto !important;
    border-bottom: 1px solid #999;

    .action-image {
      height: 50px;
      width: 50px;
      display: inline-block !important;
      vertical-align: top !important;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 10%;
      }
      margin-right: 10px;
    }

    .action-info {
      p {
        // padding-top: 5px;
        padding-bottom: 3px;
      }

      height: auto !important;
      overflow-y: scroll;

      width: 70%;
      display: inline-block !important;
      vertical-align: top !important;
      overflow: hidden;

      .action-text {
      }

      .action-text.username{
        cursor: pointer;
      }

      .action-text.subject{
        cursor: pointer;
        font-weight: bold;
        opacity: 1;
        // text-decoration: underline;
      }

      .action-text.subject:hover{
        // text-decoration: underline;
      }

      .action-text.action{}

      .action-text.value{
        cursor: pointer;
      }
    }
  }
}

.fantastic-card {
  @extend .card;
  border-radius: 2px;

  max-width: 625px;

  @media #{$tablet-media} {
    max-width: 100%;
  }

  @media #{$mobile-media} {
    max-width: 100%;
  }

  @media #{$mobile-media} {
    max-width: 100%;
  }

  .cardScore {
    display: none !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .cardPrompt {
    width: fit-content !important;
    display: inline-block;
    z-index: 1001 !important;
    vertical-align: top;
    float: right;
    padding-left: 3px !important;
    padding-right: 3px !important;

    button {
      // box-shadow: 1px 1px 5px #222 !important;
      // background: #ff5e62 !important;
      // background: rgba(0,0,0,.35) !important;
      background: rgba(50,50,50,.25) !important;
      // background: none !important;

      opacity: 1 !important;
      width: auto !important;
      height: auto !important;

      padding: 7px !important;
      padding-top: 1px !important;
      padding-bottom: 1px !important;

      font-size: 22px !important;
      border-radius: 10px !important;

      @media #{$tablet-media} {
        font-size: 20px !important;
      }
      @media #{$mobile-large-media} {
        font-size: 18px !important;
      }
      @media #{$mobile-media} {
        font-size: 18px !important;
      }

      h1, h2{
        padding: 0px !important;
        font-size: 22px !important;
      }

      @media #{$mobile-large-media} {
        h1, h2{
          font-size: 18px !important;
        }
      }
    }
  }

  .cardPrompt.open {
    button {
    }
  }

  .cardPrompt.showPreview {
    z-index: 1500 !important;
    position:  relative !important;
    width: 100%;

    button {
      box-shadow: none !important;
      float: right;
      text-align: right;
      border: none;
      box-shadow: none;
      padding: 0px;
      padding-top: 20px;
      margin: 0px;
      margin-bottom: 3px;
      font-size: 27px;

      @media #{$tablet-media} {
        font-size: 24px;
      }
      @media #{$mobile-large-media} {
        font-size: 21px;
      }
      @media #{$mobile-media} {
        font-size: 21px;
      }
    }
  }

  .cardPrompt.displayProgress {
    display: none !important;

    button {
      padding: 5px !important;
      background-color: #222 !important;
      border-radius: 50% !important;
      height: 40px !important;
      width: 40px !important;

      @media #{$mobile-large-media} {
        height: 30px !important;
        width: 30px !important;
      }

      font-size: 24px !important;

      @media #{$tablet-media} {
        font-size: 22px !important;
      }
      @media #{$mobile-large-media} {
        font-size: 18px !important;
      }
      @media #{$mobile-media} {
        font-size: 18px !important;
      }
    }
  }

  .cardLabel {
    white-space: break-spaces !important;

    p {
      display: inline-block;
    }

    h3 {
      width: 100%;
      display: inline-block;
      font-size: 20.5px;
      margin: 0px !important;
      // padding: 6px !important;
      font-weight: bold !important;
      padding: 0px !important;

      @media #{$tablet-media} {
        font-size: 17.5px;
        margin: 0px !important;
        // padding: 10px !important;
      }

      @media #{$mobile-media} {
        font-size: 14px;
        margin: 0px !important;
        // padding: 3px !important;
      }
    }

    .count {
      display: inline-block;
      margin: 0 auto;
      // background: #ff5e62;
      background: #cc1166;
      padding: 5px;
      border-radius: 3.5px;
      float: right;
    }
    border-radius: 10px;
    background: rgba(100,100,100,.25) !important;
    padding: 20px;
    // padding-left: 0px;
  }

  .cardLabel.large {
    h3 {
      display: inline-block;
      font-size: 25px;
      margin: 2px;

      @media #{$mobile-large-media} {
        font-size: 21.5px;
      }
      @media #{$mobile-media} {
        font-size: 18px;
      }
    }
    height: auto !important
  }

  .cardLabel.transparent {
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
    // margin-top: 10px !important;
    margin-bottom: 4px !important;
    h3 {
      padding: 0 !important;
      font-weight: bold !important;
    }
  }

  .cardList {
    height: auto !important;

    overflow: hidden;
    margin-bottom: 5px !important;

    white-space: nowrap !important;
    overflow-x: scroll;

    .titles {
      // display: table;
      // table-layout: fixed;
      // border-collapse: separate;
      text-align: left !important;
      // width: fit-content !important;
      // margin-top: 1.5vh !important;
      margin-top: 2vh !important;
      margin-bottom: 2vh !important;
      border-radius: 0px !important;
      // border-bottom-left-radius: 0px !important;
      // border-bottom-right-radius: 0px !important;
      // background: rgba(0,0,0,.25) !important;

      .title:first-of-type {
        border-radius: 15px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        // margin-left: 0px !important;
      }

      .title:last-of-type {
        border-radius: 15px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        // margin-right: 0px !important;
      }

      .title:only-of-type {
        border-radius: 15px !important;
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        border-top-left-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
      }

      table-layout: fixed !important;
      display: table !important;
      width: 100% !important;

      @media #{$tablet-media} {
        text-align: center !important;
        width: 100% !important;
        display: table !important;
        // table-layout: fixed !important;
        // border-bottom: .5px solid #353535 !important;
      }

      .title {
        background: rgba(10, 10, 10, 0.25) !important;
        // border-radius: 15px !important;

        opacity: .5;
        cursor: pointer;
        padding: .95%;
        padding-top: 2.5%;
        padding-bottom: 1.5%;
        margin-left: 1%;
        margin-right: 1%;
        // border-radius: 25px !important;
        // display: table-cell;
        // background: #282828;
        text-align: center;
        color: #ccc;
        width: 100% !important;
        display: table-cell !important;

        @media #{$tablet-media} {
          padding-top: 1.5% !important;
        }

        @media #{$mobile-large-media} {
          margin-left: 1% !important;
          margin-right: 1% !important;
          padding: 0 !important;
        }

        p {
          text-align: center;
          display: inline-block;

          font-size: 22px !important;

          @media #{$tablet-media} {
            font-size: 18px !important;
          }

          @media #{$mobile-media} {
            font-size: 18px !important;
          }
        }

        .count {
          display: inline-block;
          // background: #ff5e62;
          background: #cc1166;
          padding: 3.5px;
          margin: 3px;
          border-radius: 7px;
        }
      }

      border-radius: 0px !important;

      .title.selected {
        opacity: 1;
        // border-bottom: 2.5px solid rgba(255,255,255,.45) !important;
        color: #fff !important;
        // background: rgba(0,0,0,.25) !important;
        background: rgba(10, 10, 10, 0.225) !important;
        padding: 10px !important;
        // border-radius: 10px !important;
        p {
          font-weight: bold !important;
        }
      }
    }

    .tagField {
      margin-top: 6px !important;
      margin-bottom: 6px !important;
      .filterBar {
        background: #191919 !important;
      }
    }

    .cardListElements {
      overflow-y: scroll !important;
      background: rgba(0,0,0,.25) !important;
      border-radius: 15px !important;
    }
  }

  .cardSubject {
    text-align: left;
    width: 100%;
    // margin-bottom: 12px;

    @media #{$tablet-media} {
      // margin-bottom: 10px;
    }

    @media #{$mobile-large-media} {
      // margin-bottom: 6px;
    }

    p {
      text-shadow: #333 2px 2px 3px;
      font-size: 14.5px;
      padding-top: 0px !important;
      color: #fff;
      display: inline-block;

      a {
        text-decoration: underline;
        color: #fff !important;
        cursor: pointer;
      }
      a.bare {
        text-decoration: none !important;
        color: #fff !important;
        cursor: pointer;
      }
    }

    a.isCreator {
      color: #fff;
      font-weight: bold;
    }

    a {
      text-decoration: none;
      color: #fff !important;
    }

    h1, h2{
      // background: #cc1166 !important;
      text-overflow: ellipsis;
      white-space: nowrap !important;
      overflow: hidden;

      @supports (-webkit-line-clamp: 2) {
        white-space: initial !important;
        display: -webkit-inline-box !important;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical !important;
      }

      max-width: 65% !important;
      border-radius: 6px;
      display: inline-block;
      margin: 0 !important;
      font-size: 33px;
      font-weight: bold !important;
      // background-color: #ff5e62;
      // background-color: #cc1166;

      padding-left: 0px !important;
      padding-right: 5px !important;
      color: white !important;
      // text-shadow: #333 2px 2px 3px;
    }

    @media #{$mobile-large-media} {
      h1, h2{
        // padding-left: 3px !important;
        padding-right: 3px !important;
        font-size: 24px;
      }

      p {
        font-size: 11.5px;
      }
    }
  }

  .cardAccountInfo {
    width: 100%;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    .cardTags {
      margin-top: 0px !important;
      margin-bottom: 10px !important;
    }

    .userStats {
      display: table;
      display: none !important;
      table-layout: fixed;
      background: rgba(0,0,0,.34) !important;
      border-radius: 5px;

      margin-bottom: 10px;

      .profilePicture {
        display: none;

        img {
          background: #fff;
          width: 100%;
          float: left;
          border-radius: 10%;

          height: 85px;
          width: 85px;

          @media #{$mobile-large-media} {
            height: 75px;
            width: 75px;
          }
        }

      }

      .userCounts {
        vertical-align: top;
        display: table-cell;
        overflow: hidden;
        margin-left: 1%;
        width: 75%;

        @media #{$mobile-media} {
          width: 100%;
        }

        .row {
          width: 100%;
          display: table;
          table-layout: fixed;
          padding: 5px;
        }

        .row::after {
          display: none !important;
         }

        .count {
          padding: 1%;
          display: table-cell;

          p {
            font-size: 21px;
            height: 21px;

            @media #{$tablet-media} {
              font-size: 16px;
              height: 16px;
            }

            @media #{$mobile-large-media} {
              font-size: 14px;
              height: 14px;
            }

            text-align: center
          }

          p.label {
            color: #ccc
          }
        }
      }
    }

    .userBio {
      margin-bottom: 1.5%;
      padding: 1%;
      padding-left: 0px !important;

      background: rgba(0,0,0,.2) !important;
      border-radius: 5px;

      .name {
        font-weight: bold;
        padding: 5px;
        padding-bottom: 0px !important;

        font-size: 17px !important;

        @media #{$tablet-media} {
          font-size: 15px !important;
        }

        @media #{$mobile-large-media} {
          font-size: 13px !important;
        }

        background: rgba(0,0,0,.25) !important;
        border-radius: 5px !important;
        display: table; //TODO: kc, hack
      }

      .message {
        font-weight: 100 !important;
        font-size: 15.5px !important;

        @media #{$tablet-media} {
          font-size: 14.5px !important;
        }

        @media #{$mobile-large-media} {
          font-size: 11px !important;
        }
      }

      .bio {
        font-weight: 100 !important;
        font-size: 16.5px !important;

        @media #{$tablet-media} {
          font-size: 15.5px !important;
        }

        @media #{$mobile-large-media} {
          font-size: 12px !important;
        }

        background: rgba(0,0,0,.25) !important;
        border-radius: 5px !important;
        // display: table; //TODO: kc, hack
      }
    }
  }

  .cardInput {
    // padding: .2%;
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    position: relative !important;

    .no-padding.table-cell {
      padding: 0px !important;
    }

    .table-cell {
      display: table-cell;
      padding-left: 1% !important;
      padding-right: 1% !important;
      padding-bottom: .5% !important;
      vertical-align: top;
    }

    .desktop-table-cell {
      @media #{$tablet-media} {
        padding: 0% !important;
        width: 100% !important;
        display: block !important;
      }
    }

    .desktop-table-cell.expanded.min {
      @media #{$tablet-media} {
        // padding: 1.4vh !important;
        padding-right: 1% !important;
        width: 87% !important;
        display: inline-block !important;
        button {
          padding-left: 15% !important;
        }
      }
    }

    .desktop-table-cell.minimized {
      @media #{$tablet-media} {
        // padding: 1.4vh !important;
        width: 13% !important;
        display: inline-block !important;
      }
    }

    .table-cell:first-of-type {
      padding-left: 0 !important;
    }
    .table-cell:last-of-type {
      padding-right: 0 !important;
    }

    // input, textarea {
    //   margin-top: 10px;
    //   margin-bottom: 10px;

    //   @media #{$tablet-media} {
    //     margin-top: 7px;
    //     margin-bottom: 7px;
    //   }

    //   @media #{$mobile-large-media} {
    //     margin-top: 5px;
    //     margin-bottom: 5px;
    //   }
    // }

    button {
      margin-top: 1px;
      margin-bottom: 5px;

      @media #{$tablet-media} {
        margin-top: 1px;
        margin-bottom: 4px;
      }

      @media #{$mobile-large-media} {
        margin-top: 1px;
        margin-bottom: 3px;
      }
    }

    .cardInputField {
      margin-top: 5px;
      margin-bottom: 9px;
      // padding: 0px !important;
      position: relative !important;

      p.row {
        padding-bottom: 5px !important;
        font-size: 16px !important;

        @media #{$tablet-media} {
          font-size: 14px !important;
        }

        @media #{$mobile-media} {
          font-size: 12px !important;
        }
      }

      input {
        margin: 0;
        vertical-align: baseline;
        padding-right: 31px;

        @media #{$mobile-large-media} {
          padding-right: 26.5px !important;
        }
      }

      .profileButton {
        color: #BBB;
        cursor: pointer;
        margin-left: -69px;

        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px;

        img {
          height: 15px;
        }

        @media #{$tablet-media} {
          margin-left: -57px;
          img {
            height: 14px;
          }
        }

        @media #{$mobile-media} {
          margin-left: -46px;
          img {
            height: 13px;
          }
        }
      }

      .clearButton {
        position: absolute;
        font-weight: bold;
        color: white;
        padding: 5px;
        text-shadow: none;

        cursor: pointer;
        right: 6px;
        font-size: 20px;

        top: 50% !important;
        transform: translateY(-50%) !important;

        @media #{$tablet-media} {
          font-size: 16px;
        }
      }

      .clearButton.secondary {
        margin-left: 8px;
        @media #{$tablet-media} {
          margin-left: 4px;
        }
        @media #{$mobile-media} {
          margin-left: 1px;
        }
      }

      .cardDropDown {
        // background: rgba(0,0,0,.25) !important;
        background: rgba(150,150,150,.25) !important;
        padding: 10px;
        padding-right: 7px;
        margin-top: 5px;
        margin-bottom: 8px;
        overflow: auto;
        border-radius: 10px;

        @media #{$tablet-media} {
          border-radius: 7px;
        }

        @media #{$mobile-large-media} {
          padding: 5px !important;
          border-radius: 7px;
        }

        @media #{$mobile-media} {
          border-radius: 7px;
        }

        p {
          font-size: 17px !important;
          @media #{$tablet-media} {
            font-size: 16px !important;
          }
          @media #{$mobile-large-media} {
            font-size: 15px !important;
          }
          @media #{$mobile-media} {
            font-size: 15px !important;
          }
        }

        p.row{
          float: left;
          padding: 10px;
          display: inline-block;
          margin-right: 5px;
	  font-weight: bold !important;
        }

        .selectDiv {
          float: right;
          width: 25%;
          position: relative !important;

          .table-cell {
            float: right !important;
          }
        }

        select {
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          width: 25%;
          text-align: center;
          border-radius: 7px;
          padding: 5px;
          margin-top: 5px;
          margin-bottom: 5px;
          background: transparent !important;
          border: 2px solid #777;
          color: white;
          width: 100%;
	  font-weight: bold !important;
        }

        .downArrow {
          position: absolute;
          right: 5px;
          top: 5px !important;
          // top: 3px;
          color: white;
          pointer-events: none;
        }
      }
    }

    .cardInputField.condensed {
      float: left;
      width: 68%;
    }

    .rounded {
      button {
        border-radius: 20px;
      }

      @media #{$tablet-media} {
        button {
          border-radius: 18px;
        }
      }

      @media #{$mobile-media} {
        button {
          border-radius: 15px;
        }
      }
    }

    .condensed {
      button {
        width: 15%;
      }
    }

    .thin.condensed {
      button {
        width: 6%;
      }
    }

    @media #{$tablet-media} {
      .cardInputField.condensed {
       width: 76.5%;
      }

      .condensed {
        button {
          width: 15%;
        }
      }

      .thin.condensed {
        button {
          width: 7.5%;
        }
      }
    }

    @media #{$mobile-large-media} {
      .condensed {
        button {
          width: 25%;
        }
      }

      .thin.condensed {
        button {
          width: 12%;
        }
      }
    }
  }

  .cardMessage {
    margin-top: 0px;
    margin-bottom: 18px;
    border-radius: 10px;

    text-shadow: none;
    width: 100%;
    padding-bottom: 5px;

    text-align: left;
    overflow-x: hidden;
    overflow: hidden !important;

    // overflow-y: scroll;
    // backdrop-filter: blur(5px) !important;

    p {
      font-size: 14px;
      // font-weight: 500 !important;
      opacity: .96;
      // padding: 5px;
      border-radius: 5px !important;
      white-space: break-spaces !important;
      color: #ccc !important;

      display: -webkit-inline-box !important;
      -webkit-line-clamp: 3 !important;
      -webkit-box-orient: vertical !important;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
    }

    @media #{$mobile-large-media} {
      margin-top: 5px;
      margin-bottom: 5px;

      p {
        font-size: 13px;
      }
    }

    .table-cell {
      float: left !important;
      margin: 3px !important;
      // margin-right: 3px !important;
      opacity: .75 !important;
      button {
        // opacity: .70;
        font-size: 20px;
        font-weight: bold;
        padding: 5px;
        border-radius: 5px !important;
        white-space: break-spaces !important;
        // background: rgba(150,150,150,.1) !important;
        background: rgba(50,50,50,.25) !important;
      }

      @media #{$mobile-large-media} {
        button {
          font-size: 13px;
        }
      }
    }
  }

  .cardToggle {
    background: rgba(100,100,100,.25) !important;
    overflow: auto !important;
    padding: 5px;

    border-radius: 15px;
    @media #{$tablet-media} {
      border-radius: 14px;
    }
    @media #{$mobile-large-media} {
      border-radius: 12px;
    }
    @media #{$mobile-media} {
      border-radius: 10px;
    }

    p.row{
      float: left;
      padding: 5px;
      display: inline-block;
      margin-right: 5px;
    }

    .toggleDiv {
      float: right;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      // background-color: #ff5e62;
      background-color: #cc1166;
    }

    input:focus + .slider {
      // box-shadow: 0 0 1px #ff5e62;
      box-shadow: 0 0 1px #cc1166;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  .cardDatePicker {
    background: rgba(150,150,150,.25) !important;
    color: white !important;
    width: 100% !important;
    margin-top: 14px !important;
    margin-bottom: 8px !important;
    border-radius: 15px;
    @media #{$tablet-media} {
      border-radius: 12px;
    }
  }

  .cardInputField.minimized {
    float: left !important;
    width: 32% !important;
    margin-right: 1% !important;
    margin-top: 0px !important;

    .cardDropDown {
      // background: rgba(50, 50, 50, 1) !important;
      background: rgba(50,50,50,.80) !important;
      padding-left: 7px !important;
      padding-right: 7px !important;

      p.row {
        padding-left: 0px !important;
        padding-top: 7px !important;
        padding-bottom: 1px !important;
      }

      .selectDiv {
        width: 100% !important;
        select {
          // border: 2px solid #424242 !important;
          text-align: left !important;
          border: none !important;
        }
      }
    }
  }

}

.MuiPickersPopper-root {
  z-index: 2300 !important;
  @media #{$tablet-media} {
    transform: translate3d(7.5%, 30%, 0px) !important;
  }
}

.MuiPickersCalendarHeader-label {
  line-height: 1 !important;
}

.MuiButtonBase-root {
  width: fit-content !important;
}

.MuiFormLabel-root {
  color: #fff !important;
}

.MuiInputAdornment-root {
  .MuiSvgIcon-root {
    color: #fff !important;
  }
}

.slow-hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.card-type-info {
  .cardDownArrow {
    // display: none !important;
  }
}

.cardDownArrow {
  display: none !important;
  animation: bounce 1.5s linear infinite;
  z-index: 1400 !important;

  opacity: .7;
  position: fixed;
  bottom: 2vh;
  right: 2.5%;
  top: 5px !important;
  left: auto !important;
  padding: 0px !important;
  padding-top: 12px !important;
  padding-bottom: 5px !important;

  font-size: 20px !important;

  height: 55px !important;
  width: 55px !important;
  border-radius: 50% !important;

  font-weight: 100 !important;
  color: white;

  background: rgba(0, 0, 0, 0.25) !important;

  @media #{$tablet-media} {
    display: none !important;
  }

  .prompt {
    font-size: 12px !important;
  }

  .arrow {
    margin-top: -10px;
  }
}

.cardFooter {
  position: relative;
  margin-top: 5px;
  padding-bottom: 4px;
}

.cardFooter.chunk {
  .cardInput {
    padding-top: 1%;
    padding-bottom: 1%;
  }
}

.cardDismiss-top {
  cursor: pointer !important;
  z-index: 5000 !important;
  position: absolute;
  top: 1%;
  left: 1%;
  // bottom: 1%;

  display: inline-block !important;
  // padding: 4px;
  border-radius: 10%;
  color: #fff;
  font-size: 35px;

  @media #{$tablet-media} {
    display: none !important;
  }
}

.cardDismiss-bottom {
  cursor: pointer !important;
  z-index: 5000 !important;

  display: none !important;
  font-size: 35px;
  text-align: center !important;

  border-radius: 0px !important;

  @media #{$tablet-media} {
    position: absolute;
    padding-bottom: 2.5% !important;

    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;

    z-index: 2002;

    margin: 0 auto;
    display: block !important;

    padding: 0px;
    border-radius: 10%;

    color: #fff;
    margin-top: 0px;
    font-size: 25px;

    width: 42px !important;
    height: 42px!important;
    background: rgba(25,25,25,.85) !important;
    padding: 5px !important;
    border-radius: 15px !important;
    bottom: 1.2% !important;
  }
}

.cardDismiss-top:hover, .cardDismiss-bottom:hover {
  cursor: pointer !important;
}

.cardHeader {
  // position: relative;
  width: 100%;
  text-align: left;
}

.cardContent {
  padding: 3.25%;
  position: absolute !important;
  top: 3vh !important;
  bottom: auto !important;
  left: 0% !important;
  right: 0% !important;
  margin: 0 auto !important;
  z-index: 1 !important;
}

.cardInput {
  text-align: left;
  overflow: auto;

  width: 100%;

  .fill {
    button {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }

  ::-webkit-input-placeholder { /* Chrome */
    color: #ccc;
    opacity: .80;
  }

  input.invalid {
    // border: 1px solid #ff5e62;
    border: 1px solid #cc1166;
  }
  input.valid {
    background: #66bb11;
  }

  div.tag {
    width: auto;
    margin-right: 3px;
    padding: 3px;
    font-size: 10px;

    @media #{$mobile-large-media} {
      margin-right: 3px;
      font-size: 8px;
    }
  }
}

.tag {
 cursor: pointer;
}

.cardTags {
  overflow-x: scroll !important;
  margin-top: 3px !important;
  margin-bottom: 2px !important;

  padding-top: 0px !important;
  padding-bottom: 0px !important;

  @media #{$tablet-media} {
    font-size: 8px;
  }

  @media #{$mobile-large-media} {
    font-size: 7px;
  }

  .cardInput {
    white-space: nowrap;
    padding-top: 0px;
    padding-bottom: 0px;
    display: block !important;
  }

  div.tag {
    margin: 1px;
    margin-top: 3px;

    direction: ltr;
    padding: 8px;
    padding-top: 5px;
    padding-bottom: 3px;

    border-radius: 9px;
    // text-shadow: 1px 1px 3px #000 !important;
    text-transform: uppercase;
    font-size: 13px;
    margin-right: 7px;
    font-weight: bold;
    display: inline-block;

    @media #{$tablet-media} {
      padding: 6px;
      padding-top: 4px;
      padding-bottom: 2px;

      font-size: 13px;
      border-radius: 7px;
    }

    @media #{$mobile-large-media} {
      padding: 3.5px;
      padding-top: 2px;
      padding-bottom: 0px;

      font-size: 13px;
      border-radius: 6px;
    }

    p {
      font-size: 10px !important;
    }

    position: relative;

    .promptButton {
      height: 14px;
      width: 14px;
      top: -3px;
      right: -6px;
      text-shadow: none;
      // background: #ff5e62;
      background: #cc1166;
      position: absolute;
      border-radius: 50%;
      text-align: center;
      font-size: 13px;
      font-weight: bolder;
    }

    // border: 2px solid transparent; //HACK for sizing
    // box-shadow: 1px 1px 2px #353535 !important;
    background: rgba(0,0,0,.75) !important;
  }

  div.tag.pressed {
    font-weight: bold !important;
    border: 2px solid #fff;
  }
}

.cardTags.add {
  .cardInput {
    white-space: nowrap;
    padding-top: 0px;
    padding-bottom: 0px;
    overflow-x: scroll;
    display: block !important;
    width: 91% !important;
    // float: left !important;
  }

  .table-cell {
    width: 7% !important;
    float: right !important;
    button {
      padding: 5px !important;
      background-color: rgba(0, 0, 0, 0.25) !important;
    }
  }

  @media #{$tablet-media} {
    .table-cell {
      button {
        padding: 4px !important;
        padding-bottom: 2px !important;
      }
    }
  }
}

label[for=profilePicture] {
  display: block !important;
}

button, label[for=profilePicture] {
  font-weight: bold;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 0px;
  background-color: transparent;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;

  padding: 1.4vh !important;
  // background-color: rgba(0,0,0, .25);
  background-color: rgba(175,175,175,.15);

  @media #{$tablet-media} {
    padding: 1.25vh !important;
  }

  @media #{$mobile-large-media} {
    padding: 1.25vh !important;
  }

  p {
    opacity: .7;
    font-size: 12px !important;
    display: inline-block !important;
    padding-left: 5% !important;

    @media #{$tablet-media} {
      font-size: 10px !important;
    }
    @media #{$mobile-large-media} {
      font-size: 9px !important;
    }
    @media #{$mobile-media} {
      font-size: 8px !important;
    }
  }
  img {
    vertical-align: middle !important;
  }
}

.thin {
  width: 9%;

  @media #{$mobile-media} {
    width: 9%;
  }
}

button.thin {
  width: 40px;

  @media #{$tablet-media} {
    width: 30px;
  }
  @media #{$mobile-large-media} {
    width: 25px;
  }
}

.pressed-dark {
  button, label[for=profilePicture] {
    // background: #ff5e62 !important;
    background: rgba(0,0,0,.25) !important;
    color: white !important;
  }
}

.pressed {
  button, label[for=profilePicture] {
    // background: #ff5e62 !important;
    background: #cc1166 !important;
    color: white !important;
  }
}

.premium {
  button, label[for=profilePicture] {
    background: #DAA520 !important;
    color: white !important;
  }
}

.no-padding.table-cell {
  padding: 0px !important;
}

button.pressed {
  // background: #ff5e62 !important;
  background: #cc1166 !important;
  color: white !important;
}

button.premium {
  // background: #ff5e62 !important;
  background: #DAA520 !important;
  color: white !important;
}

textarea {
  margin: 0px;
  // margin-left: 0px;
  color: white;
  text-align: left;
  font-size: 20px;
  width: 100%;
  padding: 7px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 14px;
  border: none !important;
  // background: rgba(0,0,0,.25);
  background: rgba(150,150,150,.25);
  min-height: 85px !important;
  padding-right: 42px !important;
}

.large, .medium, .small, .mini, .micro {
  // overflow: hidden !important;
  textarea {
    height: 100% !important;
  }
}

input {
  border: none;
  // background: rgba(0,0,0,.25);
  background: rgba(150,150,150,.25);
  color: white;
  text-align: left;
  width: 100%;
  padding: 12px;
}

button {
  div {
    display: inline-block !important;
  }
}

@media #{$tablet-media} {
  button {
    font-size: 17px;
    padding: 7px;
  }

  input, textarea {
    width: 100%;
    font-size: 17px;
    padding: 10px;
  }
}

@media #{$mobile-large-media} {
  button {
    font-size: 14px;
    padding: 7px;
  }

  input, textarea {
    width: 100%;
    font-size: 15px;
  }
}

.fantastic-card-collection {
  // height: 100%;
  // overflow: hidden;

  right: 0;
  left: 0;
  margin: 0 auto;
}

.no-scroll {
  overflow: hidden !important;
  height: 100%;
}

.fantastic-page {
  height: 100% !important; /* Ensures the body takes up the full screen height */
  position: fixed !important; /* Prevents overscroll bounce */
  overflow-y: scroll !important; /* Enables vertical scrolling */
  -webkit-overflow-scrolling: touch !important; /* iOS velocity scrolling */
  width: 100% !important;

  background-position: center center !important;
  background-size: cover !important;

  .mask-bg-3 {
    opacity: .99 !important;
  }

  .cardInput {
    input {
      font-size: 20px;
      border-radius: 15px;

      @media #{$tablet-media} {
        font-size: 17px;
        border-radius: 14px;
      }
      @media #{$mobile-large-media} {
        font-size: 15px;
        border-radius: 12x;
      }
      @media #{$mobile-media} {
        font-size: 15px;
        border-radius: 10px;
      }
    }

    button, label[for=profilePicture] {
      text-shadow: none !important;
      border-radius: 15px;
      font-weight: bold;

      font-size: 16px;
      line-height: 28px;

      img {
        // margin: 3px !important;
        height: 13px;
        margin-left: 6px !important;
        margin-right: 6px !important;
      }

      @media #{$tablet-media} {
        font-size: 15.5px;
        line-height: 22px;

        img {
          height: 12px;
        }

        border-radius: 10px;
      }

      @media #{$mobile-large-media} {
        font-size: 13.5px;
	line-height: 20px;

        img {
          height: 12px;
        }

        border-radius: 10px;
      }

      @media #{$mobile-media} {
        font-size: 13.5px;
	line-height: 20px;

        img {
          height: 12px;
        }

        border-radius: 10px;
      }
    }

    .create {
      button {
        img {
          height: 16px !important;
        }

        @media #{$tablet-media} {
          img {
            height: 14px !important;
          }
        }

        @media #{$mobile-large-media} {
          img {
            height: 13px !important;
          }
        }

        @media #{$mobile-media} {
          img {
            height: 13px !important;
          }
        }
      }
    }
  }
}

.userProfilePictureBackdrop {
  background: rgba(0,0,0,.25) !important;
  height: fit-content !important;
  width: fit-content !important;
  margin: 0 auto !important;
  border-radius: 50% !important;
}

.userProfilePicture {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat no-repeat !important;

  float: left !important;
  width: 20% !important;

  display: block !important;
  height: 170px !important;
  width: 170px !important;
  border-radius: 50% !important;
  margin: 0 auto !important;

  // background: rgba(0,0,0,.25) !important;
  // border: 7.5px solid #222 !important;
  // float: left !important;

  @media #{$tablet-media} {
    max-width: 100% !important;
    height: 15vh !important;
    width: 15vh !important;
  }

  @media #{$mobile-large-media} {
    margin-bottom: 1% !important;
    max-width: 100% !important;
    height: 26vw !important;
    width: 26vw !important;
  }

  // @media #{$mobile-media} {
  //   height: 26vw !important;
  //   width: 26vw !important;
  // }
}

.userProfilePicture.center {
  float: auto !important;
}

.userProfilePictureContainer {
  // background: rgba(150,150,150,.25) !important;
  border-radius: 15px !important;

  height: 130px !important;
  width: 100% !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;

  // margin-top: 15px !important;
  // margin-bottom: 15px !important;

  @media #{$tablet-media} {
    height: 17vh !important;
    width: 100% !important;
  }

  @media #{$mobile-large-media} {
    height: 22vw !important;
    width: 100% !important;
  }

  .userProfilePictureBackdrop {
    float: left !important;
    // margin-bottom: 15px !important;
  }

  .userProfilePicture {
    height: 130px !important;
    width: 130px !important;

    @media #{$tablet-media} {
      max-width: 100% !important;
      height: 17vh !important;
      width: 17vh !important;
    }

    @media #{$mobile-large-media} {
      height: 22vw !important;
      width: 22vw !important;
    }
  }

  // position: relative !important;
  .row {
    padding-left: 27% !important;
    position: absolute !important;
    bottom: 65% !important;
  }

  .userProfilePictureEditButtons {
    position: absolute !important;
    // bottom: 15px !important;
    // right: 0 !important;

    margin: 0 auto !important;
    margin-bottom: 15px !important;

    width: 73% !important;
    right: 0 !important;
    bottom: 0 !important;
    display: table;
    table-layout: fixed;
    border-collapse: separate;

    input#profilePicture {
      display: none !important;
    }
  }
}

.fantastic-feed-page, .fantastic-insights-feed-page {
  min-height: 100vh !important;
  // height: 100%;
  // overflow-y: hidden;

  position: relative !important;
  padding-top: 12vh !important;

  @media #{$tablet-media} {
    padding: 2.5vh !important;
    padding-top: 9vh !important;
  }

  @media #{$mobile-large-media} {
    padding: 2.5vh !important;
    padding-top: 9vh !important;
  }

  // top: 28.5% !important;

  // @media #{$tablet-media} {
  //   top: 29% !important;
  // }

  // @media #{$mobile-large-media} {
  //   top: 25% !important;
  // }

  .fantastic-card.cardAccountInfo {
    .userProfilePictureBackdrop {
      float: left !important;

      @media #{$tablet-media} {
        float: none !important;
      }

      .userProfilePicture {
        @media #{$tablet-media} {
          float: none !important;
        }
      }
    }

    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    max-width: 625px !important;
    // padding-top: 12.5vh !important;
    // top: 12.5vh  auto !important;
    margin: 0 auto !important;
    // padding-bottom: 2vh !important;
    display: block !important;
    // padding: 6px !important;;

    @media #{$tablet-media} {
      // padding-top: 0 !important;
      // padding-top: 8.5vh !important;
      // top: 8.5vh !important;
      // padding-top: 8vh !important;
      // padding-bottom: 2.5vh !important;
      // max-width: 88% !important;
      max-width: 100% !important;
    }

    @media #{$mobile-large-media} {
      max-width: 100% !important;
      // padding-bottom: 9vh !important;
      // padding: 0% !important;
    }

    .userInfo {
      float: right !important;
      width: 70% !important;
      height: 140px !important;
      margin-top: 15px !important;
      position: relative !important;

      @media #{$tablet-media} {
        height: fit-content !important;
        float: none !important;
        width: 100% !important;
      }

      @media #{$mobile-large-media} {
        //width: 63vw !important;
      }

      // @media #{$mobile-media} {
      //   width: 63vw !important;
      // }

      .userBio {
        margin-bottom: 1.5%;
        overflow: hidden !important;

        // background: rgba(0,0,0,.2) !important;
        // border-radius: 25px !important;
        // padding: 7px !important;
        padding-left: 0px !important;
        text-align: left !important;

        @media #{$tablet-media} {
          min-height: none !important;
          width: 100% !important;
          padding: 0px !important;
          padding-top: 1vh !important;
        }

        .name {
          width: 100% !important;
          text-align: left !important;
          font-weight: bold;
          // padding: 5px;
          padding-left: 0px !important;
          padding-bottom: 0px !important;

          font-size: 35px !important;

          @media #{$tablet-media} {
            font-size: 28px !important;
            padding: 0px !important;
            width: fit-content !important;
            margin: 0 auto !important;
          }

          @media #{$mobile-large-media} {
            font-size: 20px !important;
          }

          // background: rgba(0,0,0,.25) !important;
          border-radius: 5px !important;
          // display: table; //TODO: kc, hack
        }

        .linkInfo {
          @media #{$tablet-media} {
            font-size: 28px !important;
            width: fit-content !important;
            margin: 0 auto !important;
          }
        }

        .bio {
          color: #ccc !important;
          text-align: left !important;
          font-weight: 100 !important;
          padding-left: 0px !important;

          font-size: 16.5px !important;

          @media #{$tablet-media} {
            font-size: 15.5px !important;
          }

          @media #{$mobile-large-media} {
            font-size: 11.5px !important;
          }

          // background: rgba(0,0,0,.25) !important;
          border-radius: 5px !important;
          // display: table; //TODO: kc, hack
          max-width: 100% !important;
          margin: 0 auto !important;
          margin-top: 1.5% !important;
          // float: left !important;

          @media #{$tablet-media} {
            max-width: 100% !important;
          }

          display: none !important;
        }
      }

      .cardInput {
        margin-top: 2% !important;
        width: 100% !important; //TODO: kc, hack
        // float: left !important;

        position: absolute !important;
        bottom: 1vh !important;

        // @media #{$mobile-large-media} {
        //   margin-top: 13% !important;
        //   width: 136.25% !important; //TODO: kc, hack
        //   margin-left: -36.25% !important; //TODO: kc, hack
        //   .table-cell.expanded {
        //     width: auto !important;
        //   }
        // }

        width: 100% !important;

        @media #{$tablet-media} {
          margin: 0 auto !important;
          margin-top: 3vh !important;
          position: relative !important;
          width: 100% !important;
        }

        @media #{$mobile-large-media} {
          left: 0px !important;
          right: 0px !important;
          width: 100% !important;
        }
      }

      button {
        display: inline-block !important;
      }
    }
  }

  .fantastic-card-collection.active {
    margin-top: 3vh !important;

    @media #{$tablet-media} {
      margin-top: 3vh !important;
    }

    // overflow-y: scroll;
    // height: auto !important;

    // .fantastic-card {
    //   height: 40vh !important;
    // }
  }
}

.fantastic-insights-feed-page {
  .cardDismiss-top {
    // right: 1.5% !important;
    // left: auto !important;
  }

  .fantastic-card-collection {
    .fantastic-card-row {
      border: none !important;
      .cardBody {
        .cardMessage.muted {
          opacity: .65;
          p {
            font-weight: bold !important;
            text-align: center !important;
          }
          max-height: none !important;
        }
      }
    }
  }

  .fantastic-card-collection.active {
    overflow-y: scroll !important;
    overflow-x: hidden !important;

    width: 100%;
    height: 100%;
    margin-top: 5%;
    @media #{$tablet-media} {
      margin-top: 8%;
    }

    @media #{$mobile-media} {
      margin-top: 12%;
    }

    .fantastic-card-row.card-type-info {
      .mask-bg, .mask-bg-2, .mask-bg-mobile {
        display: none !important;
      }

      padding: 1.5%;
      padding-left: 0%;
      padding-right: 0%;
      // width: 33%;
      // float: left;
      // display: inline-block;

      @media #{$tablet-media} {
        width: 100%;
        float: none;
        padding: 3%;
        padding-left: 0%;
        padding-right: 0%;
      }

      .fantastic-card {
        position: relative !important;
        width: 100%;

        background: rgba(103,103,103,.25);
        border-radius: 15px;
        // border: 1px solid #333333 !important;

        .cardSubject {
          h1, h2{
            background-color: transparent;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            display: -webkit-inline-box !important;
            -webkit-line-clamp: 1 !important;
            -webkit-box-orient: vertical !important;
            font-size: 22.5px !important;
          }

          @media #{$tablet-media} {
            h1, h2{
              font-size: 18.5px !important;
            }
          }

          img {
            height: 18px;
            width: 18px;
            @media #{$mobile-large-media} {
              height: 15px;
              width: 15px;
            }
          }
        }
        .cardBody {
          height: 15vh !important;
        }
        .cardMessage {
          text-align: left;
        }

        .cardPreview{
          .cardTags {
            margin-top: 0px !important;
            padding: 0px !important;
            .cardInput {
              float: none !important;
              width: 100%;
            }
            .table-cell {
              display: block !important;
            }
          }
        }
      }
    }
  }

  .fantastic-card-row.card-type-info {
    .cardInput {
      width: 100%;
      border-radius: 10px;
    }

    .cardMessage {
      width: auto !important;
      margin-top: 1%;
      p {
        padding: 0px;
        font-size: 14px;

        @media #{$tablet-media} {
          font-size: 12px;
        }

        @media #{$mobile-large-media} {
          font-size: 12px;
        }
      }
    }

    .cardLabel {
      margin-top: 1%;
      p {
        padding: 0px;
        font-size: 17px;

        @media #{$tablet-media} {
          font-size: 14px;
        }

        @media #{$mobile-large-media} {
          font-size: 14px;
        }
      }
    }

    .cardPreview {
      .fantastic-card-collection.active {
        .fantastic-card-row.card-type-promo {
          .fantastic-card {
            .cardHeader {
              height: 50vh !important;
            }
          }
        }
      }
    }
  }

  .cardSubject {
    text-align: left !important;
    width: 100% !important;
  }

  .fantastic-tags {
    .tagField {
      .filterBar {
        .cardInput {
          input {
            text-align: center !important;
          }
        }
      }
    }
  }

  .tagField {
    .filterBar {
      .cardInput {
        input {
          text-align: left !important;
        }
      }
    }
  }

  .fantastic-slider {
    input[type='value'] {
      width: 15% !important;
      padding: 2% !important;
      vertical-align: top;
      display: inline-block;
      margin: 0 auto;
      float: right;
      text-align: center;
    }

    input[type='range'] {
      padding: 0 !important;
      // margin-left: 2% !important;
      -webkit-appearance: none;
    }

    input[type="range"]::-webkit-slider-thumb {
      background-color: #ff1166 !important;
     -webkit-appearance: none;
      height: 30px !important;
      width: 30px !important;
      border-radius: 50% !important;

    }

    .slider-group {
      display: inline-block !important;
      width: 80% !important;
      datalist {
        display: table;
        table-layout: fixed;
        border-collapse: separate;
        width: 104%;
        @media #{$tablet-media} {
          font-size: 17px;
        }

        .option {
          font-size: 14px;

          @media #{$tablet-media} {
            font-size: 12px;
          }

          @media #{$mobile-large-media} {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.fantastic-insights-page {
  position: fixed;
  z-index: 1600;
  opacity: 95;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background: linear-gradient(90deg, #ffaa66, #ff5e62) !important;
  background: #282828 !important;
  // background: linear-gradient(-0.1turn , #cc1166, #282828, #282828) !important;
  // background: radial-gradient(110em at bottom right, #cc1166, #282828, #282828) !important;

  @media #{$tablet-media} {
    // background: radial-gradient(63em at bottom right, #cc1166, #282828, #282828) !important;
  }

  @media #{$mobile-large-media} {
    // background: radial-gradient(63em at bottom right, #cc1166, #282828, #282828) !important;
  }

  .logo {
    position: relative;
    left: 0;
    right: 0;

    .fantastic-tags {
      margin-top: 1%;
      position: relative !important;
    }

    p {
      text-align: center;
      color: #fff;
      margin: 3px !important;
      font-size: 18px;
      opacity: .9;

      @media #{$tablet-media} {
        font-size: 16px;
      }

      @media #{$mobile-large-media} {
        font-size: 14px;
      }
    }

    .logo-fan {
      font-size: 65px;
      border-radius: 10px;
      display: inline-block !important;
      font-weight: 500;
      @media #{$tablet-media} {
        font-size: 55px;
      }
      @media #{$mobile-media} {
        font-size: 45px;
      }
    }

    .logo-tastic {
      font-size: 65px;
      display: inline-block !important;
      font-weight: 500;
      @media #{$tablet-media} {
        font-size: 55px;
      }
      @media #{$mobile-media} {
        font-size: 45px;
      }
    }

    h3 {
      padding: 0px;
      font-size: 22px;
      font-weight: 500;
      display: inline-block;
      margin: 0px;
      font-family: 'Verdana-Installed', 'Verdana', 'Lato', 'Arial' !important;
    }

    @media #{$tablet-media} {
      margin: 0px;
      padding: 0px;
    }

    @media #{$mobile-large-media} {
      h3 {
        font-size: 13px;
        display: inline-block;
        margin-top: 0px;
      }
    }
  }

  .logo-insights {
    h3 {
      font-size: 22px;
    }

    @media #{$tablet-media} {
      margin: 0px;
      padding: 0px;
      h3 {
        font-size: 18px;
      }
    }

    @media #{$mobile-large-media} {
      h3 {
        font-size: 16px;
      }
    }
  }

  height: 100%;
  overflow-y: scroll;

  .mask-bg {
    display: none !important;
  }

  .cardSubject {
    text-align: center !important;
    width: 100% !important;
    background: rgba(0,0,0,.25) !important;
  }

  .cardBuffer {
    p {
      font-weight: bold !important;
      text-align: center !important;;
    }
  }

  .fantastic-card {
    border-radius: 14px;

    @media #{$mobile-large-media} {
      border-radius: 10px;
    }
    .cardSubject {
      padding: 2.5%;
      border-radius: 10px;
      background: rgba(0,0,0,.30) !important;
      text-align: center;
      h1, h2{
        text-shadow: none !important;
      }
    }

    .cardMessage {
      max-height: none !important;
      background: none !important;
    }

    .cardInput {
      button {
        box-shadow: none !important;
        text-shadow: none !important;
        padding: 1.25%;
        border-radius: 10px;
        font-weight: bold;

        font-size: 20px;
        img {
          height: 12px;
        }
        @media #{$tablet-media} {
          font-size: 17px;
          img {
            height: 10px;
          }
          border-radius: 8px;
        }
        @media #{$mobile-large-media} {
          font-size: 15px;
          img {
            height: 12px;
          }
          border-radius: 7px;
        }
        @media #{$mobile-media} {
          font-size: 14px;
          img {
            height: 12px;
          }
          border-radius: 6px;
        }
      }
    }
  }

  .fantastic-card-collection{
    overflow: visible !important;
    .fantastic-card-row {
      border: none !important;
      .cardBody {
        .cardMessage.muted {
          opacity: .65;
          p {
            font-weight: bold !important;
            text-align: center !important;
          }
          max-height: none !important;
        }
      }
    }
    .fantastic-card-row.card-type-info {
      padding: 0% !important;
    }
  }
}

.main-cta {
  // padding-top: 7%;
  // padding-left: 6%;
  // padding-right: 6%;
  // padding-top: 5.5%;
  // padding-bottom: 7.5%;

  @media #{$tablet-media} {
    // padding-top: 15.5%;
    // padding-bottom: 15%;
    padding-left: 5%;
    padding-right: 5%;
  }

  @media #{$mobile-large-media} {
    // padding-top: 21.5%;
    // padding-bottom: 18%;
    padding-left: 5.5%;
    padding-right: 5.5%;
  }

  // position: absolute;
  // height: auto;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;

  position: absolute;
  top: 53%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);

  @media #{$mobile-large-media} {
    top: 48%;
    -webkit-transform: translateY(-48%);
    -ms-transform: translateY(-48%);
    transform: translateY(-48%);
  }

  .cardMessage {
    text-align: center !important;
    // max-width: 80% !important;
    margin: 0 auto !important;

    @media #{$mobile-large-media} {
      max-width: 95% !important;
    }

    p {
      font-size: 28px !important;
      opacity: 1 !important;
      // padding: 1px !important;
      // padding-left: 3px !important;
      // font-weight: 500 !important;
    }

    p:last-of-type {
      // padding-bottom: 9px !important;
    }

    @media #{$mobile-large-media} {
      p {
        font-size: 19px;
      }
    }
  }

  .cardInput {
    margin: 0 auto !important;
    max-width: 75% !important;

    @media #{$mobile-large-media} {
      max-width: 100% !important;
    }
  }

  .table-cell {
    text-align: center !important;

    button {
      margin: 0 auto !important;
    }
  }

  .logo {
    margin-bottom: 3.2%;

    @media #{$tablet-media} {
      margin-bottom: 6.2%;
    }
    @media #{$mobile-large-media} {
      margin-bottom: 10.2%;
    }
  }

  .fantastic-card {
    .cardImage {
      display: block !important;
      margin: 0 auto !important;

      margin-top: 25px !important;
      margin-bottom: 25px !important;

      width: 28.5vh !important;
      height: 28.5vh !important;

      @media #{$tablet-media} {
        width: 29vw !important;
        height: 29vw !important;
      }

      @media #{$mobile-large-media} {
        width: 50vw !important;
        height: 50vw !important;
      }
    }

    .cardSubject {
      h1, h2{
        width: auto !important;
        white-space: break-spaces !important;
        max-width: 100% !important;
        display: block !important;
        font-size: 58px;
      }

      @media #{$tablet-media} {
        h1, h2{
          font-size: 70px;
        }
      }

      @media #{$mobile-large-media} {
        h1, h2{
          font-size: 70px;
        }
      }
    }
  }
}

.fantastic-legal-terms, .fantastic-legal-privacy, .fantastic-contact, .fantastic-faq, .fantastic-product-walkthrough, .fantastic-insights-documentation {
  height: 100%;
  width: 100%;

  padding-top: 3% !important;
  padding-bottom: 1% !important;

  position: fixed;
  z-index: 2000;
  // background: linear-gradient(90deg, #ffaa66, #ff5e62) !important;
  background: #282828 !important;
  // background: radial-gradient(110em at bottom right, #cc1166, #282828, #282828) !important;

  @media #{$tablet-media} {
    // background: radial-gradient(63em at bottom right, #cc1166, #282828, #282828) !important;
  }

  @media #{$mobile-large-media} {
    // background: radial-gradient(63em at bottom right, #cc1166, #282828, #282828) !important;
  }

  @media #{$tablet-media} {
    padding-top: 1.5% !important;
  }
}

.fantastic-landing-page {
  // background: linear-gradient(90deg, #ffaa66, #ff5e62) !important;
  // background: #282828 !important;

  background-position: bottom center !important;
  background-size: cover !important;

  // height: 100vh !important;
  // width: 100% !important;

  .mask-bg-3 {
    opacity: .86 !important;

    background: radial-gradient(110em at bottom right, #cc1166, #282828, #282828) !important;

    @media #{$tablet-media} {
      background: radial-gradient(63em at bottom right, #cc1166, #282828, #282828) !important;
    }

    @media #{$mobile-large-media} {
      background: radial-gradient(63em at bottom right, #cc1166, #282828, #282828) !important;
    }
  }

  position: fixed;
  z-index: 1601 !important;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;

  .cardDismiss-bottom {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 1%;
    // right: auto;
    // bottom: 1%;

    @media #{$tablet-media} {
      top: auto;
      left: 0;
      right: 0%;
      bottom: 1.375%;
    }
  }

  .fantastic-card-collection {
     height: auto;

    .fantastic-card-row {
      .fantastic-card {
        max-width: 950px !important;
        .cardSubject {
          // padding: 1%;
          border-radius: 15px;
          text-align: center !important;
          h1, h2{
            padding: 1.75%;
            padding-bottom: 0 !important;
            text-shadow: none !important;

            font-size: 105px !important;
            margin: 0 !important;
            background: none !important;
            padding: 0px !important;

            // text-overflow: ellipsis !important;
            // white-space: nowrap !important;
            overflow: hidden;
          }

          @media #{$tablet-media} {
            h1, h2{
              font-size: 11.4vw !important;
            }
          }

          @media #{$mobile-media} {
            h1, h2{
              font-size: 10.9vw !important;
            }
          }
        }

        .cardSubjectSubHeader {
          // padding: 1%;
          border-radius: 15px;
          text-align: center !important;

          h1, h2{
            color: #efefef !important;
            padding: 1.75%;
            padding-bottom: 0 !important;
            text-shadow: none !important;

            font-size: 53px !important;
            margin: 0 !important;
            background: none !important;
            padding: 0px !important;

            // text-overflow: ellipsis !important;
            // white-space: nowrap !important;
            overflow: hidden;
          }

          @media #{$tablet-media} {
            h1, h2{
              font-size: 7.2vw !important;
            }
          }

          @media #{$mobile-media} {
            h1, h2{
              font-size: 7.2vw !important;
            }
          }
        }

        .cardMessage {
          max-width: 750px !important;
          max-height: 100% !important;
          background: none !important;

          margin-top: 5px !important;
          margin-bottom: 5px !important;

          border-radius: 35px !important;
          @media #{$tablet-media} {
                border-radius: 28px !important;
          }

          // background: rgba(0,0,0,.33) !important;
          padding-top: 15px !important;
          padding-bottom: 15px !important;
          padding: 10px !important;
          margin-bottom: 15px !important;
          border-radius: 35px !important;

          p {
            font-weight: 350 !important;
            -webkit-line-clamp: initial !important;
            -webkit-box-orient: initial !important;
            text-overflow: initial !important;
            overflow: auto !important;

            max-height: 100% !important;
            text-align: center !important;
            color: #dfdfdf !important;
            font-size: 24px !important;
            // font-weight: 500 !important;
            // display: block !important;
            padding-top: 2px !important;
            padding-bottom: 2px !important;

            @media #{$tablet-media} {
              font-size: 18px !important;
            }

            @media #{$mobile-large-media} {
              font-size: 3.55vw !important;
            }
          }
        }

        .cardInput {
          button {
            box-shadow: none !important;
            text-shadow: none !important;
            padding: 2.05vh !important;
            margin-bottom: 1% !important;
            border-radius: 30px !important;
            font-weight: bold;
            // background: rgba(0,0,0,.30);
            background: rgba(150,150,150,.33);

            font-size: 25px;
            img {
              height: 12px;
            }

            @media #{$tablet-media} {
              font-size: 21px;
              img {
                height: 10px;
              }

              border-radius: 8px;
              padding: 1.75vh !important;
            }

            @media #{$mobile-large-media} {
              font-size: 20px;
              img {
                height: 12px;
              }
              border-radius: 7px;
            }

            @media #{$mobile-media} {
              font-size: 16px;
              img {
                height: 12px;
              }
              border-radius: 6px;
            }
          }
        }
      }
    }
  }

  .fantastic-header {
    // padding: 0px !important;
    // margin: 0px !important;

    // display: block !important;
    // position: absolute;

    // top: 1vh !important;
    // left: 1vh !important;
    // right: 1vh !important;

    // // margin-top: 1.5vh !important;
    // // margin-right: 1vh !important;
    // // margin-left: 1vh !important;

    // bottom: auto !important;

    border: none !important;
    background: none !important;

     .options {
       width: 100%;
       // table-layout: auto !important;
       // margin-top: 0px !important;

       .option.show-background {
         // background: rgba(0,0,0,.3) !important;
         // border-radius: 10px !important;
         padding-left: 10px !important;
         padding-right: 10px !important;
         background: #cc1166 !important;
         font-weight: bold !important;
       }

       .option {
         // background: rgba(150, 150, 150, 0.1) !important;
         // border-radius: 10px !important;
         padding-left: 10px !important;
         padding-right: 10px !important;
         font-weight: bold !important;
       }

       .logo.option {
         padding: 0px !important;
         background: none !important;
       }

       // .option {
       //   // float: right;
       //   width: auto !important;
       //   opacity: 1 !important;
       //   // padding: 0px !important;
       // }
     }
   }

  .fantastic-tags {
    background: none !important;
    .fantastic-card-row {
      padding-top: 0 !important;
    }
    padding: 0px !important;
    top: 37%;

    .cardInput {
      float: none !important;
      display: inline-block !important;
      margin: 0 auto !important;
    }

    .cardInputField {
      width: 100% !important;
    }
  }

  .logo {
    position: relative;
    left: 0;
    right: 0;

    .fantastic-tags {
      margin-top: 1%;
      position: relative !important;
    }

    p {
      text-align: center;
      color: #fff;
      margin: 3px !important;
      font-size: 18px;
      opacity: .9;
      font-weight: 100 !important;

      @media #{$tablet-media} {
        font-size: 13px;
      }

      @media #{$mobile-large-media} {
        font-size: 13px;
      }
    }

    .logo-fan {
      font-size: 70px;
      border-radius: 12px;
      display: inline-block !important;
      font-weight: 500;
      @media #{$tablet-media} {
        font-size: 70px;
      }
      @media #{$mobile-media} {
        font-size: 45px;
      }
    }

    .logo-tastic {
      font-size: 70px;
      display: inline-block !important;
      font-weight: 500;
      @media #{$tablet-media} {
        font-size: 75px;
      }
      @media #{$mobile-media} {
        font-size: 45px;
      }
    }

  }
}

.fantastic-card-collection.relative {
  position: relative !important;
}

.fantastic-card-collection.active {
  // min-height: 50vh !important;
  width: 100%;
  // height: 100%;
  // overflow-y: scroll;

  // background: rgba(0,0,0,.30) !important;
  // border-radius: 30px;

  bottom: 0;

  scroll-snap-type: y mandatory !important;
  // margin-top: 17.5vh !important;

  @media #{$tablet-media} {
    scroll-snap-type: y mandatory !important;
    background: none !important;
    width: 100% !important;
    height: 100%;
    margin-top: 0px !important;
    // margin-top: 14.8vh !important;
    border-radius: 0px;
  }

  .fantastic-card-row.dark {
    .mask-bg-2 {
      display: block !important;
      background: rgba(0,0,0,.93) !important;
      position: absolute !important;
      // background: linear-gradient(90deg, #ffaa66, #ff5e62) !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      opacity: 1 !important;
      z-index: 0 !important;
      // backdrop-filter: blur(5px) !important;
    }

    .fantastic-card {
      // position: fixed !important;
      z-index: 1600 !important;
      box-shadow: none !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 2vh !important;
      right: 2vh !important;
      // background: none !important;
      // height: 100vh !important;
      width: auto !important;
      // padding: 0px !important;

      @media #{$tablet-media} {
        // height: 93vh !important;
      }

      .cardFooter {
        // button {
        //   padding: 1.05vh !important;

        //   img {
        //     height: 20px;
        //   }

        //   @media #{$tablet-media} {
        //     img {
        //       height: 17px;
        //     }
        //   }

        //   @media #{$mobile-large-media} {
        //     img {
        //       height: 15px;
        //     }
        //   }
        //   @media #{$mobile-media} {
        //     img {
        //       height: 15px;
        //     }
        //   }
        // }
      }

      .cardBody{
        // display: none !important;
      }

      .cardCreator {
        // font-size: 25px;

        @media #{$mobile-large-media} {
          font-size: 18px !important;
        }

        opacity: 1 !important;
      }

      .cardToolbar {
        // top: 5% !important;
        left: 0% !important;
        opacity: 1 !important;
      }

      .cardPrompt {
        // position: fixed !important;
        // button {
        //   font-size: 35px !important;
        //   background: none !important;
        //

        // top: 1.5%;
        // left: 1.5%;
        // // bottom: 1%;

        // display: inline-block !important;
        // color: #fff;

        // @media #{$tablet-media} {
        //   position: absolute;
        //   padding-bottom: 2.25% !important;

        //   top: auto !important;
        //   bottom: 0 !important;
        //   left: 0 !important;
        //   right: 0 !important;

        //   z-index: 2002;

        //   margin: 0 auto;
        //   display: block !important;

        //   padding: 0px;
        //   border-radius: 10%;

        //   color: #fff;
        //   margin-top: 0px;
        //   font-size: 25px !important;
        // }
      }

      .cardBody {
        .cardSubject {
          width: 100%;
        }

        .cardSubject {
          font-weight: bold !important;
          // margin-bottom: 0px !important;

          h1, h2{
            // font-size: 19px !important;
            margin: 0 !important;
            background: none !important;
            padding: 0px !important;

            text-overflow: ellipsis !important;
            // white-space: nowrap !important;
            overflow: hidden;
          }

          @media #{$mobile-large-media} {
            h1, h2{
              font-size: 16.5px !important;
            }
          }

          img {
            height: 22px;
            width: 22px;

            @media #{$mobile-large-media} {
              height: 18px;
              width: 18px;
            }
          }
        }

        .cardMessage {
          // margin-top: 5px !important;
          // margin-bottom: 5px !important;
        }
      }
    }
  }

  .fantastic-card-row.tall-background {
     .fantastic-card {
       @media #{$tablet-media} {
         background-size: cover !important;
         background-position: center center !important;
         background-repeat: no-repeat no-repeat !important;
       }
     }

    .mask-bg {
      display: block !important;
      width: auto !important;
      height: 100% !important;
      left: auto !important;
      right: 0 !important;
      opacity: 1 !important;

      @media #{$tablet-media} {
        display: none !important;
      }
    }

    .mask-bg-mobile {
      @media #{$tablet-media} {
        display: none !important;
      }
    }

    .mask-bg-2 {
      display: none !important;
      position: absolute;
      width:100%;
      height:100%;
      top:0 !important;
      left:0 !important;
      right: 0 !important;
      bottom: 0 !important;
      z-index:1;

      // background: linear-gradient(90deg, #ffaa66, #ff5e62) !important;
      background: rgba(0,0,0,.7) !important;

      background-position: center center !important;
      background-repeat: no-repeat no-repeat !important;
      background-size: auto 100% !important;
      opacity: 1 !important;

      @media #{$tablet-media} {
        opacity: 0 !important;
      }
    }
  }

  .fantastic-card-row.tall-background.dark {
    .mask-bg-2 {
      display: none !important;
      background: rgba(0,0,0,.75) !important;
      position: absolute !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      opacity: 1 !important;
      z-index: 3 !important;
    }
  }

  .fantastic-card-row.background-loaded {
    .fantastic-card {
      .cardHeader {
        background-size: cover !important;
        background-position: center center !important;

        .mask-bg-mobile {
          @media #{$tablet-media} {
            visibility: visible !important;
          }
        }
      }
    }
  }

  .fantastic-card-row.background-none {
    .fantastic-card {
      .cardHeader {
        background-size: cover !important;
        background-position: center center !important;
        // background: rgba(0,0,0,.25) !important;
      }
    }
  }

  .fantastic-card-row.card-type-loader {
    overflow: visible !important;
  }

  .fantastic-card-row {
    @extend .row;

    scroll-snap-align: start;
    scroll-snap-stop: always;

    position: relative;

    background-position: center 38% !important;
    background-repeat: no-repeat no-repeat !important;
    background-size: 0 0 !important;

    .mask-bg-mobile.dark {
      @media #{$tablet-media} {
        visibility: hidden !important;
      }
    }

    .mask-bg {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: none !important;

      @media #{$tablet-media} {
        top: 21% !important;
        width: 100% !important;
        height: auto !important;
        display: block !important;
        visibility: hidden !important;
      }
    }

    .mask-bg-mobile {
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
      z-index: 2;

      display: none !important;

      @media #{$tablet-media} {
        // display: block !important;
        background-size: 100% auto !important;
        background-position: center 42% !important;
        background-repeat: no-repeat no-repeat !important;
        visibility: hidden !important;
        display: none !important;
      }
    }

    .mask-bg-2 {
      display: none !important;
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
      opacity: 0 !important;

      @media #{$tablet-media} {
        opacity: 1 !important;
        // backdrop-filter: blur(25px) !important;
      }
    }

    overflow: hidden !important;

    .fantastic-card {
      .cardToolbar {
        // background: rgba(21,21,21,.90) !important;
        position: absolute !important;
        width: 100% !important;
        top: 0px !important;
        left: 0px !important;
        right: 0px !important;

        padding-top: 8px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;

        white-space: nowrap !important;
        overflow-x: scroll !important;
        overflow-y: hidden !important;
        opacity: 1;
        z-index: 5 !important;

        p {
          opacity: 1;
          font-size: 11px;
          background: transparent;
          text-shadow: #444 1px 1px 3px !important;
        }
      }

      .cardSubject {
        @media #{$mobile-large-media} {
          h1, h2{
            font-size: 16.5px !important;
          }

          p {
            font-size: 11px !important;
          }
        }

        margin-bottom: 10px;
      }

      .cardMessage {
        .cardCreator {
          cursor: pointer !important;
          opacity: 1 !important;
          width: 100% !important;
          margin-top: 0px !important;

          .userProfilePictureBackdrop {
            margin: 0 !important;
            // opacity: .9 !important;

            .userProfilePicture {
              float: left !important;

              display: block !important;
              height: 22px !important;
              width: 22px !important;

              border-radius: 50% !important;
              // margin: 0 auto !important;

              // border: 5.5px solid #222 !important;

              @media #{$tablet-media} {
                max-width: 100% !important;
                height: 3vh !important;
                width: 3vh !important;
              }

              @media #{$mobile-large-media} {
                margin-bottom: 1% !important;
                max-width: 100% !important;
                height: 5vw !important;
                width: 5vw !important;
              }
            }
          }

          .userInfo {
            color: #efefef !important;
            float: left !important;
            padding-left: .5% !important;
            max-width: 85% !important;
            // max-width: 120px !important;

            // display: -webkit-inline-box !important;
            // -webkit-line-clamp: 2 !important;
            // -webkit-box-orient: vertical !important;
            // text-overflow: ellipsis !important;
            overflow: hidden !important;

            // @media #{$tablet-media} {
            //   width: 68% !important;
            // }

            // @media #{$mobile-large-media} {
            //   width: 85% !important;
            // }

            .userBio {
              width: fit-content !important;

              p {
                padding-top: 0px !important;
              }

              margin-bottom: 1.5%;
              border-radius: 25px !important;
              padding: 7px !important;
              padding-top: 0px !important;
              padding-left: 4px !important;
              text-align: left !important;

              .name {
                font-weight: bold !important;
              }

              .message {
                padding-left: 6px !important;
                font-weight: 100 !important;
                color: #ddd !important;
              }

              .name, .price, .price-pending, .price-completed {
                width: 100% !important;
                text-align: left !important;
                font-weight: bold;
                padding: 0px !important;
                padding-left: 0px !important;

                // font-size: 12.5px !important;

                // @media #{$tablet-media} {
                //   font-size: 12.5px !important;
                // }

                // @media #{$mobile-large-media} {
                //   font-size: 12px !important;
                // }

                border-radius: 5px !important;
                // display: table; //TODO: kc, hack

                // max-width: 70% !important;
                // display: -webkit-inline-box !important;
                // -webkit-line-clamp: 1 !important;
                // -webkit-box-orient: vertical !important;
                // text-overflow: ellipsis !important;
                // overflow: hidden !important;
              }

              .price {
                font-size: 15.5px !important;

                @media #{$tablet-media} {
                  font-size: 15.5px !important;
                }

                @media #{$mobile-large-media} {
                  font-size: 15px !important;
                }
              }

              .bio {
                color: #ccc !important;
                text-align: left !important;
                font-weight: 100 !important;
                padding-left: 0px !important;

                font-size: 16.5px !important;

                @media #{$tablet-media} {
                  font-size: 15.5px !important;
                }

                @media #{$mobile-large-media} {
                  font-size: 13.5px !important;
                }

                // background: rgba(0,0,0,.25) !important;
                border-radius: 5px !important;
                // display: table; //TODO: kc, hack
                max-width: 100% !important;
                margin: 0 auto !important;
                margin-top: 1.5% !important;
                // float: left !important;

                @media #{$tablet-media} {
                  max-width: 100% !important;
                }
              }
            }
          }
        }
      }

      .cardToolbar {
        .cardCreator, .createdAt, .cardUpdate, .cardActivity,
          .cardReport, .cardShare, .cardSource, .cardActions, .cardTrending {
          margin-top: 3px;
          cursor: pointer !important;
          padding: 0px;
          // padding-top: 1px;
          padding-bottom: 2px;

          border-radius: 5px;
          display: inline-block;
        }

        .cardActions {
          font-weight: bold !important;
          font-size: 35px !important;
          padding: 0px !important;
          margin: 0px !important;
          // margin-top: -6px !important;

          height: 25px !important;
          width: 25px !important;
          border-radius: 8px !important;
          background: rgba(0,0,0,.70) !important;
          padding: 5px !important;
        }

        .cardTrending {
          padding: 4px !important;
          // background: rgba(0,0,0,.5) !important;
        }

        .cardActivity {
          display: none !important;
          img {
            padding-right: 2px !important;
          }
        }
      }

      .cardInput {
        input, textarea, button {
          margin-top: 10px;
          margin-bottom: 10px;
          @media #{$tablet-media} {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
      }

      .cardFooter {
        // margin-top: 5px;
        // @media #{$mobile-large-media} {
        //   margin-top: 5.5px;
        // }
        margin-top: 2px !important;

        bottom: 0;
      }
    }
  }
}

#root {
  height: 100% !important;
  overflow: hidden !important;
}

.top {
  z-index: 1000;
  position: fixed;
  width: 100%;
  top: 0px;
  text-align: right;

  .top-info {
    margin-right: 25px;
  }

  a {
    padding: 10px;
    font-size: 15px;
  }
}

.modal {
  pointer-events: all !important;
  width:100%;

  // height: 100vh !important;
  // max-height: calc(100vh + env(safe-area-inset-top)) !important;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

  position: fixed !important;
  top:0;
  left:0;
  bottom:0px;
  right:0;

  z-index:1700;

  .fantastic-card-collection {
    height: 100%;

    @media #{$tablet-media} {
      // height: 95%;
      .cardInput {
        width: 100%;
      }
    }

    z-index: 2001;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;

    input {
      border-color: #474747;
      box-shadow: none !important;
    }
    textarea {
      border-color: #474747;
      box-shadow: none !important;
    }
    button {
      border-color: #999;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .modal-bg {
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index:1700;
    background-size: cover !important;
    // background: linear-gradient(90deg, #ffaa66, #ff5e62) !important;
    // background: linear-gradient(-0.1turn , #cc1166, #333, #282828, #121212) !important;
    background: #282828 !important;

    // opacity: .985;
    opacity: 1;
  }
}

.modal.inline {
  position: relative;
}

.modal.super {
  z-index: 1800 !important;
}

.fantastic-card-row.card-type-info {
  max-height: 100% !important;
  overflow-y: scroll !important;

  @media #{$tablet-media} {
    max-height: 100% !important;
    overflow-y: scroll !important;
  }

  .confetti {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .fantastic-card {
    height: 100%;

    padding: 1% !important;
    padding-top: 2.5% !important;

    @media #{$tablet-media} {
      padding: 5% !important;
      padding-top: 20px !important;
    }

    @media #{$mobile-large-media} {
      max-width: 100%;
    }

    .cardSubject {
      h1, h2{
        width: auto !important;
        max-width: 100% !important;
        text-shadow: none !important;
      }
    }

    .cardBody {
      height: 100% !important;
    }

    .cardMessage {
      border-radius: 10px !important;
      // background: rgba(100,100,100,.25) !important;
      padding: 7px !important;
      padding-left: 0px !important;

      p {
        font-size: 20px;
        // display: -webkit-inline-box !important;
        -webkit-line-clamp: initial !important;
        -webkit-box-orient: initial !important;
        text-overflow: initial !important;
        overflow: auto !important;
      }

      @media #{$mobile-large-media} {
        p {
          font-size: 15px;
        }
      }
    }

    .cardFooter {
      max-height: 90% !important;

      .cardInput {
        .cardInputField { }

        .inputLinksData {
          padding-top: 10px;
          height: fit-content;

          .inputLinks {
            padding: 8px !important;
            margin-top: 7px;
            margin-bottom: 7px;

            z-index: 5000 !important;
            background: rgba(0,0,0,.25) !important;
            position: relative; // TODO: kemezie Hack?
            height: 55vh !important;

            @media #{$mobile-large-media} {
              height: 50vh !important;
            }
            overflow-y: scroll !important;
          }
        }
      }
    }
    .cardContent {
      display: none !important;
    }
  }
}

.fantastic-card-row.card-type-info {
  .cardMessage {
    overflow-y: initial !important;
    max-height: initial !important;
    height: auto !important;

    padding: 0px !important;
    margin-top: 5px;
    margin-bottom: 5px;

    @media #{$mobile-large-media} {
      margin-top: 5px;
    }

    p {
      padding-left: 0px !important;
      padding: 0px !important;
    }
  }

  .cardInput {
    .cardPreview {
      overflow: hidden;
      border-radius: 25px !important;

      margin-top: 1.5vh !important;
      margin-bottom: 1.5vh !important;
      position: relative !important;
      display: block !important;

      .cardDismiss {
        display: none !important;
      }

      transition: height .05s linear;

      .fantastic-card-collection.active {
        margin: 0% !important;
        min-height: auto !important;
      }

      .fantastic-card-collection {
        position: relative !important;

        .fantastic-card-row.card-type-info {
          .fantastic-card {
            padding: 0px !important;
          }
        }

        .fantastic-card-row.card-type-promo {
          .fantastic-card {
            padding: 0% !important;
            left: 0 !important;
            right: 0 !important;

            width: 100% !important;
            height: 100% !important;

            // border-radius: 0px !important;
            // max-width: 630px !important;
            // margin-left: 10px;
            // margin-right: 10px;

            .cardSubject {
              h1, h2{
                max-width: 70% !important;
                background: none !important;
                // text-shadow: #333 2px 2px 3px !important;
                font-size: 22px !important;

                @media #{$tablet-media} {
                  font-size: 15px !important;
                }
              }
            }

            .cardBody {
              height: auto !important;
            }

            .cardMessage {
              p {
                padding: 5px;
                padding-left: 0px !important;
              }
            }

            .cardContent {
              display: block !important;

              .rect {
                height: 275px;

                @media #{$tablet-media} {
                  height: 270px;
                }

                @media #{$mobile-large-media} {
                  height: 250px;
                }

                @media #{$mobile-media} {
                  height: 190px;
                }
              }
            }

            .cardInput {
              div.tag {
                font-size: 11px;

                @media #{$tablet-media} {
                  font-size: 9px !important;
                }
              }
            }

            input {
              font-size: 13px !important;
              margin-top: 3px;
              margin-bottom: 3px;
            }

            padding-bottom: 4.5% !important;

            bottom: 0 !important;
          }
        }
      }
    }

    .cardPreview.nano {
      border-radius: 15px !important;
    }

   .cardPreview.showBackground {
      background: rgba(0,0,0,.25) !important;
    }

    .cardPreview{
      .cardTags {
        direction: rtl !important;
        margin-top: 0px !important;
        padding: 0px !important;
        .cardInput {
          float: left !important;
          width: 100%;
        }
        .table-cell {
          display: none !important;
        }
      }
    }

    .cardPreview.super-nano, .cardPreview.nano {
      .fantastic-card-collection .fantastic-card-row.card-type-promo {
        .fantastic-card {
          padding: 5px !important;
          padding-bottom: .5% !important;
          background: none !important;

          .cardHeader {
            // height: 0% !important;
            position: absolute !important;
            height: 100% !important;
            z-index: -1 !important;
            top: 0 !important;
            left: 0 !important;
          }

          .cardBody {
            padding: 0px !important;
          }

          .cardSubject {
            margin-bottom: 0px !important;
            @supports (-webkit-line-clamp: 2) {
              white-space: initial !important;
              display: -webkit-inline-box !important;
              -webkit-line-clamp: 1 !important;
              -webkit-box-orient: vertical !important;
            }
          }

          .cardMessage {
            display: none !important;
          }

          .cardTags {
            position: relative !important;
            top: auto !important;
            left: auto !important;
            right: auto !important;
            bottom: auto !important;

            div.tag {
              font-size: 15px !important;

              @media #{$tablet-media} {
                font-size: 14px !important;
              }

              @media #{$mobile-large-media} {
                font-size: 13px !important;
                border-radius: 6px;
              }
            }
          }

          bottom: 0 !important;
        }
      }
    }
  }
}

.fantastic-card-row.card-type-info.stack {
  height: auto !important;
}

.fantastic-card-row.expand {
  width: 100% !important;
  max-width: 100% !important;

  .fantastic-card {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.fantastic-card-row.card-type-promo {
  position: relative !important;
  // box-shadow: 1px 1px 5px #353535 !important;
  height: 100% !important;
  // border-radius: 30px !important;
  // margin-bottom: 10vh !important;

  @media #{$tablet-media} {
    // border-radius: 0px !important;
  }

  .fantastic-card {
    // background: linear-gradient(transparent, transparent, rgba(15,15,15,.98), rgba(10,10,10,1)) !important;
    // backdrop-filter: blur(10px) !important;
    background: #151515 !important;

    // position: relative !important;
    // padding: 8.5px;
    // border-radius: 4px !important;

    // bottom: 3.8% !important;

    height: 100% !important;
    width: 100% !important;
    // left: 0 !important;
    // right: 0 !important;
    // bottom: 0 !important;
    // top: 0 !important;

    height: fit-content !important;

    border-radius: 25px !important;

    @media #{$tablet-media} {
      left: 0 !important;
      right: 0 !important;
      margin-left: 0px !important;
      margin-right: 0px !important;
      // padding-bottom: 8.5% !important;
      bottom: 0 !important;
      // border-radius: 0 !important;

      height: fit-content !important;
      border-radius: 18px !important;
    }

    @media #{$mobile-large-media} {
      // padding: 6px;
      height: fit-content !important;
    }

    .cardHeader {
      z-index: 2;
      height: 50vh !important;
      position: relative !important;
      background-size: cover !important;
      background-position: center center !important;

      border-top-left-radius: 25px !important;
      border-top-right-radius: 25px !important;

      @media #{$tablet-media} {
        border-top-left-radius: 25px !important;
        border-top-right-radius: 25px !important;
      }

      @media #{$mobile-large-media} {
        height: 50vh !important;
      }
    }

    .cardToolbar {
      position: absolute !important;
      width: 100% !important;
      top: 0px !important;
      left: 0px !important;
      right: 0px !important;
      padding-top: 8px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
      white-space: nowrap !important;
      overflow-x: scroll !important;
      overflow-y: hidden !important;
      opacity: 1;
      z-index: 5 !important;

      .cardActions {
        font-weight: bold !important;
        font-size: 35px !important;
        padding: 0px !important;
        margin: 0px !important;
        height: 25px !important;
        width: 25px !important;
        border-radius: 8px !important;
        background: rgba(0, 0, 0, 0.7) !important;
        padding: 5px !important;
      }
    }

    .cardSubject {
      font-weight: bold !important;

      h1, h2{
        background: none !important;
        text-shadow: #333 2px 2px 3px !important;
        padding-left: 0px !important;
        color: #fff !important;
        font-size: 20.5px !important;
      }

      @media #{$mobile-large-media} {
        h1, h2{
          font-size: 18.5px !important;
        }
      }
      img {
        height: 22px;
        width: 22px;

        @media #{$mobile-large-media} {
          height: 18px;
          width: 18px;
        }
        padding-bottom: 2px !important;
      }
    }

    .cardInput {
      input, textarea, button {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      button {
        // opacity: .8 !important;
        background: rgba(45,45,45,.65) !important;
        // box-shadow: 1px 1px 2px #282828;
        // background: none !important;

        text-shadow: none !important;
        border-radius: 15px;
        padding: 1vh !important;

        font-size: 17px;
        img {
          height: 17px;
          margin: 5px !important;
          margin-top: 0px !important;
          margin-bottom: 0px !important;
        }
        @media #{$tablet-media} {
          font-size: 17px;
          img {
            height: 17px;
          }
          border-radius: 10px;
        }
        @media #{$mobile-large-media} {
          font-size: 17px;
          img {
            height: 17px;
          }
          border-radius: 10px;
        }
        @media #{$mobile-media} {
          font-size: 17px;
          img {
            height: 15px;
          }
          border-radius: 10px;
        }

        div {
          font-weight: 800 !important;
          color: white !important;
        }
      }

      div.pressed {
        button {
          // background: #ff5e62 !important;
          background: #cc1166 !important;
        }
      }
    }

    .cardBody {
      // position: absolute !important;
      // bottom: 15.5% !important;
      padding: 12px !important;
      padding-right: 10px !important;
      height: 43% !important;
      max-height: 100% !important;
      overflow-y: scroll !important;
      width: 100% !important;
      // border-radius: 10px !important;
      // padding: 5px !important;
      // background: rgba(0,0,0,.85) !important;

      @media #{$tablet-media} {
        padding-right: 5px !important;
      }

      .cardMessage {
        border-radius: 0px !important;
        background: none !important;
        padding: 0 !important;

        margin: 0 !important;
        margin-bottom: 15px !important;
        // display: none !important;

        p {
          text-shadow: #333 2px 2px 3px !important;
          // max-height: 120px !important;

          // @media #{$tablet-media} {
          //   max-height: 120px !important;
          // }

          // @media #{$mobile-large-media} {
          //   max-height: 120px !important;
          // }
        }
      }
    }

    .cardTags {
      opacity: .70;
      display: block !important;

      div.tag {
        font-size: 12px;

        @media #{$tablet-media} {
          font-size: 11px !important;
        }
      }

      width: 100% !important;
      position: absolute !important;
      bottom: 52px !important;
    }

    border-radius: 6px;

    .cardBuffer {
      display: none !important;
    }

    .cardFooter {
      position: relative !important;
      // margin: 10px !important;
      bottom: 1% !important;

      margin-right: 10px !important;
      margin-left: 10px !important;

      @media #{$mobile-large-media} {
        margin-right: 6px !important;
        margin-left: 6px !important;
      }
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes bounce {
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
   40% {transform: translateY(-10px);}
   60% {transform: translateY(-5px);}
}

.fantastic-card-row.card-type-info {
  .fantastic-card-row.card-type-loader {
    .fantastic-card {
      padding: 0px !important;
    }
  }
}

.fantastic-card-row.card-type-loader {
  .mask-bg {
    display: none !important;
  }

  min-height: 5vh;
  height: 100% !important;

  .fantastic-card {
    @extend .dot-flashing;
    // display: none !important;
  }

  // .fantastic-card {
  //   background: none !important;
  //   opacity: 0;
  //   // top: 35% !important;

  //   border: 8px solid transparent !important;
  //   border-top: 8px solid white !important;
  //   border-radius: 50% !important;
  //   width: 100px !important;
  //   height: 100px !important;

  //   position: absolute;
  //   top: 0 !important;
  //   bottom: 0 !important;
  //   left: 0 !important;
  //   right: 0 !important;
  //   margin: auto !important;

  //   animation: spin .80s linear infinite;
  //   -webkit-animation: spin .80s linear infinite;
  //   -ms-animation: spin .80s linear infinite;

  //   @media #{$tablet-media} {
  //     border: 6px solid transparent !important;
  //     border-top: 6px solid white !important;
  //     width: 80px !important;
  //     height: 80px !important;
  //   }

  //   @media #{$mobile-large-media} {
  //     border: 5px solid transparent !important;
  //     border-top: 5px solid white !important;
  //     width: 70px !important;
  //     height: 70px !important;
  //   }

  //   @media #{$mobile-media} {
  //     // width: 140px;
  //     // height: 140px;
  //   }

  //   .cardContent {
  //     display: none !important;
  //   }
  // }
}

.fantastic-card-row.card-type-loader.stack {
  height: auto !important;

  .fantastic-card {
    position: relative !important;
  }
}

p.row.input {
  font-size: 16px !important;

  @media #{$tablet-media} {
    font-size: 14px !important;
  }

  @media #{$mobile-large-media} {
    font-size: 12px !important;
  }
}

p {
  font-size: 14px;

  @media #{$tablet-media} {
    font-size: 12px;
  }

  @media #{$mobile-large-media} {
    font-size: 12px;
  }

  margin: 0;
  text-align: left;
}

p.highlight {
  color: #cc1166;
}

.content {
  opacity: .9;
  margin:0;
  top: 50px;
  left: 0;
  right: 0;
}

.rect {
  box-shadow: 0px 0px 400px 25px #222;
  // width: 100% !important;
  // max-width: 625px;
  // height: auto;

  width: 100% !important;
  min-height: 32.5vh !important;
  margin: 0 auto !important;

  // @media #{$tablet-media} {
  //   max-width: 100% !important;
  //   height: 252px;
  // }

  // @media #{$mobile-large-media} {
  //   max-width: 100% !important;
  //   height: 190px;
  // }
}

.card-rows-header {
  margin: 2.25% !important;
  font-size: 35px !important;
  margin-bottom: 1.5% !important;
  text-align: center;

  @media #{$tablet-media} {
    margin: 2.5% !important;
  }
  display: none;
}

.card-row {
 border-radius: 18px !important;
 padding-top: 1.25% !important;

 // scroll-snap-align: start;
 // scroll-snap-stop: always;
 max-width: 625px !important;
 @media #{$tablet-media} {
   max-width: 100% !important;
 }

 h3 {
   font-size: 22px !important;
   margin: 0px !important;
   margin-bottom: 3.25% !important;
   // text-decoration: underline !important;
   cursor: pointer !important;
   // background: rgba(0,0,0,.25) !important;
   border-radius: 15px !important;
   width: 100% !important;
   text-align: center !important;
   // opacity: .85 !important;

   // background: rgba(60, 60, 60, .7) !important;
   padding: 10px !important;
   // padding-left: 0px !important;
   color: #fff !important;
   font-weight: bold !important;

   background: rgba(10,10,10,.125) !important;

   @media #{$tablet-media} {
     border-radius: 15px !important;
   }
 }

 h3.no-posts-found {
   font-size: 25px !important;
   // width: 55% !important;
   margin: 0 auto !important;
   padding: 2.5vw !important;
   border-radius: 10px !important;
   font-weight: 400 !important;
   max-width: 625px !important;
   width: 100% !important;
   color: #fff !important;
   // background: rgba(85,85,85,.4) !important;
   background: rgba(103, 103, 103, 0.25) !important;
   font-weight: 500 !important;
   text-align: left !important;

   @media #{$tablet-media} {
     font-size: 19px !important;
     width: 100% !important;
     max-width: 100% !important;
     text-align: center !important;
     // padding: 6px;
   }

   @media #{$mobile-large-media} {
     font-size: 17px !important;
     width: 100% !important;
     max-width: 100% !important;
     // padding: 6px;
   }
 }

 margin: 3% !important;
 margin-top: 1% !important;

 margin: 0 auto !important;
 // margin-bottom: 1.25% !important;

 height: 46% !important;

 .card-row-cards {
   margin-right: -1% !important;
   border-radius: 4px !important;
   padding-top: 3px !important;
   padding-bottom: 3px !important;

   height: 100% !important;

   // white-space: nowrap !important;
   // overflow-x: hidden !important;
   overflow-y: hidden !important;

   // scroll-snap-type: x mandatory;

   .fantastic-card-row.card-type-promo, .fantastic-card-row.card-type-loader {
     // margin-right: 1% !important;
     margin-bottom: 4.5% !important;
     height: 100% !important;
     // width: 24% !important;
     width: 100% !important;
     display: inline-block !important;
     overflow: hidden !important;

     height: fit-content !important;

     .fantastic-card {
       // height: 100% !important;
       // height: 37.5vh !important;
     }

     @media #{$tablet-media} {
     }

     @media #{$mobile-large-media} {
       margin-bottom: 5vh !important;
     }
   }

   .fantastic-card-row.card-type-promo {
     // border-radius: 12px !important;
     // border-radius: 25px !important;
     // border: 1px solid #333 !important;

     // @media #{$mobile-large-media} {
     //   border-radius: 18px !important;
     // }
   }

   .fantastic-card-row.card-type-loader {
     height: 70vh !important;
     border-radius: 25px !important;
     background: #222 !important;
   }
 }

 @media #{$tablet-media} {
   margin: 0px !important;
   margin-bottom: 6% !important;

   h3 {
     font-size: 24px !important;
     margin: 0px !important;
     margin-bottom: 3.5% !important;
   }

   height: 38% !important;

   .card-row-cards {
      overflow-x: hidden !important;
     .fantastic-card-row.card-type-promo, .fantastic-card-row.card-type-loader {
       margin-right: 1% !important;
       // border-radius: 18px !important;
       height: 100% !important;
       // width: 49% !important;
       width: 100% !important;
       display: inline-block !important;
       overflow: hidden !important;

       height: fit-content !important;

       .fantastic-card {
         // height: 100% !important;
         // height: 50vh !important;
       }
     }

     .fantastic-card-row.card-type-loader {
        height: 60vh !important;
     }
   }

 }

 @media #{$mobile-large-media} {
   height: 15% !important;
   margin-bottom: 0% !important;

   h3 {
     font-size: 18px !important;
     margin: 0px !important;
     margin-bottom: 3.5% !important;
   }

   .card-row-cards {
     .fantastic-card-row.card-type-promo, .fantastic-card-row.card-type-loader {
       // margin-right: 3% !important;
       width: 100% !important;
       // border-radius: 18px !important;
       height: 100% !important;
       display: inline-block !important;
       overflow: hidden !important;

       height: fit-content !important;

       .fantastic-card {
         // height: 40vh !important;
         // height: 100% !important;
       }
     }
     .fantastic-card-row.card-type-loader {
       height: 5vh !important;
     }
   }
 }
}

.fantastic-card-collection.active.isScrolling {
  .card-row-cards {
    overflow-x: hidden !important;
  }
}

iframe {
  display: block;
  border: none;
  overflow: hidden;
}
