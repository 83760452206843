@font-face {
  font-family: 'Avenir-Medium-Installed';
  src: url("../font/Avenir-Medium/Avenir-Medium.woff2") format("woff2"), url("../font/Avenir-Medium/Avenir-Medium.woff") format("woff"), url("../font/Avenir-Medium/Avenir-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir-Heavy-Installed';
  src: url("../font/Avenir-Heavy/Avenir-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: 'Verdana-Installed';
  src: url("../font/Verdana/Verdana.eot?#iefix") format("embedded-opentype"), url("../font/Verdana/Verdana.woff2") format("woff2"), url("../font/Verdana/Verdana.woff") format("woff"), url("../font/Verdana/Verdana.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'Avenir', 'Lato', 'Avenir-Heavy-Installed', 'Avenir-Medium-Installed', 'Arial';
  box-sizing: inherit;
}

:focus {
  outline: 0 !important;
}

html {
  overscroll-behavior: none !important;
  /*   <--- the trick    */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #282828;
  width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  overflow: hidden !important;
  /* Prevents the address bar from hiding on scroll */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

body {
  margin: 0;
  color: white;
  width: 100%;
  height: 100% !important;
  /* Ensures the body takes up the full screen height */
  overflow: hidden !important;
}

.spacer {
  padding: 2.5vh !important;
}

.text-align-left {
  text-align: left !important;
  float: left !important;
  padding-right: 1% !important;
}

.text-align-right {
  text-align: right !important;
  float: right !important;
  padding-left: 1% !important;
}

.mask-bg-3 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100% !important;
  max-height: 110vh;
  width: 100% !important;
  z-index: -1;
  background: #282828 !important;
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 481px) {
  .mobile, .mobile-responsive, .responsive {
    text-align: center !important;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 641px) {
  .mobile-large, .mobile-responsive, .responsive {
    text-align: center !important;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.full-width {
  width: 100% !important;
}

@media only screen and (max-width: 961px) {
  .tablet-full-width {
    width: 100% !important;
    text-align: center !important;
  }
}

@media only screen and (max-width: 961px) {
  .tablet, .responsive {
    text-align: left;
  }
}

@media only screen and (max-width: 1025px) {
  .laptop, .responsive {
    text-align: left;
  }
}

@media only screen and (max-width: 1281px) {
  .desktop, .responsive {
    text-align: left;
  }
}

@media only screen and (min-width: 1281px) {
  .desktop-large, .responsive {
    text-align: left;
  }
}

@media only screen and (max-width: 961px) {
  .desktop-only {
    display: none !important;
  }
}

.mobile-only {
  display: none !important;
}

@media only screen and (max-width: 961px) {
  .mobile-only {
    display: block !important;
  }
}

.dot-flashing, .fantastic-card-row.card-type-loader .fantastic-card {
  width: 12px !important;
  height: 12px !important;
  border-radius: 6px;
  background-color: rgba(204, 17, 102, 0.8);
  color: rgba(204, 17, 102, 0.8);
  animation: dotFlashing .75s infinite linear alternate;
  animation-delay: .25s;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 40% !important;
  -webkit-transform: translateY(-40%) !important;
  -ms-transform: translateY(-40%) !important;
  transform: translateY(-40%) !important;
  margin: 0 auto !important;
}

.dot-flashing::before, .fantastic-card-row.card-type-loader .fantastic-card::before, .dot-flashing::after, .fantastic-card-row.card-type-loader .fantastic-card::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before, .fantastic-card-row.card-type-loader .fantastic-card::before {
  left: -18px;
  width: 13px;
  height: 13px;
  border-radius: 6.5px;
  background-color: rgba(204, 17, 102, 0.8);
  color: rgba(204, 17, 102, 0.8);
  animation: dotFlashing .75s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after, .fantastic-card-row.card-type-loader .fantastic-card::after {
  left: 18px;
  width: 13px;
  height: 13px;
  border-radius: 6.5px;
  background-color: rgba(204, 17, 102, 0.8);
  color: rgba(204, 17, 102, 0.8);
  animation: dotFlashing .75s infinite alternate;
  animation-delay: .75s;
}

@keyframes dotFlashing {
  0% {
    background-color: rgba(204, 17, 102, 0.8);
  }
  50%,
  100% {
    background-color: #fff;
  }
}

.modal .dot-flashing, .modal .fantastic-card-row.card-type-loader .fantastic-card, .fantastic-card-row.card-type-loader .modal .fantastic-card {
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
  margin: 0 auto !important;
}

.fit-height {
  height: fit-content !important;
}

.super-super-nano {
  height: 1.4em !important;
}

.super-super-nano .dot-flashing, .super-super-nano .fantastic-card-row.card-type-loader .fantastic-card, .fantastic-card-row.card-type-loader .super-super-nano .fantastic-card {
  width: 7px !important;
  height: 7px !important;
}

.super-super-nano .dot-flashing::before, .super-super-nano .fantastic-card-row.card-type-loader .fantastic-card::before, .fantastic-card-row.card-type-loader .super-super-nano .fantastic-card::before {
  left: -10px !important;
  width: 7px !important;
  height: 7px !important;
}

.super-super-nano .dot-flashing::after, .super-super-nano .fantastic-card-row.card-type-loader .fantastic-card::after, .fantastic-card-row.card-type-loader .super-super-nano .fantastic-card::after {
  left: 10px !important;
  width: 7px !important;
  height: 7px !important;
}

.super-nano {
  height: fit-content !important;
}

.super-nano {
  border-radius: 5px !important;
  height: 5vh !important;
}

.nano {
  height: 120px !important;
}

@media only screen and (max-width: 961px) {
  .nano {
    height: 105px !important;
  }
}

@media only screen and (max-width: 641px) {
  .nano {
    height: 95px !important;
  }
}

.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card {
  background: none !important;
  padding-bottom: 1% !important;
}

.micro {
  height: 17vh !important;
}

.micro .fantastic-card-row.card-type-promo .fantastic-card {
  background: none !important;
}

.mini {
  height: 32vh !important;
}

.small {
  height: 40vh !important;
}

.medium {
  height: 47vh !important;
}

.large {
  height: 55vh !important;
}

.x-large-3 {
  height: 64.5vh !important;
}

.x-large-2 {
  height: 62vh !important;
}

.x-large {
  height: 70vh !important;
}

.x-large .fantastic-card .cardList .titles .title {
  padding: .5% !important;
}

.row::after, .fantastic-card-collection.active .fantastic-card-row::after {
  content: "";
  clear: both;
  display: table;
}

.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

[class*="col-"] {
  float: left;
  min-height: 1px;
}

.paypalCheckoutContainer {
  background: #efefef !important;
  border-radius: 15px !important;
  padding: 25px !important;
  margin-top: 5px !important;
  margin-bottom: 20px !important;
}

.transparent {
  background: transparent !important;
}

.transparent button {
  background: transparent !important;
}

p.charCounter {
  position: absolute !important;
  right: 8px !important;
  bottom: 8px !important;
  font-weight: cold !important;
  font-size: 19.5px !important;
}

@media only screen and (max-width: 961px) {
  p.charCounter {
    font-size: 17.5px !important;
  }
}

@media only screen and (max-width: 641px) {
  p.charCounter {
    font-size: 16px !important;
  }
}

p.valid {
  color: #66cc11 !important;
}

p.invalid {
  color: #cc1111 !important;
}

.center {
  text-align: center !important;
}

.center p {
  text-align: center !important;
}

.muted, .muted.visible {
  opacity: .87 !important;
}

.muted button, .muted.visible button {
  background: rgba(70, 70, 70, 0.37) !important;
  box-shadow: none !important;
}

.muted.transparent {
  opacity: .85 !important;
  background: transparent !important;
}

.muted.transparent button {
  background: transparent !important;
}

.no-background {
  background: none !important;
  background-color: transparent !important;
}

.no-display {
  display: none !important;
}

.table-cell.visible {
  display: table-cell !important;
}

@media only screen and (max-width: 961px) {
  .table-cell.visible {
    display: block !important;
  }
}

.table-cell.expanded {
  width: 80%;
}

.table-cell.minimized {
  width: 14%;
}

.table-cell.minimized div {
  display: none !important;
}

.table-cell.minimized button img {
  margin: 0px !important;
}

.visible {
  display: block !important;
  opacity: 1 !important;
}

.visible.no-display {
  display: none !important;
}

.hidden {
  visibility: hidden !important;
  position: absolute !important;
  z-index: -1 !important;
}

.confetti {
  width: 10px !important;
  margin: 0 auto !important;
  z-index: 10 !important;
}

.logo-visible .logo {
  display: block !important;
  opacity: 1 !important;
}

.logo-hidden .logo {
  display: none !important;
}

.card, .fantastic-card {
  display: inline-block;
  margin: 0 auto;
  background-size: cover;
}

.logo {
  text-align: center;
  background: none !important;
}

.logo .logo-fan {
  background-color: #cc1166;
  padding: 2.5px !important;
  margin-right: .5px !important;
  border-radius: 4px;
  text-shadow: none !important;
}

.logo .logo-insights {
  background-color: #cc1166;
  font-family: 'Verdana-Installed', 'Verdana' , 'Lato', 'Arial' !important;
  margin-left: 5px !important;
  border-radius: 4px;
  text-shadow: none !important;
  padding: 2.5px !important;
  font-weight: 900 !important;
  font-style: italic !important;
}

.logo h4 {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  padding: 0px;
  margin: 0px;
  margin-left: 3px;
}

.logo h3 {
  padding: 0px;
  font-size: 22px;
  font-weight: 500;
  display: inline-block;
  margin: 0px;
  font-family: 'Verdana-Installed', 'Verdana', 'Lato', 'Arial' !important;
  text-align: center !important;
}

@media only screen and (max-width: 961px) {
  .logo {
    margin: 0px;
    padding: 0px;
  }
  .logo h4 {
    font-size: 11px;
    display: inline-block;
    margin-left: 3px;
  }
}

@media only screen and (max-width: 641px) {
  .logo h4 {
    font-size: 7px;
    display: inline-block;
    margin-left: 3px;
  }
  .logo h3 {
    font-size: 13px;
    display: inline-block;
    margin-top: 0px;
  }
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: none !important;
}

button:focus {
  outline: 0;
}

input:focus {
  outline: 0;
}

button.iOSPWA-cancel {
  width: auto !important;
}

div.upload-file {
  padding-top: 7px !important;
  padding-bottom: 10px !important;
}

.legal-terms, .legal-privacy, .legal-copyright, .contact, .faq, .product-walkthrough, .documentation {
  height: 100%;
  overflow: scroll !important;
  padding-left: 25%;
  padding-right: 25%;
}

.legal-terms h1, .legal-terms h2, .legal-privacy h1, .legal-privacy h2, .legal-copyright h1, .legal-copyright h2, .contact h1, .contact h2, .faq h1, .faq h2, .product-walkthrough h1, .product-walkthrough h2, .documentation h1, .documentation h2 {
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  display: inline-block;
  border-radius: 5px;
  font-size: 31.5px;
  font-weight: bold !important;
  background-color: #cc1166;
  color: white !important;
}

@media only screen and (max-width: 961px) {
  .legal-terms, .legal-privacy, .legal-copyright, .contact, .faq, .product-walkthrough, .documentation {
    height: 95%;
    padding: 6% !important;
    padding-top: 3% !important;
  }
  .legal-terms h2, .legal-terms h3, .legal-privacy h2, .legal-privacy h3, .legal-copyright h2, .legal-copyright h3, .contact h2, .contact h3, .faq h2, .faq h3, .product-walkthrough h2, .product-walkthrough h3, .documentation h2, .documentation h3 {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .legal-terms p, .legal-terms li, .legal-privacy p, .legal-privacy li, .legal-copyright p, .legal-copyright li, .contact p, .contact li, .faq p, .faq li, .product-walkthrough p, .product-walkthrough li, .documentation p, .documentation li {
    font-size: 11px !important;
    margin-top: 2.5% !important;
    margin-bottom: 2.5% !important;
  }
  .legal-terms h1, .legal-terms h2, .legal-privacy h1, .legal-privacy h2, .legal-copyright h1, .legal-copyright h2, .contact h1, .contact h2, .faq h1, .faq h2, .product-walkthrough h1, .product-walkthrough h2, .documentation h1, .documentation h2 {
    font-size: 29.5px !important;
  }
}

@media only screen and (max-width: 641px) {
  .legal-terms h1, .legal-terms h2, .legal-privacy h1, .legal-privacy h2, .legal-copyright h1, .legal-copyright h2, .contact h1, .contact h2, .faq h1, .faq h2, .product-walkthrough h1, .product-walkthrough h2, .documentation h1, .documentation h2 {
    font-size: 27px !important;
  }
}

.legal-terms h3, .legal-privacy h3, .legal-copyright h3, .contact h3, .faq h3, .product-walkthrough h3, .documentation h3 {
  text-align: left;
  text-decoration: underline;
}

.legal-terms h4, .legal-privacy h4, .legal-copyright h4, .contact h4, .faq h4, .product-walkthrough h4, .documentation h4 {
  text-align: left;
  text-decoration: underline;
}

.legal-terms h5, .legal-privacy h5, .legal-copyright h5, .contact h5, .faq h5, .product-walkthrough h5, .documentation h5 {
  text-align: left;
}

.legal-terms p, .legal-terms li, .legal-privacy p, .legal-privacy li, .legal-copyright p, .legal-copyright li, .contact p, .contact li, .faq p, .faq li, .product-walkthrough p, .product-walkthrough li, .documentation p, .documentation li {
  margin-top: .5%;
  margin-bottom: .5%;
  font-size: 15px;
}

.legal-terms li, .legal-privacy li, .legal-copyright li, .contact li, .faq li, .product-walkthrough li, .documentation li {
  text-align: left;
}

.legal-terms a, .legal-privacy a, .legal-copyright a, .contact a, .faq a, .product-walkthrough a, .documentation a {
  cursor: pointer;
  color: #dd2277;
  font-weight: bolder;
}

.legal-terms a:hover, .legal-privacy a:hover, .legal-copyright a:hover, .contact a:hover, .faq a:hover, .product-walkthrough a:hover, .documentation a:hover {
  text-decoration: underline !important;
}

.faq h3, .contact h3, .product-walkthrough h3, .documentation h3 {
  padding-top: 0 !important;
  margin-top: 3vh !important;
  margin-bottom: 2vh !important;
  text-decoration: none !important;
  font-size: 30px !important;
  font-weight: bold !important;
}

.faq p, .contact p, .product-walkthrough p, .documentation p {
  font-weight: #444 !important;
  font-size: 18px !important;
  margin-bottom: 20px !important;
  color: #dadada !important;
}

@media only screen and (max-width: 961px) {
  .faq h3, .contact h3, .product-walkthrough h3, .documentation h3 {
    font-size: 27px !important;
    font-weight: bold !important;
  }
  .faq p, .contact p, .product-walkthrough p, .documentation p {
    font-weight: bold !important;
    font-size: 16px !important;
    margin-bottom: 20px !important;
  }
}

@media only screen and (max-width: 641px) {
  .faq h3, .contact h3, .product-walkthrough h3, .documentation h3 {
    font-size: 25px !important;
    font-weight: bold !important;
  }
  .faq p, .contact p, .product-walkthrough p, .documentation p {
    font-weight: bold !important;
    font-size: 15px !important;
    margin-bottom: 20px !important;
  }
}

.fantastic-header {
  z-index: 1;
  position: fixed !important;
  top: 0vh !important;
  left: 0vh !important;
  right: 0vh !important;
  text-align: left !important;
  margin: 1vh !important;
  margin-top: 0vh !important;
  margin-left: 0vh !important;
  margin-right: 0vh !important;
  padding-left: 1vh !important;
  padding-right: 1vh !important;
  padding-top: .75vh !important;
  padding-bottom: .75vh !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-header {
    z-index: 1000 !important;
    background: #202020 !important;
  }
}

.fantastic-header .options {
  float: right !important;
  background: none !important;
  border-radius: 9px !important;
  width: fit-content !important;
  opacity: 1 !important;
  display: inline-block !important;
}

.fantastic-header .options .option {
  display: inline-block;
  margin-top: 0px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  text-align: center;
  cursor: pointer;
  font-size: 19.05px !important;
  vertical-align: bottom !important;
  margin-right: 7px !important;
}

.fantastic-header .options .option img {
  box-shadow: none !important;
  background: none !important;
  display: inline-block !important;
  margin: 0 auto !important;
  padding: 3.25px !important;
  border-radius: 20%;
  height: 28px !important;
  width: 28px !important;
  vertical-align: middle !important;
}

.fantastic-header .options .option.show-background {
  padding-left: 10px !important;
  padding-right: 10px !important;
  background: #cc1166 !important;
  font-weight: bold !important;
}

.fantastic-header .options .logo.option {
  padding: 0px !important;
  background: none !important;
}

.fantastic-header .options .option:last-of-type {
  margin-right: 0px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-header .options .option {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    font-size: 18px !important;
    border-radius: 8px !important;
  }
  .fantastic-header .options .option img {
    width: 25px !important;
    height: 25px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-header .options .option {
    font-size: 13px !important;
    border-radius: 6px !important;
  }
  .fantastic-header .options .option img {
    width: 22px !important;
    height: 22px !important;
  }
}

.fantastic-header .options.header {
  left: 0 !important;
  right: auto !important;
  position: relative !important;
  float: none !important;
}

.fantastic-header .logo h3 {
  border-radius: 8px !important;
  font-size: 25px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-header .logo h3 {
    border-radius: 6px;
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-header .logo h3 {
    font-size: 15px !important;
  }
}

.fantastic-footer {
  min-width: 260px;
  position: fixed;
  margin: 0 auto;
  bottom: 1vh;
  left: 1vh;
  right: 1vh;
}

@media only screen and (max-width: 961px) {
  .fantastic-footer {
    bottom: 1.5%;
  }
}

.fantastic-footer .brand {
  margin-top: 3px !important;
  display: inline-block;
  float: left !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-footer .brand {
    width: 100%;
    text-align: center;
  }
  .fantastic-footer .brand h3 {
    padding: 0px !important;
  }
}

.fantastic-footer .brand h3 {
  font-size: 14px;
  display: inline-block;
  padding: 0px;
  margin: 0px;
}

@media only screen and (max-width: 641px) {
  .fantastic-footer .brand h3 {
    font-size: 12px;
    display: inline-block;
    padding: 0px;
    margin-top: 0px;
  }
}

.fantastic-footer .options {
  position: relative !important;
  text-align: center !important;
  float: right !important;
  display: block !important;
  font-size: 16px;
}

@media only screen and (max-width: 961px) {
  .fantastic-footer .options {
    width: auto !important;
    float: none !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-footer .options {
    font-size: 12px;
  }
}

.fantastic-footer .options .option {
  display: inline-block !important;
  text-align: center !important;
}

.inputImages {
  white-space: nowrap !important;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 80px;
  width: 100%;
  border-radius: 2px;
  overflow-x: scroll;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.25) !important;
  padding: 1%;
}

.inputImages .inputImage {
  cursor: pointer;
  padding: 5px;
  background: #000;
  height: 75px !important;
  display: inline-block;
  overflow: hidden;
}

.inputImages .inputImage img {
  height: 70px;
}

.inputImages .inputImage.selected {
  border: solid 3px #fff;
}

@media only screen and (max-width: 961px) {
  .inputImages {
    height: 65px;
  }
  .inputImages .inputImage {
    height: 60px;
  }
  .inputImages .inputImage img {
    height: 55px;
  }
}

.inputPost {
  display: inline-block !important;
  border-radius: 5px;
  cursor: pointer;
  width: 100% !important;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat no-repeat !important;
}

.inputPosts {
  scroll-snap-type: x mandatory;
  border-radius: 0px !important;
  margin-bottom: 12px !important;
  white-space: nowrap;
}

@media only screen and (max-width: 641px) {
  .inputPosts {
    margin-bottom: 8px !important;
  }
}

.inputPosts p {
  padding: 2px;
}

.inputLink {
  display: block !important;
  padding: 1px;
  border-bottom: 1px solid rgba(100, 100, 100, 0.6);
}

.inputLinks, .inputPosts {
  width: 100%;
  border-radius: 15px !important;
}

.inputSuggestions, .inputPosts {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.card-type-info .inputSuggestion {
  background: rgba(75, 75, 75, 0.4) !important;
}

.inputSuggestion {
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  border-radius: 9px !important;
  padding: 9% !important;
  padding-top: 7vh !important;
  padding-bottom: 2% !important;
  margin-right: 6px !important;
  font-size: 28px !important;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat no-repeat !important;
  background: rgba(90, 90, 90, 0.4) !important;
}

@media only screen and (max-width: 961px) {
  .inputSuggestion {
    border-radius: 10px !important;
    margin: 3px !important;
    margin-right: 1px !important;
    font-size: 20px !important;
  }
  .inputSuggestion .plus {
    font-size: 25px;
  }
}

@media only screen and (max-width: 481px) {
  .inputSuggestion {
    border-radius: 8px !important;
    font-size: 25px !important;
  }
  .inputSuggestion .plus {
    font-size: 16px;
  }
}

.inputSuggestion .plus {
  font-size: 20px;
}

.inputSuggestion p {
  opacity: .38 !important;
  font-size: 10.5px !important;
  text-align: center !important;
}

.inputSuggestion.isUser {
  width: 90% !important;
}

.inputLink {
  width: 100% !important;
  white-space: normal !important;
  overflow: hidden;
  position: relative;
}

.inputLink .inputImage {
  cursor: pointer;
  padding: 5px;
  background: #000;
  height: 76% !important;
  width: 28% !important;
  margin-right: 1.5%;
  display: inline-block;
  overflow-x: hidden;
  overflow-y: hidden;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat no-repeat !important;
  position: absolute;
  left: 0;
  border-radius: 5px;
}

.inputLink .inputImage img {
  height: 70px;
}

.inputLink .info {
  width: 68% !important;
  margin-left: 32% !important;
  overflow-x: scroll;
}

.inputLink, .inputPost {
  cursor: pointer;
  font-weight: bold;
  margin-right: 3px;
  color: white;
  overflow: hidden;
  font-size: 24px;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat no-repeat !important;
  padding: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 961px) {
  .inputLink, .inputPost {
    font-size: 20px;
  }
}

@media only screen and (max-width: 641px) {
  .inputLink, .inputPost {
    font-size: 20px;
  }
}

@media only screen and (max-width: 481px) {
  .inputLink, .inputPost {
    font-size: 20px;
  }
}

.inputLink h3, .inputPost h3 {
  font-size: 17px;
  margin: 0;
  padding-top: 4px;
  padding-bottom: 3px;
}

.inputLink p.link, .inputPost p.link {
  font-size: 12px !important;
  text-decoration: underline;
  margin-bottom: 10px;
  opacity: .8;
}

.inputLink p.description, .inputPost p.description {
  display: none !important;
}

.inputLink p, .inputPost p {
  font-size: 14px;
}

@media only screen and (max-width: 641px) {
  .inputLink, .inputPost {
    padding: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .inputLink h3, .inputPost h3 {
    font-size: 15px;
    margin: 0;
    padding-top: 4px;
    padding-bottom: 1px;
  }
  .inputLink p.link, .inputPost p.link {
    font-size: 11px;
    text-decoration: underline;
    margin-bottom: 5px;
    opacity: .7;
  }
  .inputLink p, .inputPost p {
    font-size: 11px;
  }
}

p.row.input, .fantastic-card-collection.active p.input.fantastic-card-row {
  color: #dfdfdf !important;
  padding-top: 25px !important;
  padding-bottom: 3px !important;
  font-weight: bold !important;
}

p.row.input.highlight, .fantastic-card-collection.active p.input.highlight.fantastic-card-row {
  color: #fff !important;
}

.linkInfo {
  display: none !important;
  margin-bottom: 3px !important;
  position: relative !important;
  overflow-x: scroll !important;
  white-space: nowrap !important;
}

.linkInfo .linkIconImage {
  left: 0px !important;
  margin-left: 0px !important;
}

.linkInfo p.link {
  margin-left: 23px !important;
  color: #ccc !important;
  text-transform: lowercase !important;
  cursor: pointer !important;
  font-size: 16.5px !important;
  line-break: anywhere !important;
}

@media only screen and (max-width: 961px) {
  .linkInfo p.link {
    margin-left: 19px !important;
    font-size: 15.5px;
  }
}

@media only screen and (max-width: 641px) {
  .linkInfo p.link {
    font-size: 13.5px !important;
  }
}

.price {
  color: #66CC11 !important;
}

.price-pending {
  color: #1166CC !important;
}

.price-completed {
  color: #CCC !important;
}

.linkIcon, .priceIcon {
  height: fit-content !important;
  position: absolute;
  font-weight: bold;
  color: white;
  padding-right: 2px;
  text-shadow: none;
  cursor: pointer;
  left: 0px !important;
  margin-left: 10px !important;
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.linkIcon img, .priceIcon img {
  height: 16.5px !important;
}

@media only screen and (max-width: 961px) {
  .linkIcon img, .priceIcon img {
    height: 12px !important;
    right: 4px;
  }
}

@media only screen and (max-width: 641px) {
  .linkIcon img, .priceIcon img {
    height: 13px !important;
  }
}

@media only screen and (max-width: 481px) {
  .linkIcon img, .priceIcon img {
    height: 13px !important;
  }
}

.linkIconImage {
  position: absolute !important;
  left: 0px !important;
  margin-left: 10px !important;
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
  height: 16.5px !important;
}

@media only screen and (max-width: 961px) {
  .linkIconImage {
    height: 12px !important;
    right: 4px;
  }
}

@media only screen and (max-width: 641px) {
  .linkIconImage {
    height: 13px !important;
  }
}

@media only screen and (max-width: 481px) {
  .linkIconImage {
    height: 13px !important;
  }
}

.tagField {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: scroll;
  text-align: left;
  margin-top: 7px;
  margin-bottom: 7px;
}

@media only screen and (max-width: 961px) {
  .tagField {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.tagField .options {
  border-radius: 3px !important;
  margin: 0 auto !important;
  margin-top: 1.5% !important;
}

.tagField .cardTags {
  vertical-align: top;
}

.tagField .cardTags .cardInput {
  width: 100% !important;
}

.tagField .cardTags .cardInput div.tag {
  background: rgba(0, 0, 0, 0.25) !important;
}

.tagField .inputSuggestions {
  position: relative !important;
  text-align: center !important;
  white-space: nowrap;
  overflow-x: scroll;
  height: fit-content;
  border-radius: 15px !important;
  background: rgba(0, 0, 0, 0.25) !important;
  margin-top: 10px;
}

.tagField .inputSuggestions .inputSuggestion {
  margin-left: 3% !important;
  margin-right: 3% !important;
  margin-top: 2.8% !important;
  margin-bottom: 2.8% !important;
  padding: 4% !important;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
}

.tagField .inputSuggestions .cardPreview {
  display: block !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  height: 100%;
  z-index: -1 !important;
}

.tagField .inputSuggestions .cardPreview .mask-bg, .tagField .inputSuggestions .cardPreview .mask-bg-2, .tagField .inputSuggestions .cardPreview .mask-bg-mobile {
  display: none !important;
}

.tagField .cardInputField {
  width: 100% !important;
  margin: 0 !important;
}

.tagField .cardInputField .inputSuggestions {
  height: auto !important;
  overflow: hidden !important;
}

.tagField .filterBar {
  position: relative !important;
  display: table;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  border-radius: 10px;
  width: 100%;
  background: rgba(0, 0, 0, 0.25) !important;
}

@media only screen and (max-width: 961px) {
  .tagField .filterBar {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
}

@media only screen and (max-width: 481px) {
  .tagField .filterBar {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.tagField .filterBar .cardTags {
  padding: 0 !important;
  margin: 0 !important;
  direction: rtl;
  float: left;
  max-width: 0% !important;
  width: fit-content !important;
  display: block !important;
}

.tagField .filterBar .cardTagsField {
  display: inline-block;
  transition: max-width .05s linear;
  float: left;
  margin: 4px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.tagField .filterBar .cardTagsField .clearButton {
  display: none;
}

.tagField .filterBar input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  box-shadow: none !important;
  background: none !important;
}

.tagField .filterBar .searchIcon {
  position: absolute;
  font-weight: bold;
  color: white;
  padding-right: 2px;
  text-shadow: none;
  cursor: pointer;
  font-size: 23px;
  left: 6px !important;
  margin-left: 4px !important;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.tagField .filterBar .searchIcon img {
  height: 17.5px !important;
}

@media only screen and (max-width: 961px) {
  .tagField .filterBar .searchIcon {
    font-size: 18px;
  }
  .tagField .filterBar .searchIcon img {
    height: 12px !important;
    right: 4px;
  }
}

@media only screen and (max-width: 641px) {
  .tagField .filterBar .searchIcon img {
    height: 13px !important;
  }
}

@media only screen and (max-width: 481px) {
  .tagField .filterBar .searchIcon {
    font-size: 17px;
  }
  .tagField .filterBar .searchIcon img {
    height: 13px !important;
  }
}

.tagField .filterBar .homeButton {
  position: absolute;
  font-weight: bold;
  color: white;
  padding-right: 2px;
  text-shadow: none;
  cursor: pointer;
  font-size: 23px;
  right: 10px;
  margin-left: 4px !important;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.tagField .filterBar .homeButton img {
  height: 16px !important;
}

@media only screen and (max-width: 961px) {
  .tagField .filterBar .homeButton {
    font-size: 18px;
  }
  .tagField .filterBar .homeButton img {
    height: 12px !important;
    right: 4px;
  }
}

@media only screen and (max-width: 641px) {
  .tagField .filterBar .homeButton img {
    height: 13px !important;
  }
}

@media only screen and (max-width: 481px) {
  .tagField .filterBar .homeButton {
    right: 2px;
    font-size: 17px;
  }
  .tagField .filterBar .homeButton img {
    height: 13px !important;
  }
}

.tagField .filterBar .clearButton {
  position: absolute;
  font-weight: bold;
  color: white;
  text-shadow: none;
  cursor: pointer;
  font-size: 23px;
  right: 12px;
  margin-left: 4px !important;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 961px) {
  .tagField .filterBar .clearButton {
    right: 10px;
    font-size: 18px;
  }
  .tagField .filterBar .clearButton img {
    height: 12px;
  }
}

@media only screen and (max-width: 481px) {
  .tagField .filterBar .clearButton {
    right: 10px;
    font-size: 17px;
  }
  .tagField .filterBar .clearButton img {
    height: 12px;
  }
}

.tagField .filterBar.withTags .cardTags {
  max-width: 52% !important;
}

.tagField .filterBar.withTags .cardTagsField {
  max-width: 40% !important;
}

.tagField .filterBar.withTags input {
  text-align: left !important;
  padding-left: 0 !important;
}

.tagField .filterBar.expand .cardTagsField {
  max-width: 100% !important;
}

.tagField .filterBar.expand.withTags .cardTags {
  max-width: 32% !important;
}

.tagField .filterBar.expand.withTags .cardTagsField {
  max-width: 60% !important;
}

.tagField .cardDismiss-top {
  display: none !important;
}

.tagField .cardDismiss-bottom {
  display: none !important;
}

.tagField.center input {
  text-align: left !important;
  padding-left: 40px !important;
}

@media only screen and (max-width: 641px) {
  .tagField.center input {
    padding-left: 13px !important;
  }
}

.tagField.center input:focus {
  text-align: left !important;
  padding-left: 0px !important;
}

.tagField.center input:focus::-webkit-input-placeholder {
  color: transparent !important;
}

.tagField.not-centered input {
  text-align: left !important;
  padding-left: 5px !important;
}

.tagField.not-centered input:focus {
  text-align: left !important;
  padding-left: 5px !important;
}

.showLinkIcon input, .showLinkIcon input:focus, .showPriceIcon input, .showPriceIcon input:focus {
  padding-left: 35px !important;
}

@media only screen and (max-width: 961px) {
  .showLinkIcon input, .showLinkIcon input:focus, .showPriceIcon input, .showPriceIcon input:focus {
    padding-left: 30px !important;
  }
}

.tagField.showSearchIcon input, .tagField.showSearchIcon input:focus {
  padding-left: 45px !important;
}

.tagField.showSearchIcon .filterBar.withTags .cardTags {
  padding-left: 35px !important;
}

.tagField.showSearchIcon .filterBar.withTags input, .tagField.showSearchIcon .filterBar.withTags input:focus {
  padding-left: 0px !important;
}

@media only screen and (max-width: 961px) {
  .tagField.showSearchIcon input, .tagField.showSearchIcon input:focus {
    padding-left: 40px !important;
  }
  .tagField.showSearchIcon .filterBar.withTags .cardTags {
    padding-left: 25px !important;
  }
  .tagField.showSearchIcon .filterBar.withTags input, .tagField.showSearchIcon .filterBar.withTags input:focus {
    padding-left: 0px !important;
  }
}

.tagField.expand .inputSuggestions {
  white-space: break-spaces !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.tagField.expand .inputSuggestions.nano {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.fantastic-tags {
  position: relative;
  min-width: 260px;
  width: fit-content !important;
  margin: 0 auto !important;
  margin-top: 2.5vh !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags {
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    margin-top: 1.5vh !important;
  }
}

.fantastic-tags p:hover {
  cursor: pointer;
}

.fantastic-tags p {
  margin: 2px;
}

.fantastic-tags .cardInput {
  height: 100%;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags .cardInput {
    width: 100% !important;
  }
}

.fantastic-tags .cardInput input, .fantastic-tags .cardInput textarea {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.fantastic-tags .cardInput .inputSuggestions {
  display: none !important;
}

.fantastic-tags .cardInput .cardDismiss-bottom {
  display: none !important;
  display: block;
  position: fixed;
  top: 1%;
  left: 1%;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags .cardInput .cardDismiss-bottom {
    top: auto;
    left: 0;
    right: 0%;
    bottom: 1.5%;
    padding-top: 15% !important;
  }
}

.fantastic-tags .cardInput .table-cell {
  height: 100%;
}

.fantastic-tags .cardInput .table-cell button {
  height: 100% !important;
}

.fantastic-tags .cardInput .table-cell:first-of-type {
  padding-left: 0px;
}

.fantastic-tags .cardInput .table-cell:first-of-type button {
  border-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.fantastic-tags .cardInput .table-cell:last-of-type {
  padding-right: 0px;
}

.fantastic-tags .cardInput .table-cell:last-of-type button {
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags .cardInput .table-cell:first-of-type {
    padding-left: 0px;
  }
  .fantastic-tags .cardInput .table-cell:first-of-type button {
    border-radius: 6px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .fantastic-tags .cardInput .table-cell:last-of-type {
    padding-right: 0px;
  }
  .fantastic-tags .cardInput .table-cell:last-of-type button {
    border-radius: 6px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

.fantastic-tags .fantastic-card .cardList .titles {
  margin-bottom: 2.5vh !important;
}

.fantastic-tags .fantastic-card .cardList .titles .title {
  padding: 8px !important;
}

.fantastic-tags .fantastic-card .cardList .titles .title.selected {
  background: rgba(204, 17, 102, 0.8) !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags .fantastic-card .cardList .titles {
    text-align: center !important;
  }
  .fantastic-tags .fantastic-card .cardList .titles .title {
    padding: 4.5px !important;
  }
}

.fantastic-tags .fantastic-card .cardDropDown {
  background: none !important;
}

.fantastic-tags .fantastic-card .cardDropDown p.row, .fantastic-tags .fantastic-card .cardDropDown .fantastic-card-collection.active p.fantastic-card-row, .fantastic-card-collection.active .fantastic-tags .fantastic-card .cardDropDown p.fantastic-card-row {
  display: none !important;
}

.fantastic-tags .fantastic-card .cardDropDown .selectDiv {
  float: none !important;
  width: 50% !important;
  margin-left: 24% !important;
  border: none !important;
}

.fantastic-tags .fantastic-card .cardDropDown .selectDiv select {
  padding: 11px !important;
  border-radius: 20px !important;
  background: rgba(0, 0, 0, 0.3) !important;
  border: none !important;
  text-align-last: center !important;
  margin-top: 3px !important;
  font-size: 17px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags .fantastic-card .cardDropDown .selectDiv select {
    font-size: 13px !important;
    padding: 7px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-tags .fantastic-card .cardDropDown .selectDiv select {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-tags .fantastic-card .cardDropDown .selectDiv select {
    font-size: 13px !important;
  }
}

.fantastic-tags .fantastic-card .cardDropDown .selectDiv option {
  font-size: 20px !important;
  font-weight: bold !important;
  padding: 10px;
  color: #fff;
  opacity: .7;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags .fantastic-card .cardDropDown .selectDiv option {
    box-shadow: none !important;
    font-size: 14px !important;
    padding: 8px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-tags .fantastic-card .cardDropDown .selectDiv option {
    font-size: 14px !important;
    padding: 7px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-tags .fantastic-card .cardDropDown .selectDiv option {
    font-size: 14px !important;
  }
}

.fantastic-tags .fantastic-card .cardDropDown .selectDiv option:focus, .fantastic-tags .fantastic-card .cardDropDown .selectDiv option:active {
  outline: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.fantastic-tags .fantastic-card .cardDropDown .downArrow {
  right: 12px !important;
  top: 5px !important;
  font-size: 25px !important;
  opacity: .87 !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags .options {
    float: none !important;
  }
}

.fantastic-tags .tagField {
  margin: 0 !important;
}

.fantastic-tags .tagField .options {
  display: none !important;
}

.fantastic-tags .tagField .cardTags div.tag.pressed {
  font-weight: bold !important;
  border: none !important;
}

.fantastic-tags .filterBar {
  background: rgba(150, 150, 150, 0.2) !important;
  border-radius: 12px;
}

.fantastic-tags button {
  border-radius: 3px;
  text-shadow: 0px 0px 3px #000;
  margin: 0px !important;
  font-size: 23px;
  padding: 0px !important;
}

.fantastic-tags button img {
  height: 12px;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags button {
    font-size: 20px;
  }
  .fantastic-tags button img {
    height: 10px;
    max-width: 97%;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-tags button {
    box-shadow: none !important;
    font-size: 18px;
  }
  .fantastic-tags button img {
    height: 12px;
    max-width: 97%;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-tags button {
    font-size: 15px;
  }
  .fantastic-tags button img {
    height: 12px;
    max-width: 97%;
  }
}

.fantastic-tags button .notification {
  margin-right: 5px;
  background-color: #cc1166;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags button .notification {
    height: 12px;
    width: 12px;
  }
}

.fantastic-tags button .notification.hidden {
  visibility: hidden;
}

.fantastic-tags .fantastic-card-collection {
  padding: 0px !important;
  margin: 0px !important;
}

.fantastic-tags .fantastic-card-row {
  padding: 0px !important;
  margin: 0px !important;
}

.fantastic-tags .fantastic-card-row .fantastic-card {
  max-width: 625px;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags .fantastic-card-row .fantastic-card {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-tags .fantastic-card-row .fantastic-card {
    width: 100%;
    max-width: 100%;
  }
}

.fantastic-tags .fantastic-card-row .fantastic-card .cardFooter {
  padding: 0px !important;
  margin: 0px !important;
}

.fantastic-tags .fantastic-card-row .fantastic-card .cardContent {
  display: none !important;
}

.fantastic-tags .fantastic-card-row .fantastic-card .cardMessage {
  display: none !important;
}

.fantastic-tags .options {
  width: 82% !important;
  opacity: .985;
  border-radius: 5px;
}

.fantastic-tags .options .option.feature {
  opacity: 1;
  background: rgba(0, 0, 0, 0.3) !important;
}

.fantastic-tags .options .option {
  color: #fff !important;
  padding: 5px;
  border-radius: 8px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: rgba(0, 0, 0, 0.3) !important;
}

.fantastic-tags .options .option:first-of-type {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.fantastic-tags .options .option:last-of-type {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags .options .option {
    border-radius: 6px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.suggestions-visible .inputSuggestions {
  display: block !important;
  background: none !important;
  border-radius: 0px !important;
  text-align: left !important;
  overflow-y: scroll !important;
}

.suggestions-visible .inputSuggestions .inputSuggestion {
  margin-right: 1% !important;
  margin-left: 0 !important;
  margin-top: 0% !important;
  margin-bottom: 2% !important;
  padding: 1% !important;
  font-size: 15px !important;
}

.suggestions-visible .inputSuggestions .inputSuggestion.full-width {
  background: rgba(0, 0, 0, 0.35) !important;
  font-size: 25px !important;
}

@media only screen and (max-width: 961px) {
  .suggestions-visible .inputSuggestions .inputSuggestion.full-width {
    font-size: 22px !important;
  }
}

@media only screen and (max-width: 481px) {
  .suggestions-visible .inputSuggestions .inputSuggestion.full-width {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 961px) {
  .suggestions-visible .inputSuggestions .inputSuggestion {
    margin-top: 0% !important;
    margin-bottom: 3% !important;
    font-size: 13px !important;
  }
}

.fantastic-tags.suggestions-visible {
  display: block !important;
  overflow-y: hidden !important;
}

.fantastic-tags.suggestions-visible .mask-bg-2 {
  display: block !important;
  display: none !important;
  height: 100%;
  background: linear-gradient(-0.1turn, #cc1166, #282828, #282828) !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .994 !important;
}

.fantastic-tags.suggestions-visible .cardDismiss-bottom {
  display: none !important;
}

.fantastic-tags.suggestions-visible .inputSuggestions {
  display: block !important;
}

.fantastic-tags.options-visible .inputSuggestions {
  margin-top: 2.5% !important;
  height: fit-content !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-tags.options-visible .inputSuggestions {
    margin-top: 3.5% !important;
  }
}

.options {
  margin: 0 auto !important;
  margin-top: 3.3px !important;
  display: table;
  table-layout: fixed;
}

.options .option {
  display: inline-block;
  margin-top: 0px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  text-align: center;
  cursor: pointer;
  font-size: 18px !important;
  padding: 7px !important;
}

.options .option img {
  display: inline-block !important;
  margin: 0 auto !important;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 20%;
  height: 28px;
  width: 28px;
}

.options .option .notifications {
  padding: 3px !important;
  background: #cc1166 !important;
  border-radius: 4px !important;
  font-size: 10px !important;
  margin-left: -10px !important;
}

@media only screen and (max-width: 961px) {
  .options .option {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    font-size: 14px !important;
    padding: 5px !important;
  }
  .options .option img {
    background: none !important;
    box-shadow: none !important;
    width: 25px !important;
    height: 25px !important;
    padding: 0px !important;
  }
}

@media only screen and (max-width: 641px) {
  .options .option {
    font-size: 12px !important;
    padding: 5px !important;
  }
  .options .option img {
    background: none !important;
    width: 22px !important;
    height: 22px !important;
    padding: 0px !important;
  }
}

.options .option-account {
  border-radius: 50%;
}

.options .option.feature {
  opacity: 1;
}

.option:hover {
  opacity: 1 !important;
  color: #fff !important;
  pointer: cursor !important;
}

.mask-bg {
  display: none !important;
}

.cardPreview .fantastic-card-collection {
  margin: 0% !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
}

.cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo {
  border-radius: 0px !important;
}

.cardPreview .fantastic-card-collection.active {
  scroll-snap-type: y mandatory !important;
  margin: 0% !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
  padding-bottom: 0px !important;
}

.cardPreview .fantastic-card-collection.active .fantastic-card-row.card-type-promo {
  border-radius: 0px !important;
}

.cardPreview .fantastic-card-collection.active .fantastic-card-row.card-type-promo .fantastic-card .cardHeader {
  height: 65% !important;
}

.cardPreview .fantastic-card-collection.active .fantastic-card-row.card-type-promo .fantastic-card .cardBody {
  height: 35% !important;
}

.cardPreview .fantastic-card-collection.active .fantastic-card-row.card-type-promo .fantastic-card .cardContent {
  top: 0 !important;
}

.cardPreview .fantastic-card-collection.active .fantastic-card-row .cardShare, .cardPreview .fantastic-card-collection.active .fantastic-card-row .cardActions {
  display: none !important;
}

@media only screen and (max-width: 961px) {
  .cardPreview .fantastic-card-collection.active .fantastic-card-row .mask-bg, .cardPreview .fantastic-card-collection.active .fantastic-card-row .mask-bg-2, .cardPreview .fantastic-card-collection.active .fantastic-card-row .mask-bg-mobile {
    background-position: center center !important;
    background-size: auto 100% !important;
  }
}

.cardPreview .fantastic-card-collection.active .fantastic-card-row .mask-bg, .cardPreview .fantastic-card-collection.active .fantastic-card-row .mask-bg-mobile {
  display: none !important;
}

.cardPreview .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage p {
  display: -webkit-inline-box !important;
  -webkit-line-clamp: 10 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.cardPreview .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .name {
  font-weight: bold;
  padding: 5px;
  padding-bottom: 0px !important;
  font-size: 17px !important;
  border-radius: 5px !important;
  display: table;
}

@media only screen and (max-width: 961px) {
  .cardPreview .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .name {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 641px) {
  .cardPreview .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .name {
    font-size: 13px !important;
  }
}

.cardListElements {
  padding: 2% !important;
}

.cardListElements .activity, .cardListElements .notifications, .cardListElements .following, .cardListElements .saved {
  text-align: left !important;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 5px;
  height: auto !important;
  border-bottom: 1px solid #999;
}

.cardListElements .activity .toView, .cardListElements .notifications .toView, .cardListElements .following .toView, .cardListElements .saved .toView {
  display: inline-block !important;
  vertical-align: top !important;
  margin-right: 5px;
  background-color: #cc1166;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.cardListElements .activity .toView.hidden, .cardListElements .notifications .toView.hidden, .cardListElements .following .toView.hidden, .cardListElements .saved .toView.hidden {
  visibility: hidden;
}

.cardListElements .activity .action-image, .cardListElements .notifications .action-image, .cardListElements .following .action-image, .cardListElements .saved .action-image {
  height: 50px;
  width: 50px;
  display: inline-block !important;
  vertical-align: top !important;
  overflow: hidden;
  margin-right: 10px;
}

.cardListElements .activity .action-image img, .cardListElements .notifications .action-image img, .cardListElements .following .action-image img, .cardListElements .saved .action-image img {
  width: 100%;
  border-radius: 10%;
}

.cardListElements .activity .action-info, .cardListElements .notifications .action-info, .cardListElements .following .action-info, .cardListElements .saved .action-info {
  height: auto !important;
  overflow-y: scroll;
  width: 70%;
  display: inline-block !important;
  vertical-align: top !important;
  overflow: hidden;
}

.cardListElements .activity .action-info p, .cardListElements .notifications .action-info p, .cardListElements .following .action-info p, .cardListElements .saved .action-info p {
  padding-bottom: 3px;
}

.cardListElements .activity .action-info .action-text.username, .cardListElements .notifications .action-info .action-text.username, .cardListElements .following .action-info .action-text.username, .cardListElements .saved .action-info .action-text.username {
  cursor: pointer;
}

.cardListElements .activity .action-info .action-text.subject, .cardListElements .notifications .action-info .action-text.subject, .cardListElements .following .action-info .action-text.subject, .cardListElements .saved .action-info .action-text.subject {
  cursor: pointer;
  font-weight: bold;
  opacity: 1;
}

.cardListElements .activity .action-info .action-text.value, .cardListElements .notifications .action-info .action-text.value, .cardListElements .following .action-info .action-text.value, .cardListElements .saved .action-info .action-text.value {
  cursor: pointer;
}

.fantastic-card {
  border-radius: 2px;
  max-width: 625px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card {
    max-width: 100%;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card {
    max-width: 100%;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card {
    max-width: 100%;
  }
}

.fantastic-card .cardScore {
  display: none !important;
  padding: 0px !important;
  margin: 0px !important;
}

.fantastic-card .cardPrompt {
  width: fit-content !important;
  display: inline-block;
  z-index: 1001 !important;
  vertical-align: top;
  float: right;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.fantastic-card .cardPrompt button {
  background: rgba(50, 50, 50, 0.25) !important;
  opacity: 1 !important;
  width: auto !important;
  height: auto !important;
  padding: 7px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  font-size: 22px !important;
  border-radius: 10px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardPrompt button {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardPrompt button {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardPrompt button {
    font-size: 18px !important;
  }
}

.fantastic-card .cardPrompt button h1, .fantastic-card .cardPrompt button h2 {
  padding: 0px !important;
  font-size: 22px !important;
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardPrompt button h1, .fantastic-card .cardPrompt button h2 {
    font-size: 18px !important;
  }
}

.fantastic-card .cardPrompt.showPreview {
  z-index: 1500 !important;
  position: relative !important;
  width: 100%;
}

.fantastic-card .cardPrompt.showPreview button {
  box-shadow: none !important;
  float: right;
  text-align: right;
  border: none;
  box-shadow: none;
  padding: 0px;
  padding-top: 20px;
  margin: 0px;
  margin-bottom: 3px;
  font-size: 27px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardPrompt.showPreview button {
    font-size: 24px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardPrompt.showPreview button {
    font-size: 21px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardPrompt.showPreview button {
    font-size: 21px;
  }
}

.fantastic-card .cardPrompt.displayProgress {
  display: none !important;
}

.fantastic-card .cardPrompt.displayProgress button {
  padding: 5px !important;
  background-color: #222 !important;
  border-radius: 50% !important;
  height: 40px !important;
  width: 40px !important;
  font-size: 24px !important;
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardPrompt.displayProgress button {
    height: 30px !important;
    width: 30px !important;
  }
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardPrompt.displayProgress button {
    font-size: 22px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardPrompt.displayProgress button {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardPrompt.displayProgress button {
    font-size: 18px !important;
  }
}

.fantastic-card .cardLabel {
  white-space: break-spaces !important;
  border-radius: 10px;
  background: rgba(100, 100, 100, 0.25) !important;
  padding: 20px;
}

.fantastic-card .cardLabel p {
  display: inline-block;
}

.fantastic-card .cardLabel h3 {
  width: 100%;
  display: inline-block;
  font-size: 20.5px;
  margin: 0px !important;
  font-weight: bold !important;
  padding: 0px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardLabel h3 {
    font-size: 17.5px;
    margin: 0px !important;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardLabel h3 {
    font-size: 14px;
    margin: 0px !important;
  }
}

.fantastic-card .cardLabel .count {
  display: inline-block;
  margin: 0 auto;
  background: #cc1166;
  padding: 5px;
  border-radius: 3.5px;
  float: right;
}

.fantastic-card .cardLabel.large {
  height: auto !important;
}

.fantastic-card .cardLabel.large h3 {
  display: inline-block;
  font-size: 25px;
  margin: 2px;
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardLabel.large h3 {
    font-size: 21.5px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardLabel.large h3 {
    font-size: 18px;
  }
}

.fantastic-card .cardLabel.transparent {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 4px !important;
}

.fantastic-card .cardLabel.transparent h3 {
  padding: 0 !important;
  font-weight: bold !important;
}

.fantastic-card .cardList {
  height: auto !important;
  overflow: hidden;
  margin-bottom: 5px !important;
  white-space: nowrap !important;
  overflow-x: scroll;
}

.fantastic-card .cardList .titles {
  text-align: left !important;
  margin-top: 1vh !important;
  margin-bottom: 2vh !important;
  border-radius: 0px !important;
  table-layout: fixed !important;
  display: table !important;
  width: 100% !important;
  border-radius: 0px !important;
}

.fantastic-card .cardList .titles .title:first-of-type {
  border-radius: 15px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.fantastic-card .cardList .titles .title:last-of-type {
  border-radius: 15px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.fantastic-card .cardList .titles .title:only-of-type {
  border-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardList .titles {
    text-align: center !important;
    width: 100% !important;
    display: table !important;
  }
}

.fantastic-card .cardList .titles .title {
  background: rgba(10, 10, 10, 0.25) !important;
  opacity: .5;
  cursor: pointer;
  padding: .95%;
  padding-top: 2.5%;
  padding-bottom: 1.5%;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  color: #ccc;
  width: 100% !important;
  display: table-cell !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardList .titles .title {
    padding-top: 1.5% !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardList .titles .title {
    margin-left: 1% !important;
    margin-right: 1% !important;
    padding: 0 !important;
  }
}

.fantastic-card .cardList .titles .title p {
  text-align: center;
  display: inline-block;
  font-size: 22px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardList .titles .title p {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardList .titles .title p {
    font-size: 18px !important;
  }
}

.fantastic-card .cardList .titles .title .count {
  display: inline-block;
  background: #cc1166;
  padding: 3.5px;
  margin: 3px;
  border-radius: 7px;
}

.fantastic-card .cardList .titles .title {
  padding: 10px !important;
}

.fantastic-card .cardList .titles .title.selected {
  opacity: 1;
  color: #fff !important;
  background: rgba(10, 10, 10, 0.225) !important;
}

.fantastic-card .cardList .titles .title.selected p {
  font-weight: bold !important;
}

.fantastic-card .cardList .tagField {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.fantastic-card .cardList .tagField .filterBar {
  background: #191919 !important;
}

.fantastic-card .cardList .cardListElements {
  overflow-y: scroll !important;
  background: rgba(0, 0, 0, 0.25) !important;
  border-radius: 15px !important;
}

.fantastic-card .cardSubject {
  text-align: left;
  width: 100%;
}

.fantastic-card .cardSubject p {
  text-shadow: #333 2px 2px 3px;
  font-size: 14.5px;
  padding-top: 0px !important;
  color: #fff;
  display: inline-block;
}

.fantastic-card .cardSubject p a {
  text-decoration: underline;
  color: #fff !important;
  cursor: pointer;
}

.fantastic-card .cardSubject p a.bare {
  text-decoration: none !important;
  color: #fff !important;
  cursor: pointer;
}

.fantastic-card .cardSubject a.isCreator {
  color: #fff;
  font-weight: bold;
}

.fantastic-card .cardSubject a {
  text-decoration: none;
  color: #fff !important;
}

.fantastic-card .cardSubject h1, .fantastic-card .cardSubject h2 {
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden;
  max-width: 65% !important;
  border-radius: 6px;
  display: inline-block;
  margin: 0 !important;
  font-size: 33px;
  font-weight: bold !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
  color: white !important;
}

@supports (-webkit-line-clamp: 2) {
  .fantastic-card .cardSubject h1, .fantastic-card .cardSubject h2 {
    white-space: initial !important;
    display: -webkit-inline-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardSubject h1, .fantastic-card .cardSubject h2 {
    padding-right: 3px !important;
    font-size: 24px;
  }
  .fantastic-card .cardSubject p {
    font-size: 11.5px;
  }
}

.fantastic-card .cardAccountInfo {
  width: 100%;
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

.fantastic-card .cardAccountInfo .cardTags {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}

.fantastic-card .cardAccountInfo .userStats {
  display: table;
  display: none !important;
  table-layout: fixed;
  background: rgba(0, 0, 0, 0.34) !important;
  border-radius: 5px;
  margin-bottom: 10px;
}

.fantastic-card .cardAccountInfo .userStats .profilePicture {
  display: none;
}

.fantastic-card .cardAccountInfo .userStats .profilePicture img {
  background: #fff;
  width: 100%;
  float: left;
  border-radius: 10%;
  height: 85px;
  width: 85px;
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardAccountInfo .userStats .profilePicture img {
    height: 75px;
    width: 75px;
  }
}

.fantastic-card .cardAccountInfo .userStats .userCounts {
  vertical-align: top;
  display: table-cell;
  overflow: hidden;
  margin-left: 1%;
  width: 75%;
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardAccountInfo .userStats .userCounts {
    width: 100%;
  }
}

.fantastic-card .cardAccountInfo .userStats .userCounts .row, .fantastic-card .cardAccountInfo .userStats .userCounts .fantastic-card-collection.active .fantastic-card-row, .fantastic-card-collection.active .fantastic-card .cardAccountInfo .userStats .userCounts .fantastic-card-row {
  width: 100%;
  display: table;
  table-layout: fixed;
  padding: 5px;
}

.fantastic-card .cardAccountInfo .userStats .userCounts .row::after, .fantastic-card .cardAccountInfo .userStats .userCounts .fantastic-card-collection.active .fantastic-card-row::after, .fantastic-card-collection.active .fantastic-card .cardAccountInfo .userStats .userCounts .fantastic-card-row::after {
  display: none !important;
}

.fantastic-card .cardAccountInfo .userStats .userCounts .count {
  padding: 1%;
  display: table-cell;
}

.fantastic-card .cardAccountInfo .userStats .userCounts .count p {
  font-size: 21px;
  height: 21px;
  text-align: center;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardAccountInfo .userStats .userCounts .count p {
    font-size: 16px;
    height: 16px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardAccountInfo .userStats .userCounts .count p {
    font-size: 14px;
    height: 14px;
  }
}

.fantastic-card .cardAccountInfo .userStats .userCounts .count p.label {
  color: #ccc;
}

.fantastic-card .cardAccountInfo .userBio {
  margin-bottom: 1.5%;
  padding: 1%;
  padding-left: 0px !important;
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px;
}

.fantastic-card .cardAccountInfo .userBio .name {
  font-weight: bold;
  padding: 5px;
  padding-bottom: 0px !important;
  font-size: 17px !important;
  background: rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  display: table;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardAccountInfo .userBio .name {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardAccountInfo .userBio .name {
    font-size: 13px !important;
  }
}

.fantastic-card .cardAccountInfo .userBio .message {
  font-weight: 100 !important;
  font-size: 15.5px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardAccountInfo .userBio .message {
    font-size: 14.5px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardAccountInfo .userBio .message {
    font-size: 11px !important;
  }
}

.fantastic-card .cardAccountInfo .userBio .bio {
  font-weight: 100 !important;
  font-size: 16.5px !important;
  background: rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardAccountInfo .userBio .bio {
    font-size: 15.5px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardAccountInfo .userBio .bio {
    font-size: 12px !important;
  }
}

.fantastic-card .cardInput {
  display: table;
  table-layout: fixed;
  border-collapse: separate;
  position: relative !important;
}

.fantastic-card .cardInput .no-padding.table-cell {
  padding: 0px !important;
}

.fantastic-card .cardInput .table-cell {
  display: table-cell;
  padding-left: 1% !important;
  padding-right: 1% !important;
  padding-bottom: .5% !important;
  vertical-align: top;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .desktop-table-cell {
    padding: 0% !important;
    width: 100% !important;
    display: block !important;
  }
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .desktop-table-cell.expanded.min {
    padding-right: 1% !important;
    width: 87% !important;
    display: inline-block !important;
  }
  .fantastic-card .cardInput .desktop-table-cell.expanded.min button {
    padding-left: 15% !important;
  }
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .desktop-table-cell.minimized {
    width: 13% !important;
    display: inline-block !important;
  }
}

.fantastic-card .cardInput .table-cell:first-of-type {
  padding-left: 0 !important;
}

.fantastic-card .cardInput .table-cell:last-of-type {
  padding-right: 0 !important;
}

.fantastic-card .cardInput button {
  margin-top: 1px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput button {
    margin-top: 1px;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardInput button {
    margin-top: 1px;
    margin-bottom: 3px;
  }
}

.fantastic-card .cardInput .cardInputField {
  margin-top: 5px;
  margin-bottom: 9px;
  position: relative !important;
}

.fantastic-card .cardInput .cardInputField p.row, .fantastic-card .cardInput .cardInputField .fantastic-card-collection.active p.fantastic-card-row, .fantastic-card-collection.active .fantastic-card .cardInput .cardInputField p.fantastic-card-row {
  padding-bottom: 5px !important;
  font-size: 16px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .cardInputField p.row, .fantastic-card .cardInput .cardInputField .fantastic-card-collection.active p.fantastic-card-row, .fantastic-card-collection.active .fantastic-card .cardInput .cardInputField p.fantastic-card-row {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardInput .cardInputField p.row, .fantastic-card .cardInput .cardInputField .fantastic-card-collection.active p.fantastic-card-row, .fantastic-card-collection.active .fantastic-card .cardInput .cardInputField p.fantastic-card-row {
    font-size: 12px !important;
  }
}

.fantastic-card .cardInput .cardInputField input {
  margin: 0;
  vertical-align: baseline;
  padding-right: 31px;
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardInput .cardInputField input {
    padding-right: 26.5px !important;
  }
}

.fantastic-card .cardInput .cardInputField .profileButton {
  color: #BBB;
  cursor: pointer;
  margin-left: -69px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.fantastic-card .cardInput .cardInputField .profileButton img {
  height: 15px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .cardInputField .profileButton {
    margin-left: -57px;
  }
  .fantastic-card .cardInput .cardInputField .profileButton img {
    height: 14px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardInput .cardInputField .profileButton {
    margin-left: -46px;
  }
  .fantastic-card .cardInput .cardInputField .profileButton img {
    height: 13px;
  }
}

.fantastic-card .cardInput .cardInputField .clearButton {
  position: absolute;
  font-weight: bold;
  color: white;
  padding: 5px;
  text-shadow: none;
  cursor: pointer;
  right: 6px;
  font-size: 20px;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .cardInputField .clearButton {
    font-size: 16px;
  }
}

.fantastic-card .cardInput .cardInputField .clearButton.secondary {
  margin-left: 8px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .cardInputField .clearButton.secondary {
    margin-left: 4px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardInput .cardInputField .clearButton.secondary {
    margin-left: 1px;
  }
}

.fantastic-card .cardInput .cardInputField .cardDropDown {
  background: rgba(150, 150, 150, 0.25) !important;
  padding: 10px;
  padding-right: 7px;
  margin-top: 5px;
  margin-bottom: 8px;
  overflow: auto;
  border-radius: 10px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .cardInputField .cardDropDown {
    border-radius: 7px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardInput .cardInputField .cardDropDown {
    padding: 5px !important;
    border-radius: 7px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardInput .cardInputField .cardDropDown {
    border-radius: 7px;
  }
}

.fantastic-card .cardInput .cardInputField .cardDropDown p {
  font-size: 17px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .cardInputField .cardDropDown p {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardInput .cardInputField .cardDropDown p {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardInput .cardInputField .cardDropDown p {
    font-size: 15px !important;
  }
}

.fantastic-card .cardInput .cardInputField .cardDropDown p.row, .fantastic-card .cardInput .cardInputField .cardDropDown .fantastic-card-collection.active p.fantastic-card-row, .fantastic-card-collection.active .fantastic-card .cardInput .cardInputField .cardDropDown p.fantastic-card-row {
  float: left;
  padding: 10px;
  display: inline-block;
  margin-right: 5px;
  font-weight: bold !important;
}

.fantastic-card .cardInput .cardInputField .cardDropDown .selectDiv {
  float: right;
  width: 25%;
  position: relative !important;
}

.fantastic-card .cardInput .cardInputField .cardDropDown .selectDiv .table-cell {
  float: right !important;
}

.fantastic-card .cardInput .cardInputField .cardDropDown select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 25%;
  text-align: center;
  border-radius: 7px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background: transparent !important;
  border: 2px solid #777;
  color: white;
  width: 100%;
  font-weight: bold !important;
}

.fantastic-card .cardInput .cardInputField .cardDropDown .downArrow {
  position: absolute;
  right: 5px;
  top: 5px !important;
  color: white;
  pointer-events: none;
}

.fantastic-card .cardInput .cardInputField.condensed {
  float: left;
  width: 68%;
}

.fantastic-card .cardInput .rounded button {
  border-radius: 20px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .rounded button {
    border-radius: 18px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardInput .rounded button {
    border-radius: 15px;
  }
}

.fantastic-card .cardInput .condensed button {
  width: 15%;
}

.fantastic-card .cardInput .thin.condensed button {
  width: 6%;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardInput .cardInputField.condensed {
    width: 76.5%;
  }
  .fantastic-card .cardInput .condensed button {
    width: 15%;
  }
  .fantastic-card .cardInput .thin.condensed button {
    width: 7.5%;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardInput .condensed button {
    width: 25%;
  }
  .fantastic-card .cardInput .thin.condensed button {
    width: 12%;
  }
}

.fantastic-card .cardMessage {
  margin-top: 0px;
  margin-bottom: 18px;
  border-radius: 10px;
  text-shadow: none;
  width: 100%;
  padding-bottom: 5px;
  text-align: left;
  overflow-x: hidden;
  overflow: hidden !important;
}

.fantastic-card .cardMessage p {
  font-size: 14px;
  opacity: .96;
  border-radius: 5px !important;
  white-space: break-spaces !important;
  color: #ccc !important;
  display: -webkit-inline-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardMessage {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .fantastic-card .cardMessage p {
    font-size: 13px;
  }
}

.fantastic-card .cardMessage .table-cell {
  float: left !important;
  margin: 3px !important;
  opacity: .75 !important;
}

.fantastic-card .cardMessage .table-cell button {
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px !important;
  white-space: break-spaces !important;
  background: rgba(50, 50, 50, 0.25) !important;
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardMessage .table-cell button {
    font-size: 13px;
  }
}

.fantastic-card .cardToggle {
  background: rgba(100, 100, 100, 0.25) !important;
  overflow: auto !important;
  padding: 5px;
  border-radius: 15px;
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardToggle {
    border-radius: 14px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card .cardToggle {
    border-radius: 12px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card .cardToggle {
    border-radius: 10px;
  }
}

.fantastic-card .cardToggle p.row, .fantastic-card .cardToggle .fantastic-card-collection.active p.fantastic-card-row, .fantastic-card-collection.active .fantastic-card .cardToggle p.fantastic-card-row {
  float: left;
  padding: 5px;
  display: inline-block;
  margin-right: 5px;
}

.fantastic-card .cardToggle .toggleDiv {
  float: right;
}

.fantastic-card .cardToggle .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.fantastic-card .cardToggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.fantastic-card .cardToggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.fantastic-card .cardToggle .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.fantastic-card .cardToggle input:checked + .slider {
  background-color: #cc1166;
}

.fantastic-card .cardToggle input:focus + .slider {
  box-shadow: 0 0 1px #cc1166;
}

.fantastic-card .cardToggle input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.fantastic-card .cardToggle .slider.round {
  border-radius: 34px;
}

.fantastic-card .cardToggle .slider.round:before {
  border-radius: 50%;
}

.fantastic-card .cardDatePicker {
  background: rgba(150, 150, 150, 0.25) !important;
  color: white !important;
  width: 100% !important;
  margin-top: 14px !important;
  margin-bottom: 8px !important;
  border-radius: 15px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card .cardDatePicker {
    border-radius: 12px;
  }
}

.fantastic-card .cardInputField.minimized {
  float: left !important;
  width: 32% !important;
  margin-right: 1% !important;
  margin-top: 0px !important;
}

.fantastic-card .cardInputField.minimized .cardDropDown {
  background: rgba(50, 50, 50, 0.8) !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.fantastic-card .cardInputField.minimized .cardDropDown p.row, .fantastic-card .cardInputField.minimized .cardDropDown .fantastic-card-collection.active p.fantastic-card-row, .fantastic-card-collection.active .fantastic-card .cardInputField.minimized .cardDropDown p.fantastic-card-row {
  padding-left: 0px !important;
  padding-top: 7px !important;
  padding-bottom: 1px !important;
}

.fantastic-card .cardInputField.minimized .cardDropDown .selectDiv {
  width: 100% !important;
}

.fantastic-card .cardInputField.minimized .cardDropDown .selectDiv select {
  text-align: left !important;
  border: none !important;
}

.MuiPickersPopper-root {
  z-index: 2300 !important;
}

@media only screen and (max-width: 961px) {
  .MuiPickersPopper-root {
    transform: translate3d(7.5%, 30%, 0px) !important;
  }
}

.MuiPickersCalendarHeader-label {
  line-height: 1 !important;
}

.MuiButtonBase-root {
  width: fit-content !important;
}

.MuiFormLabel-root {
  color: #fff !important;
}

.MuiInputAdornment-root .MuiSvgIcon-root {
  color: #fff !important;
}

.slow-hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.cardDownArrow {
  display: none !important;
  animation: bounce 1.5s linear infinite;
  z-index: 1400 !important;
  opacity: .7;
  position: fixed;
  bottom: 2vh;
  right: 2.5%;
  top: 5px !important;
  left: auto !important;
  padding: 0px !important;
  padding-top: 12px !important;
  padding-bottom: 5px !important;
  font-size: 20px !important;
  height: 55px !important;
  width: 55px !important;
  border-radius: 50% !important;
  font-weight: 100 !important;
  color: white;
  background: rgba(0, 0, 0, 0.25) !important;
}

@media only screen and (max-width: 961px) {
  .cardDownArrow {
    display: none !important;
  }
}

.cardDownArrow .prompt {
  font-size: 12px !important;
}

.cardDownArrow .arrow {
  margin-top: -10px;
}

.cardFooter {
  position: relative;
  margin-top: 5px;
  padding-bottom: 4px;
}

.cardFooter.chunk .cardInput {
  padding-top: 1%;
  padding-bottom: 1%;
}

.cardDismiss-top {
  cursor: pointer !important;
  z-index: 5000 !important;
  position: absolute;
  top: 1%;
  left: 1%;
  display: inline-block !important;
  border-radius: 10%;
  color: #fff;
  font-size: 35px;
}

@media only screen and (max-width: 961px) {
  .cardDismiss-top {
    display: none !important;
  }
}

.cardDismiss-bottom {
  cursor: pointer !important;
  z-index: 5000 !important;
  display: none !important;
  font-size: 35px;
  text-align: center !important;
  border-radius: 0px !important;
}

@media only screen and (max-width: 961px) {
  .cardDismiss-bottom {
    position: absolute;
    padding-bottom: 2.5% !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 2002;
    margin: 0 auto;
    display: block !important;
    padding: 0px;
    border-radius: 10%;
    color: #fff;
    margin-top: 0px;
    font-size: 25px;
    width: 42px !important;
    height: 42px !important;
    background: rgba(25, 25, 25, 0.85) !important;
    padding: 5px !important;
    border-radius: 15px !important;
    bottom: 1.2% !important;
  }
}

.cardDismiss-top:hover, .cardDismiss-bottom:hover {
  cursor: pointer !important;
}

.cardHeader {
  width: 100%;
  text-align: left;
}

.cardContent {
  padding: 3.25%;
  position: absolute !important;
  top: 3vh !important;
  bottom: auto !important;
  left: 0% !important;
  right: 0% !important;
  margin: 0 auto !important;
  z-index: 1 !important;
}

.cardInput {
  text-align: left;
  overflow: auto;
  width: 100%;
}

.cardInput .fill button {
  width: 100%;
}

.cardInput .fill input {
  width: 100%;
}

.cardInput ::-webkit-input-placeholder {
  /* Chrome */
  color: #ccc;
  opacity: .80;
}

.cardInput input.invalid {
  border: 1px solid #cc1166;
}

.cardInput input.valid {
  background: #66bb11;
}

.cardInput div.tag {
  width: auto;
  margin-right: 3px;
  padding: 3px;
  font-size: 10px;
}

@media only screen and (max-width: 641px) {
  .cardInput div.tag {
    margin-right: 3px;
    font-size: 8px;
  }
}

.tag {
  cursor: pointer;
}

.cardTags {
  overflow-x: scroll !important;
  margin-top: 3px !important;
  margin-bottom: 2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

@media only screen and (max-width: 961px) {
  .cardTags {
    font-size: 8px;
  }
}

@media only screen and (max-width: 641px) {
  .cardTags {
    font-size: 7px;
  }
}

.cardTags .cardInput {
  white-space: nowrap;
  padding-top: 0px;
  padding-bottom: 0px;
  display: block !important;
}

.cardTags div.tag {
  margin: 1px;
  margin-top: 3px;
  direction: ltr;
  padding: 8px;
  padding-top: 5px;
  padding-bottom: 3px;
  border-radius: 9px;
  text-transform: uppercase;
  font-size: 13px;
  margin-right: 7px;
  font-weight: bold;
  display: inline-block;
  position: relative;
  background: rgba(0, 0, 0, 0.75) !important;
}

@media only screen and (max-width: 961px) {
  .cardTags div.tag {
    padding: 6px;
    padding-top: 4px;
    padding-bottom: 2px;
    font-size: 13px;
    border-radius: 7px;
  }
}

@media only screen and (max-width: 641px) {
  .cardTags div.tag {
    padding: 3.5px;
    padding-top: 2px;
    padding-bottom: 0px;
    font-size: 13px;
    border-radius: 6px;
  }
}

.cardTags div.tag p {
  font-size: 10px !important;
}

.cardTags div.tag .promptButton {
  height: 14px;
  width: 14px;
  top: -3px;
  right: -6px;
  text-shadow: none;
  background: #cc1166;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
}

.cardTags div.tag.pressed {
  font-weight: bold !important;
  border: 2px solid #fff;
}

.cardTags.add .cardInput {
  white-space: nowrap;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow-x: scroll;
  display: block !important;
  width: 91% !important;
}

.cardTags.add .table-cell {
  width: 7% !important;
  float: right !important;
}

.cardTags.add .table-cell button {
  padding: 5px !important;
  background-color: rgba(0, 0, 0, 0.25) !important;
}

@media only screen and (max-width: 961px) {
  .cardTags.add .table-cell button {
    padding: 4px !important;
    padding-bottom: 2px !important;
  }
}

label[for=profilePicture] {
  display: block !important;
}

button, label[for=profilePicture] {
  font-weight: bold;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 0px;
  background-color: transparent;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  padding: 1.4vh !important;
  background-color: rgba(175, 175, 175, 0.15);
}

@media only screen and (max-width: 961px) {
  button, label[for=profilePicture] {
    padding: 1.25vh !important;
  }
}

@media only screen and (max-width: 641px) {
  button, label[for=profilePicture] {
    padding: 1.25vh !important;
  }
}

button p, label[for=profilePicture] p {
  opacity: .7;
  font-size: 12px !important;
  display: inline-block !important;
  padding-left: 5% !important;
}

@media only screen and (max-width: 961px) {
  button p, label[for=profilePicture] p {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 641px) {
  button p, label[for=profilePicture] p {
    font-size: 9px !important;
  }
}

@media only screen and (max-width: 481px) {
  button p, label[for=profilePicture] p {
    font-size: 8px !important;
  }
}

button img, label[for=profilePicture] img {
  vertical-align: middle !important;
}

.thin {
  width: 9%;
}

@media only screen and (max-width: 481px) {
  .thin {
    width: 9%;
  }
}

button.thin {
  width: 40px;
}

@media only screen and (max-width: 961px) {
  button.thin {
    width: 30px;
  }
}

@media only screen and (max-width: 641px) {
  button.thin {
    width: 25px;
  }
}

.pressed-dark button, .pressed-dark label[for=profilePicture] {
  background: rgba(0, 0, 0, 0.25) !important;
  color: white !important;
}

.pressed button, .pressed label[for=profilePicture] {
  background: #cc1166 !important;
  color: white !important;
}

.premium button, .premium label[for=profilePicture] {
  background: #DAA520 !important;
  color: white !important;
}

.no-padding.table-cell {
  padding: 0px !important;
}

button.pressed {
  background: #cc1166 !important;
  color: white !important;
}

button.premium {
  background: #DAA520 !important;
  color: white !important;
}

textarea {
  margin: 0px;
  color: white;
  text-align: left;
  font-size: 20px;
  width: 100%;
  padding: 12px !important;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 14px;
  border: none !important;
  background: rgba(150, 150, 150, 0.25);
  min-height: 85px !important;
  padding-right: 42px !important;
}

.large textarea, .medium textarea, .small textarea, .mini textarea, .micro textarea {
  height: 100% !important;
}

input {
  border: none;
  background: rgba(150, 150, 150, 0.25);
  color: white;
  text-align: left;
  width: 100%;
  padding: 12px;
}

button div {
  display: inline-block !important;
}

@media only screen and (max-width: 961px) {
  button {
    font-size: 17px;
    padding: 7px;
  }
  input, textarea {
    width: 100%;
    font-size: 17px;
    padding: 10px;
  }
}

@media only screen and (max-width: 641px) {
  button {
    font-size: 14px;
    padding: 7px;
  }
  input, textarea {
    width: 100%;
    font-size: 15px;
  }
}

.fantastic-card-collection {
  right: 0;
  left: 0;
  margin: 0 auto;
}

.no-scroll {
  overflow: hidden !important;
  height: 100%;
}

.fantastic-page {
  height: 100% !important;
  /* Ensures the body takes up the full screen height */
  position: fixed !important;
  /* Prevents overscroll bounce */
  overflow-y: scroll !important;
  /* Enables vertical scrolling */
  -webkit-overflow-scrolling: touch !important;
  /* iOS velocity scrolling */
  width: 100% !important;
  background-position: center center !important;
  background-size: cover !important;
}

.fantastic-page .mask-bg-3 {
  opacity: .99 !important;
}

.fantastic-page .cardInput input {
  font-size: 20px;
  border-radius: 15px;
}

@media only screen and (max-width: 961px) {
  .fantastic-page .cardInput input {
    font-size: 17px;
    border-radius: 14px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-page .cardInput input {
    font-size: 15px;
    border-radius: 12x;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-page .cardInput input {
    font-size: 15px;
    border-radius: 10px;
  }
}

.fantastic-page .cardInput button, .fantastic-page .cardInput label[for=profilePicture] {
  text-shadow: none !important;
  border-radius: 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
}

.fantastic-page .cardInput button img, .fantastic-page .cardInput label[for=profilePicture] img {
  height: 13px;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-page .cardInput button, .fantastic-page .cardInput label[for=profilePicture] {
    font-size: 15.5px;
    line-height: 22px;
    border-radius: 10px;
  }
  .fantastic-page .cardInput button img, .fantastic-page .cardInput label[for=profilePicture] img {
    height: 12px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-page .cardInput button, .fantastic-page .cardInput label[for=profilePicture] {
    font-size: 13.5px;
    line-height: 20px;
    border-radius: 10px;
  }
  .fantastic-page .cardInput button img, .fantastic-page .cardInput label[for=profilePicture] img {
    height: 12px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-page .cardInput button, .fantastic-page .cardInput label[for=profilePicture] {
    font-size: 13.5px;
    line-height: 20px;
    border-radius: 10px;
  }
  .fantastic-page .cardInput button img, .fantastic-page .cardInput label[for=profilePicture] img {
    height: 12px;
  }
}

.fantastic-page .cardInput .create button img {
  height: 16px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-page .cardInput .create button img {
    height: 14px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-page .cardInput .create button img {
    height: 13px !important;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-page .cardInput .create button img {
    height: 13px !important;
  }
}

.userProfilePictureBackdrop {
  background: rgba(0, 0, 0, 0.25) !important;
  height: fit-content !important;
  width: fit-content !important;
  margin: 0 auto !important;
  border-radius: 50% !important;
}

.userProfilePicture {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat no-repeat !important;
  float: left !important;
  width: 20% !important;
  display: block !important;
  height: 170px !important;
  width: 170px !important;
  border-radius: 50% !important;
  margin: 0 auto !important;
}

@media only screen and (max-width: 961px) {
  .userProfilePicture {
    max-width: 100% !important;
    height: 15vh !important;
    width: 15vh !important;
  }
}

@media only screen and (max-width: 641px) {
  .userProfilePicture {
    margin-bottom: 1% !important;
    max-width: 100% !important;
    height: 26vw !important;
    width: 26vw !important;
  }
}

.userProfilePicture.center {
  float: none !important;
}

.userProfilePictureContainer {
  border-radius: 15px !important;
  height: 130px !important;
  width: 100% !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

@media only screen and (max-width: 961px) {
  .userProfilePictureContainer {
    height: 17vh !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 641px) {
  .userProfilePictureContainer {
    height: 22vw !important;
    width: 100% !important;
  }
}

.userProfilePictureContainer .userProfilePictureBackdrop {
  float: left !important;
}

.userProfilePictureContainer .userProfilePicture {
  height: 130px !important;
  width: 130px !important;
}

@media only screen and (max-width: 961px) {
  .userProfilePictureContainer .userProfilePicture {
    max-width: 100% !important;
    height: 17vh !important;
    width: 17vh !important;
  }
}

@media only screen and (max-width: 641px) {
  .userProfilePictureContainer .userProfilePicture {
    height: 22vw !important;
    width: 22vw !important;
  }
}

.userProfilePictureContainer .row, .userProfilePictureContainer .fantastic-card-collection.active .fantastic-card-row, .fantastic-card-collection.active .userProfilePictureContainer .fantastic-card-row {
  padding-left: 27% !important;
  position: absolute !important;
  bottom: 65% !important;
}

.userProfilePictureContainer .userProfilePictureEditButtons {
  position: absolute !important;
  margin: 0 auto !important;
  margin-bottom: 15px !important;
  width: 73% !important;
  right: 0 !important;
  bottom: 0 !important;
  display: table;
  table-layout: fixed;
  border-collapse: separate;
}

.userProfilePictureContainer .userProfilePictureEditButtons input#profilePicture {
  display: none !important;
}

.fantastic-feed-page, .fantastic-insights-feed-page {
  min-height: 100vh !important;
  position: relative !important;
  padding-top: 10vh !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-feed-page, .fantastic-insights-feed-page {
    padding: 2.5vh !important;
    padding-top: 10vh !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-feed-page, .fantastic-insights-feed-page {
    padding: 2.5vh !important;
    padding-top: 10vh !important;
  }
}

.fantastic-feed-page .fantastic-card.cardAccountInfo, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo {
  position: relative !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  max-width: 625px !important;
  margin: 0 auto !important;
  display: block !important;
}

.fantastic-feed-page .fantastic-card.cardAccountInfo .createButton, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .createButton {
  position: fixed;
  width: 8.25vh !important;
  height: 8.25vh !important;
  border-radius: 4vh !important;
  /* bottom: 10px !important; */
  right: 4vw;
  z-index: 1000 !important;
  bottom: 3vh;
  /* border: 1px white solid; */
  box-shadow: #222 0px 5px 15px;
}

.fantastic-feed-page .fantastic-card.cardAccountInfo .createButton button, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .createButton button {
  padding: 0px !important;
  height: 100% !important;
  width: 100% !important;
  border-radius: 50% !important;
  font-size: 2.2em;
}

.fantastic-feed-page .fantastic-card.cardAccountInfo .userProfilePictureBackdrop, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userProfilePictureBackdrop {
  float: none !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo .userProfilePictureBackdrop .userProfilePicture, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userProfilePictureBackdrop .userProfilePicture {
    float: none !important;
  }
}

@media only screen and (max-width: 961px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo {
    max-width: 100% !important;
  }
}

.fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo {
  height: fit-content !important;
  float: none !important;
  width: 100% !important;
}

.fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio {
  width: 100% !important;
  padding: 0px !important;
  padding-top: 2vh !important;
}

.fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .name, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .name {
  font-weight: bold;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  width: fit-content !important;
  font-size: 35px !important;
  margin: 0 auto !important;
  border-radius: 5px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .name, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .name {
    font-size: 28px !important;
    padding: 0px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .name, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .name {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 961px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .linkInfo, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .linkInfo {
    font-size: 28px !important;
    width: fit-content !important;
    margin: 0 auto !important;
  }
}

.fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .bio, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .bio {
  color: #ccc !important;
  text-align: left !important;
  font-weight: 100 !important;
  padding-left: 0px !important;
  font-size: 16.5px !important;
  border-radius: 5px !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  margin-top: 1.5% !important;
  display: none !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .bio, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .bio {
    font-size: 15.5px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .bio, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .bio {
    font-size: 11.5px !important;
  }
}

@media only screen and (max-width: 961px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .bio, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .userBio .bio {
    max-width: 100% !important;
  }
}

.fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .cardInput, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .cardInput {
  margin-top: 2% !important;
  width: 100% !important;
  position: absolute !important;
  bottom: 1vh !important;
  width: 100% !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .cardInput, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .cardInput {
    margin: 0 auto !important;
    position: relative !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo .cardInput, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo .cardInput {
    left: 0px !important;
    right: 0px !important;
    width: 100% !important;
  }
}

.fantastic-feed-page .fantastic-card.cardAccountInfo .userInfo button, .fantastic-insights-feed-page .fantastic-card.cardAccountInfo .userInfo button {
  display: inline-block !important;
}

.fantastic-feed-page .fantastic-card-collection.active, .fantastic-insights-feed-page .fantastic-card-collection.active {
  margin-top: 3vh !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-feed-page .fantastic-card-collection.active, .fantastic-insights-feed-page .fantastic-card-collection.active {
    margin-top: 3vh !important;
  }
}

.fantastic-insights-feed-page .fantastic-card-collection .fantastic-card-row {
  border: none !important;
}

.fantastic-insights-feed-page .fantastic-card-collection .fantastic-card-row .cardBody .cardMessage.muted {
  opacity: .65;
  max-height: none !important;
}

.fantastic-insights-feed-page .fantastic-card-collection .fantastic-card-row .cardBody .cardMessage.muted p {
  font-weight: bold !important;
  text-align: center !important;
}

.fantastic-insights-feed-page .fantastic-card-collection.active {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  margin-top: 5%;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-feed-page .fantastic-card-collection.active {
    margin-top: 8%;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-insights-feed-page .fantastic-card-collection.active {
    margin-top: 12%;
  }
}

.fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info {
  padding: 1.5%;
  padding-left: 0%;
  padding-right: 0%;
}

.fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .mask-bg, .fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .mask-bg-2, .fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .mask-bg-mobile {
  display: none !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info {
    width: 100%;
    float: none;
    padding: 3%;
    padding-left: 0%;
    padding-right: 0%;
  }
}

.fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card {
  position: relative !important;
  width: 100%;
  background: rgba(103, 103, 103, 0.25);
  border-radius: 15px;
}

.fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardSubject h1, .fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardSubject h2 {
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-inline-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  font-size: 22.5px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardSubject h1, .fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardSubject h2 {
    font-size: 18.5px !important;
  }
}

.fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardSubject img {
  height: 18px;
  width: 18px;
}

@media only screen and (max-width: 641px) {
  .fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardSubject img {
    height: 15px;
    width: 15px;
  }
}

.fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardBody {
  height: 15vh !important;
}

.fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardMessage {
  text-align: left;
}

.fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardPreview .cardTags {
  margin-top: 0px !important;
  padding: 0px !important;
}

.fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardPreview .cardTags .cardInput {
  float: none !important;
  width: 100%;
}

.fantastic-insights-feed-page .fantastic-card-collection.active .fantastic-card-row.card-type-info .fantastic-card .cardPreview .cardTags .table-cell {
  display: block !important;
}

.fantastic-insights-feed-page .fantastic-card-row.card-type-info .cardInput {
  width: 100%;
  border-radius: 10px;
}

.fantastic-insights-feed-page .fantastic-card-row.card-type-info .cardMessage {
  width: auto !important;
  margin-top: 1%;
}

.fantastic-insights-feed-page .fantastic-card-row.card-type-info .cardMessage p {
  padding: 0px;
  font-size: 14px;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-feed-page .fantastic-card-row.card-type-info .cardMessage p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-insights-feed-page .fantastic-card-row.card-type-info .cardMessage p {
    font-size: 12px;
  }
}

.fantastic-insights-feed-page .fantastic-card-row.card-type-info .cardLabel {
  margin-top: 1%;
}

.fantastic-insights-feed-page .fantastic-card-row.card-type-info .cardLabel p {
  padding: 0px;
  font-size: 17px;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-feed-page .fantastic-card-row.card-type-info .cardLabel p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-insights-feed-page .fantastic-card-row.card-type-info .cardLabel p {
    font-size: 14px;
  }
}

.fantastic-insights-feed-page .fantastic-card-row.card-type-info .cardPreview .fantastic-card-collection.active .fantastic-card-row.card-type-promo .fantastic-card .cardHeader {
  height: 50vh !important;
}

.fantastic-insights-feed-page .cardSubject {
  text-align: left !important;
  width: 100% !important;
}

.fantastic-insights-feed-page .fantastic-tags .tagField .filterBar .cardInput input {
  text-align: center !important;
}

.fantastic-insights-feed-page .tagField .filterBar .cardInput input {
  text-align: left !important;
}

.fantastic-insights-feed-page .fantastic-slider input[type='value'] {
  width: 15% !important;
  padding: 2% !important;
  vertical-align: top;
  display: inline-block;
  margin: 0 auto;
  float: right;
  text-align: center;
}

.fantastic-insights-feed-page .fantastic-slider input[type='range'] {
  padding: 0 !important;
  -webkit-appearance: none;
}

.fantastic-insights-feed-page .fantastic-slider input[type="range"]::-webkit-slider-thumb {
  background-color: #ff1166 !important;
  -webkit-appearance: none;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
}

.fantastic-insights-feed-page .fantastic-slider .slider-group {
  display: inline-block !important;
  width: 80% !important;
}

.fantastic-insights-feed-page .fantastic-slider .slider-group datalist {
  display: table;
  table-layout: fixed;
  border-collapse: separate;
  width: 104%;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-feed-page .fantastic-slider .slider-group datalist {
    font-size: 17px;
  }
}

.fantastic-insights-feed-page .fantastic-slider .slider-group datalist .option {
  font-size: 14px;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-feed-page .fantastic-slider .slider-group datalist .option {
    font-size: 12px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-insights-feed-page .fantastic-slider .slider-group datalist .option {
    font-size: 12px;
  }
}

.fantastic-insights-page {
  position: fixed;
  z-index: 1600;
  opacity: 95;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #282828 !important;
  height: 100%;
  overflow-y: scroll;
}

.fantastic-insights-page .logo {
  position: relative;
  left: 0;
  right: 0;
}

.fantastic-insights-page .logo .fantastic-tags {
  margin-top: 1%;
  position: relative !important;
}

.fantastic-insights-page .logo p {
  text-align: center;
  color: #fff;
  margin: 3px !important;
  font-size: 18px;
  opacity: .9;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-page .logo p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-insights-page .logo p {
    font-size: 14px;
  }
}

.fantastic-insights-page .logo .logo-fan {
  font-size: 65px;
  border-radius: 10px;
  display: inline-block !important;
  font-weight: 500;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-page .logo .logo-fan {
    font-size: 55px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-insights-page .logo .logo-fan {
    font-size: 45px;
  }
}

.fantastic-insights-page .logo .logo-tastic {
  font-size: 65px;
  display: inline-block !important;
  font-weight: 500;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-page .logo .logo-tastic {
    font-size: 55px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-insights-page .logo .logo-tastic {
    font-size: 45px;
  }
}

.fantastic-insights-page .logo h3 {
  padding: 0px;
  font-size: 22px;
  font-weight: 500;
  display: inline-block;
  margin: 0px;
  font-family: 'Verdana-Installed', 'Verdana', 'Lato', 'Arial' !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-page .logo {
    margin: 0px;
    padding: 0px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-insights-page .logo h3 {
    font-size: 13px;
    display: inline-block;
    margin-top: 0px;
  }
}

.fantastic-insights-page .logo-insights h3 {
  font-size: 22px;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-page .logo-insights {
    margin: 0px;
    padding: 0px;
  }
  .fantastic-insights-page .logo-insights h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-insights-page .logo-insights h3 {
    font-size: 16px;
  }
}

.fantastic-insights-page .mask-bg {
  display: none !important;
}

.fantastic-insights-page .cardSubject {
  text-align: center !important;
  width: 100% !important;
  background: rgba(0, 0, 0, 0.25) !important;
}

.fantastic-insights-page .cardBuffer p {
  font-weight: bold !important;
  text-align: center !important;
}

.fantastic-insights-page .fantastic-card {
  border-radius: 14px;
}

@media only screen and (max-width: 641px) {
  .fantastic-insights-page .fantastic-card {
    border-radius: 10px;
  }
}

.fantastic-insights-page .fantastic-card .cardSubject {
  padding: 2.5%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3) !important;
  text-align: center;
}

.fantastic-insights-page .fantastic-card .cardSubject h1, .fantastic-insights-page .fantastic-card .cardSubject h2 {
  text-shadow: none !important;
}

.fantastic-insights-page .fantastic-card .cardMessage {
  max-height: none !important;
  background: none !important;
}

.fantastic-insights-page .fantastic-card .cardInput button {
  box-shadow: none !important;
  text-shadow: none !important;
  padding: 1.25%;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
}

.fantastic-insights-page .fantastic-card .cardInput button img {
  height: 12px;
}

@media only screen and (max-width: 961px) {
  .fantastic-insights-page .fantastic-card .cardInput button {
    font-size: 17px;
    border-radius: 8px;
  }
  .fantastic-insights-page .fantastic-card .cardInput button img {
    height: 10px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-insights-page .fantastic-card .cardInput button {
    font-size: 15px;
    border-radius: 7px;
  }
  .fantastic-insights-page .fantastic-card .cardInput button img {
    height: 12px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-insights-page .fantastic-card .cardInput button {
    font-size: 14px;
    border-radius: 6px;
  }
  .fantastic-insights-page .fantastic-card .cardInput button img {
    height: 12px;
  }
}

.fantastic-insights-page .fantastic-card-collection {
  overflow: visible !important;
}

.fantastic-insights-page .fantastic-card-collection .fantastic-card-row {
  border: none !important;
}

.fantastic-insights-page .fantastic-card-collection .fantastic-card-row .cardBody .cardMessage.muted {
  opacity: .65;
  max-height: none !important;
}

.fantastic-insights-page .fantastic-card-collection .fantastic-card-row .cardBody .cardMessage.muted p {
  font-weight: bold !important;
  text-align: center !important;
}

.fantastic-insights-page .fantastic-card-collection .fantastic-card-row.card-type-info {
  padding: 0% !important;
}

.main-cta {
  position: absolute;
  top: 53%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);
}

@media only screen and (max-width: 961px) {
  .main-cta {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 641px) {
  .main-cta {
    padding-left: 5.5%;
    padding-right: 5.5%;
  }
}

@media only screen and (max-width: 641px) {
  .main-cta {
    top: 48%;
    -webkit-transform: translateY(-48%);
    -ms-transform: translateY(-48%);
    transform: translateY(-48%);
  }
}

.main-cta .cardMessage {
  text-align: center !important;
  margin: 0 auto !important;
}

@media only screen and (max-width: 641px) {
  .main-cta .cardMessage {
    max-width: 95% !important;
  }
}

.main-cta .cardMessage p {
  font-size: 28px !important;
  opacity: 1 !important;
}

@media only screen and (max-width: 641px) {
  .main-cta .cardMessage p {
    font-size: 19px;
  }
}

.main-cta .cardInput {
  margin: 0 auto !important;
  max-width: 75% !important;
}

@media only screen and (max-width: 641px) {
  .main-cta .cardInput {
    max-width: 100% !important;
  }
}

.main-cta .table-cell {
  text-align: center !important;
}

.main-cta .table-cell button {
  margin: 0 auto !important;
}

.main-cta .logo {
  margin-bottom: 3.2%;
}

@media only screen and (max-width: 961px) {
  .main-cta .logo {
    margin-bottom: 6.2%;
  }
}

@media only screen and (max-width: 641px) {
  .main-cta .logo {
    margin-bottom: 10.2%;
  }
}

.main-cta .fantastic-card .cardImage {
  display: block !important;
  margin: 0 auto !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  width: 28.5vh !important;
  height: 28.5vh !important;
}

@media only screen and (max-width: 961px) {
  .main-cta .fantastic-card .cardImage {
    width: 29vw !important;
    height: 29vw !important;
  }
}

@media only screen and (max-width: 641px) {
  .main-cta .fantastic-card .cardImage {
    width: 50vw !important;
    height: 50vw !important;
  }
}

.main-cta .fantastic-card .cardSubject h1, .main-cta .fantastic-card .cardSubject h2 {
  width: auto !important;
  white-space: break-spaces !important;
  max-width: 100% !important;
  display: block !important;
  font-size: 58px;
}

@media only screen and (max-width: 961px) {
  .main-cta .fantastic-card .cardSubject h1, .main-cta .fantastic-card .cardSubject h2 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 641px) {
  .main-cta .fantastic-card .cardSubject h1, .main-cta .fantastic-card .cardSubject h2 {
    font-size: 70px;
  }
}

.fantastic-legal-terms, .fantastic-legal-privacy, .fantastic-contact, .fantastic-faq, .fantastic-product-walkthrough, .fantastic-insights-documentation {
  height: 100%;
  width: 100%;
  padding-top: 3% !important;
  padding-bottom: 1% !important;
  position: fixed;
  z-index: 2000;
  background: #282828 !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-legal-terms, .fantastic-legal-privacy, .fantastic-contact, .fantastic-faq, .fantastic-product-walkthrough, .fantastic-insights-documentation {
    padding-top: 1.5% !important;
  }
}

.fantastic-landing-page {
  background-position: bottom center !important;
  background-size: cover !important;
  position: fixed;
  z-index: 1601 !important;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
}

.fantastic-landing-page .mask-bg-3 {
  opacity: .86 !important;
  background: radial-gradient(110em at bottom right, #cc1166, #282828, #282828) !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-landing-page .mask-bg-3 {
    background: radial-gradient(63em at bottom right, #cc1166, #282828, #282828) !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-landing-page .mask-bg-3 {
    background: radial-gradient(63em at bottom right, #cc1166, #282828, #282828) !important;
  }
}

.fantastic-landing-page .cardDismiss-bottom {
  display: block !important;
  position: fixed;
  top: 1%;
  left: 1%;
}

@media only screen and (max-width: 961px) {
  .fantastic-landing-page .cardDismiss-bottom {
    top: auto;
    left: 0;
    right: 0%;
    bottom: 1.375%;
  }
}

.fantastic-landing-page .fantastic-card-collection {
  height: auto;
}

.fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card {
  max-width: 950px !important;
}

.fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubject {
  border-radius: 15px;
  text-align: center !important;
}

.fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubject h1, .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubject h2 {
  padding: 1.75%;
  padding-bottom: 0 !important;
  text-shadow: none !important;
  font-size: 85px !important;
  margin: 0 !important;
  background: none !important;
  padding: 0px !important;
  overflow: hidden;
}

@media only screen and (max-width: 961px) {
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubject h1, .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubject h2 {
    font-size: 8.4vw !important;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubject h1, .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubject h2 {
    font-size: 8.4vw !important;
  }
}

.fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubjectSubHeader {
  border-radius: 15px;
  text-align: center !important;
}

.fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubjectSubHeader h1, .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubjectSubHeader h2 {
  color: #efefef !important;
  padding: 1.75%;
  padding-bottom: 0 !important;
  text-shadow: none !important;
  font-size: 53px !important;
  margin: 0 !important;
  background: none !important;
  padding: 0px !important;
  overflow: hidden;
}

@media only screen and (max-width: 961px) {
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubjectSubHeader h1, .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubjectSubHeader h2 {
    font-size: 7.2vw !important;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubjectSubHeader h1, .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardSubjectSubHeader h2 {
    font-size: 7.2vw !important;
  }
}

.fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardMessage {
  opacity: .88 !important;
  max-width: 750px !important;
  max-height: 100% !important;
  background: none !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border-radius: 35px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding: 10px !important;
  margin-bottom: 15px !important;
  border-radius: 35px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardMessage {
    border-radius: 28px !important;
  }
}

.fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardMessage p {
  font-weight: 350 !important;
  -webkit-line-clamp: initial !important;
  -webkit-box-orient: initial !important;
  text-overflow: initial !important;
  overflow: auto !important;
  max-height: 100% !important;
  text-align: center !important;
  color: #dfdfdf !important;
  font-size: 24px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardMessage p {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardMessage p {
    font-size: 3.55vw !important;
  }
}

.fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardInput button {
  box-shadow: none !important;
  text-shadow: none !important;
  padding: 2.05vh !important;
  margin-bottom: 1% !important;
  border-radius: 30px !important;
  font-weight: bold;
  background: rgba(150, 150, 150, 0.33);
  font-size: 25px;
}

.fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardInput button img {
  height: 12px;
}

@media only screen and (max-width: 961px) {
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardInput button {
    font-size: 21px;
    border-radius: 8px;
    padding: 1.75vh !important;
  }
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardInput button img {
    height: 10px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardInput button {
    font-size: 20px;
    border-radius: 7px;
  }
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardInput button img {
    height: 12px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardInput button {
    font-size: 16px;
    border-radius: 6px;
  }
  .fantastic-landing-page .fantastic-card-collection .fantastic-card-row .fantastic-card .cardInput button img {
    height: 12px;
  }
}

.fantastic-landing-page .fantastic-header {
  border: none !important;
  background: none !important;
}

.fantastic-landing-page .fantastic-header .options {
  width: 100%;
}

.fantastic-landing-page .fantastic-header .options .option.show-background {
  padding-left: 10px !important;
  padding-right: 10px !important;
  background: #cc1166 !important;
  font-weight: bold !important;
}

.fantastic-landing-page .fantastic-header .options .option {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-weight: bold !important;
}

.fantastic-landing-page .fantastic-header .options .logo.option {
  padding: 0px !important;
  background: none !important;
}

.fantastic-landing-page .fantastic-tags {
  background: none !important;
  padding: 0px !important;
  top: 37%;
}

.fantastic-landing-page .fantastic-tags .fantastic-card-row {
  padding-top: 0 !important;
}

.fantastic-landing-page .fantastic-tags .cardInput {
  float: none !important;
  display: inline-block !important;
  margin: 0 auto !important;
}

.fantastic-landing-page .fantastic-tags .cardInputField {
  width: 100% !important;
}

.fantastic-landing-page .logo {
  position: relative;
  left: 0;
  right: 0;
}

.fantastic-landing-page .logo .fantastic-tags {
  margin-top: 1%;
  position: relative !important;
}

.fantastic-landing-page .logo p {
  text-align: center;
  color: #fff;
  margin: 3px !important;
  font-size: 18px;
  opacity: .9;
  font-weight: 100 !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-landing-page .logo p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-landing-page .logo p {
    font-size: 13px;
  }
}

.fantastic-landing-page .logo .logo-fan {
  font-size: 70px;
  border-radius: 12px;
  display: inline-block !important;
  font-weight: 500;
}

@media only screen and (max-width: 961px) {
  .fantastic-landing-page .logo .logo-fan {
    font-size: 70px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-landing-page .logo .logo-fan {
    font-size: 45px;
  }
}

.fantastic-landing-page .logo .logo-tastic {
  font-size: 70px;
  display: inline-block !important;
  font-weight: 500;
}

@media only screen and (max-width: 961px) {
  .fantastic-landing-page .logo .logo-tastic {
    font-size: 75px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-landing-page .logo .logo-tastic {
    font-size: 45px;
  }
}

.fantastic-card-collection.relative {
  position: relative !important;
}

.fantastic-card-collection.active {
  width: 100%;
  bottom: 0;
  scroll-snap-type: y mandatory !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active {
    scroll-snap-type: y mandatory !important;
    background: none !important;
    width: 100% !important;
    height: 100%;
    margin-top: 0px !important;
    border-radius: 0px;
  }
}

.fantastic-card-collection.active .fantastic-card-row.dark .mask-bg-2 {
  display: block !important;
  background: rgba(0, 0, 0, 0.93) !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  opacity: 1 !important;
  z-index: 0 !important;
}

.fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card {
  z-index: 1600 !important;
  box-shadow: none !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 2vh !important;
  right: 2vh !important;
  width: auto !important;
}

.fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardCreator {
  opacity: 1 !important;
}

@media only screen and (max-width: 641px) {
  .fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardCreator {
    font-size: 18px !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardToolbar {
  left: 0% !important;
  opacity: 1 !important;
}

.fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardBody .cardSubject {
  width: 100%;
}

.fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardBody .cardSubject {
  font-weight: bold !important;
}

.fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardBody .cardSubject h1, .fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardBody .cardSubject h2 {
  margin: 0 !important;
  background: none !important;
  padding: 0px !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
}

@media only screen and (max-width: 641px) {
  .fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardBody .cardSubject h1, .fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardBody .cardSubject h2 {
    font-size: 16.5px !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardBody .cardSubject img {
  height: 22px;
  width: 22px;
}

@media only screen and (max-width: 641px) {
  .fantastic-card-collection.active .fantastic-card-row.dark .fantastic-card .cardBody .cardSubject img {
    height: 18px;
    width: 18px;
  }
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row.tall-background .fantastic-card {
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat no-repeat !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row.tall-background .mask-bg {
  display: block !important;
  width: auto !important;
  height: 100% !important;
  left: auto !important;
  right: 0 !important;
  opacity: 1 !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row.tall-background .mask-bg {
    display: none !important;
  }
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row.tall-background .mask-bg-mobile {
    display: none !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row.tall-background .mask-bg-2 {
  display: none !important;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7) !important;
  background-position: center center !important;
  background-repeat: no-repeat no-repeat !important;
  background-size: auto 100% !important;
  opacity: 1 !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row.tall-background .mask-bg-2 {
    opacity: 0 !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row.tall-background.dark .mask-bg-2 {
  display: none !important;
  background: rgba(0, 0, 0, 0.75) !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  opacity: 1 !important;
  z-index: 3 !important;
}

.fantastic-card-collection.active .fantastic-card-row.background-loaded .fantastic-card .cardHeader {
  background-size: cover !important;
  background-position: center center !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row.background-loaded .fantastic-card .cardHeader .mask-bg-mobile {
    visibility: visible !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row.background-none .fantastic-card .cardHeader {
  background-size: cover !important;
  background-position: center center !important;
}

.fantastic-card-collection.active .fantastic-card-row.card-type-loader {
  overflow: visible !important;
}

.fantastic-card-collection.active .fantastic-card-row {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  position: relative;
  background-position: center 38% !important;
  background-repeat: no-repeat no-repeat !important;
  background-size: 0 0 !important;
  overflow: hidden !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row .mask-bg-mobile.dark {
    visibility: hidden !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row .mask-bg {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: none !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row .mask-bg {
    top: 21% !important;
    width: 100% !important;
    height: auto !important;
    display: block !important;
    visibility: hidden !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row .mask-bg-mobile {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 2;
  display: none !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row .mask-bg-mobile {
    background-size: 100% auto !important;
    background-position: center 42% !important;
    background-repeat: no-repeat no-repeat !important;
    visibility: hidden !important;
    display: none !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row .mask-bg-2 {
  display: none !important;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  opacity: 0 !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row .mask-bg-2 {
    opacity: 1 !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar {
  position: absolute !important;
  width: 100% !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  padding-top: 8px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  white-space: nowrap !important;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  opacity: 1;
  z-index: 5 !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar p {
  opacity: 1;
  font-size: 11px;
  background: transparent;
  text-shadow: #444 1px 1px 3px !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardSubject {
  margin-bottom: 10px;
}

@media only screen and (max-width: 641px) {
  .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardSubject h1, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardSubject h2 {
    font-size: 16.5px !important;
  }
  .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardSubject p {
    font-size: 11px !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator {
  cursor: pointer !important;
  opacity: 1 !important;
  width: 100% !important;
  margin-top: 0px !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userProfilePictureBackdrop {
  margin: 0 !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userProfilePictureBackdrop .userProfilePicture {
  float: left !important;
  display: block !important;
  height: 22px !important;
  width: 22px !important;
  border-radius: 50% !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userProfilePictureBackdrop .userProfilePicture {
    max-width: 100% !important;
    height: 3vh !important;
    width: 3vh !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userProfilePictureBackdrop .userProfilePicture {
    margin-bottom: 1% !important;
    max-width: 100% !important;
    height: 5vw !important;
    width: 5vw !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo {
  color: #efefef !important;
  float: left !important;
  padding-left: .5% !important;
  max-width: 85% !important;
  overflow: hidden !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio {
  width: fit-content !important;
  margin-bottom: 1.5%;
  border-radius: 25px !important;
  padding: 7px !important;
  padding-top: 0px !important;
  padding-left: 4px !important;
  text-align: left !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio p {
  padding-top: 0px !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .name {
  font-weight: bold !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .message {
  padding-left: 6px !important;
  font-weight: 100 !important;
  color: #ddd !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .name, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .price, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .price-pending, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .price-completed {
  width: 100% !important;
  text-align: left !important;
  font-weight: bold;
  padding: 0px !important;
  padding-left: 0px !important;
  border-radius: 5px !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .price {
  font-size: 15.5px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .price {
    font-size: 15.5px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .price {
    font-size: 15px !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .bio {
  color: #ccc !important;
  text-align: left !important;
  font-weight: 100 !important;
  padding-left: 0px !important;
  font-size: 16.5px !important;
  border-radius: 5px !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  margin-top: 1.5% !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .bio {
    font-size: 15.5px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .bio {
    font-size: 13.5px !important;
  }
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardMessage .cardCreator .userInfo .userBio .bio {
    max-width: 100% !important;
  }
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardCreator, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .createdAt, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardUpdate, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardActivity,
.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardReport, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardShare, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardSource, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardActions, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardTrending {
  margin-top: 3px;
  cursor: pointer !important;
  padding: 0px;
  padding-bottom: 2px;
  border-radius: 5px;
  display: inline-block;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardActions {
  font-weight: bold !important;
  font-size: 35px !important;
  padding: 0px !important;
  margin: 0px !important;
  height: 25px !important;
  width: 25px !important;
  border-radius: 8px !important;
  background: rgba(0, 0, 0, 0.7) !important;
  padding: 5px !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardTrending {
  padding: 4px !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardActivity {
  display: none !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardToolbar .cardActivity img {
  padding-right: 2px !important;
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardInput input, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardInput textarea, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardInput button {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardInput input, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardInput textarea, .fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardInput button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.fantastic-card-collection.active .fantastic-card-row .fantastic-card .cardFooter {
  margin-top: 2px !important;
  bottom: 0;
}

#root {
  height: 100% !important;
  overflow: hidden !important;
}

.top {
  z-index: 1000;
  position: fixed;
  width: 100%;
  top: 0px;
  text-align: right;
}

.top .top-info {
  margin-right: 25px;
}

.top a {
  padding: 10px;
  font-size: 15px;
}

.modal {
  pointer-events: all !important;
  width: 100%;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0px;
  right: 0;
  z-index: 1700;
}

.modal .fantastic-card-collection {
  height: 100%;
  z-index: 2001;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}

@media only screen and (max-width: 961px) {
  .modal .fantastic-card-collection .cardInput {
    width: 100%;
  }
}

.modal .fantastic-card-collection input {
  border-color: #474747;
  box-shadow: none !important;
}

.modal .fantastic-card-collection textarea {
  border-color: #474747;
  box-shadow: none !important;
}

.modal .fantastic-card-collection button {
  border-color: #999;
  border: none !important;
  box-shadow: none !important;
}

.modal .modal-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1700;
  background-size: cover !important;
  background: #282828 !important;
  opacity: 1;
}

.modal.inline {
  position: relative;
}

.modal.super {
  z-index: 1800 !important;
}

.fantastic-card-row.card-type-info {
  max-height: 100% !important;
  overflow-y: scroll !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-info {
    max-height: 100% !important;
    overflow-y: scroll !important;
  }
}

.fantastic-card-row.card-type-info .confetti {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.fantastic-card-row.card-type-info .fantastic-card {
  height: 100%;
  padding: 1% !important;
  padding-top: 2.5% !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-info .fantastic-card {
    padding: 5% !important;
    padding-top: 20px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-info .fantastic-card {
    max-width: 100%;
  }
}

.fantastic-card-row.card-type-info .fantastic-card .cardSubject h1, .fantastic-card-row.card-type-info .fantastic-card .cardSubject h2 {
  width: auto !important;
  max-width: 100% !important;
  text-shadow: none !important;
}

.fantastic-card-row.card-type-info .fantastic-card .cardBody {
  height: 100% !important;
}

.fantastic-card-row.card-type-info .fantastic-card .cardMessage {
  border-radius: 10px !important;
  padding: 7px !important;
  padding-left: 0px !important;
}

.fantastic-card-row.card-type-info .fantastic-card .cardMessage p {
  font-size: 20px;
  -webkit-line-clamp: initial !important;
  -webkit-box-orient: initial !important;
  text-overflow: initial !important;
  overflow: auto !important;
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-info .fantastic-card .cardMessage p {
    font-size: 15px;
  }
}

.fantastic-card-row.card-type-info .fantastic-card .cardFooter {
  max-height: 90% !important;
}

.fantastic-card-row.card-type-info .fantastic-card .cardFooter .cardInput .inputLinksData {
  padding-top: 10px;
  height: fit-content;
}

.fantastic-card-row.card-type-info .fantastic-card .cardFooter .cardInput .inputLinksData .inputLinks {
  padding: 8px !important;
  margin-top: 7px;
  margin-bottom: 7px;
  z-index: 5000 !important;
  background: rgba(0, 0, 0, 0.25) !important;
  position: relative;
  height: 55vh !important;
  overflow-y: scroll !important;
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-info .fantastic-card .cardFooter .cardInput .inputLinksData .inputLinks {
    height: 50vh !important;
  }
}

.fantastic-card-row.card-type-info .fantastic-card .cardContent {
  display: none !important;
}

.fantastic-card-row.card-type-info .cardMessage {
  overflow-y: initial !important;
  max-height: initial !important;
  height: auto !important;
  padding: 0px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-info .cardMessage {
    margin-top: 5px;
  }
}

.fantastic-card-row.card-type-info .cardMessage p {
  padding-left: 0px !important;
  padding: 0px !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview {
  overflow: hidden;
  border-radius: 25px !important;
  margin-top: 1.5vh !important;
  margin-bottom: 1.5vh !important;
  position: relative !important;
  display: block !important;
  transition: height .05s linear;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .cardDismiss {
  display: none !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection.active {
  margin: 0% !important;
  min-height: auto !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection {
  position: relative !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-info .fantastic-card {
  padding: 0px !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card {
  padding: 0% !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
  padding-bottom: 4.5% !important;
  bottom: 0 !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardSubject h1, .fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardSubject h2 {
  max-width: 70% !important;
  background: none !important;
  font-size: 22px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardSubject h1, .fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardSubject h2 {
    font-size: 15px !important;
  }
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardBody {
  height: auto !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardMessage p {
  padding: 5px;
  padding-left: 0px !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardContent {
  display: block !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardContent .rect {
  height: 275px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardContent .rect {
    height: 270px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardContent .rect {
    height: 250px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardContent .rect {
    height: 190px;
  }
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardInput div.tag {
  font-size: 11px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardInput div.tag {
    font-size: 9px !important;
  }
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card input {
  font-size: 13px !important;
  margin-top: 3px;
  margin-bottom: 3px;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview.nano {
  border-radius: 15px !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview.showBackground {
  background: rgba(0, 0, 0, 0.25) !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .cardTags {
  direction: rtl !important;
  margin-top: 0px !important;
  padding: 0px !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .cardTags .cardInput {
  float: left !important;
  width: 100%;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview .cardTags .table-cell {
  display: none !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview.super-nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card, .fantastic-card-row.card-type-info .cardInput .cardPreview.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card {
  padding: 5px !important;
  padding-bottom: .5% !important;
  background: none !important;
  bottom: 0 !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview.super-nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardHeader, .fantastic-card-row.card-type-info .cardInput .cardPreview.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardHeader {
  position: absolute !important;
  height: 100% !important;
  z-index: -1 !important;
  top: 0 !important;
  left: 0 !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview.super-nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardBody, .fantastic-card-row.card-type-info .cardInput .cardPreview.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardBody {
  padding: 0px !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview.super-nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardSubject, .fantastic-card-row.card-type-info .cardInput .cardPreview.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardSubject {
  margin-bottom: 0px !important;
}

@supports (-webkit-line-clamp: 2) {
  .fantastic-card-row.card-type-info .cardInput .cardPreview.super-nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardSubject, .fantastic-card-row.card-type-info .cardInput .cardPreview.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardSubject {
    white-space: initial !important;
    display: -webkit-inline-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
  }
}

.fantastic-card-row.card-type-info .cardInput .cardPreview.super-nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardMessage, .fantastic-card-row.card-type-info .cardInput .cardPreview.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardMessage {
  display: none !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview.super-nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardTags, .fantastic-card-row.card-type-info .cardInput .cardPreview.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardTags {
  position: relative !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
}

.fantastic-card-row.card-type-info .cardInput .cardPreview.super-nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardTags div.tag, .fantastic-card-row.card-type-info .cardInput .cardPreview.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardTags div.tag {
  font-size: 15px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-info .cardInput .cardPreview.super-nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardTags div.tag, .fantastic-card-row.card-type-info .cardInput .cardPreview.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardTags div.tag {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-info .cardInput .cardPreview.super-nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardTags div.tag, .fantastic-card-row.card-type-info .cardInput .cardPreview.nano .fantastic-card-collection .fantastic-card-row.card-type-promo .fantastic-card .cardTags div.tag {
    font-size: 13px !important;
    border-radius: 6px;
  }
}

.fantastic-card-row.card-type-info.stack {
  height: auto !important;
}

.fantastic-card-row.expand {
  width: 100% !important;
  max-width: 100% !important;
}

.fantastic-card-row.expand .fantastic-card {
  width: 100% !important;
  max-width: 100% !important;
}

.fantastic-card-row.card-type-promo {
  position: relative !important;
  height: 100% !important;
}

.fantastic-card-row.card-type-promo .fantastic-card {
  background: #151515 !important;
  height: 100% !important;
  width: 100% !important;
  height: fit-content !important;
  border-radius: 25px !important;
  border-radius: 6px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-promo .fantastic-card {
    left: 0 !important;
    right: 0 !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    bottom: 0 !important;
    height: fit-content !important;
    border-radius: 18px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-promo .fantastic-card {
    height: fit-content !important;
  }
}

.fantastic-card-row.card-type-promo .fantastic-card .cardHeader {
  z-index: 2;
  height: 50vh !important;
  position: relative !important;
  background-size: cover !important;
  background-position: center center !important;
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-promo .fantastic-card .cardHeader {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-promo .fantastic-card .cardHeader {
    height: 50vh !important;
  }
}

.fantastic-card-row.card-type-promo .fantastic-card .cardToolbar {
  position: absolute !important;
  width: 100% !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  padding-top: 8px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  white-space: nowrap !important;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  opacity: 1;
  z-index: 5 !important;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardToolbar .cardActions {
  font-weight: bold !important;
  font-size: 35px !important;
  padding: 0px !important;
  margin: 0px !important;
  height: 25px !important;
  width: 25px !important;
  border-radius: 8px !important;
  background: rgba(0, 0, 0, 0.7) !important;
  padding: 5px !important;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardSubject {
  font-weight: bold !important;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardSubject h1, .fantastic-card-row.card-type-promo .fantastic-card .cardSubject h2 {
  background: none !important;
  text-shadow: #333 2px 2px 3px !important;
  padding-left: 0px !important;
  color: #fff !important;
  font-size: 20.5px !important;
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-promo .fantastic-card .cardSubject h1, .fantastic-card-row.card-type-promo .fantastic-card .cardSubject h2 {
    font-size: 18.5px !important;
  }
}

.fantastic-card-row.card-type-promo .fantastic-card .cardSubject img {
  height: 22px;
  width: 22px;
  padding-bottom: 2px !important;
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-promo .fantastic-card .cardSubject img {
    height: 18px;
    width: 18px;
  }
}

.fantastic-card-row.card-type-promo .fantastic-card .cardInput input, .fantastic-card-row.card-type-promo .fantastic-card .cardInput textarea, .fantastic-card-row.card-type-promo .fantastic-card .cardInput button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardInput button {
  background: rgba(45, 45, 45, 0.65) !important;
  text-shadow: none !important;
  border-radius: 15px;
  padding: 1vh !important;
  font-size: 17px;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardInput button img {
  height: 17px;
  margin: 5px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-promo .fantastic-card .cardInput button {
    font-size: 17px;
    border-radius: 10px;
  }
  .fantastic-card-row.card-type-promo .fantastic-card .cardInput button img {
    height: 17px;
  }
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-promo .fantastic-card .cardInput button {
    font-size: 17px;
    border-radius: 10px;
  }
  .fantastic-card-row.card-type-promo .fantastic-card .cardInput button img {
    height: 17px;
  }
}

@media only screen and (max-width: 481px) {
  .fantastic-card-row.card-type-promo .fantastic-card .cardInput button {
    font-size: 17px;
    border-radius: 10px;
  }
  .fantastic-card-row.card-type-promo .fantastic-card .cardInput button img {
    height: 15px;
  }
}

.fantastic-card-row.card-type-promo .fantastic-card .cardInput button div {
  font-weight: 800 !important;
  color: white !important;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardInput div.pressed button {
  background: #cc1166 !important;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardBody {
  padding: 12px !important;
  padding-right: 10px !important;
  height: 43% !important;
  max-height: 100% !important;
  overflow-y: scroll !important;
  width: 100% !important;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-promo .fantastic-card .cardBody {
    padding-right: 5px !important;
  }
}

.fantastic-card-row.card-type-promo .fantastic-card .cardBody .cardMessage {
  border-radius: 0px !important;
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 15px !important;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardBody .cardMessage p {
  text-shadow: #333 2px 2px 3px !important;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardTags {
  opacity: .70;
  display: block !important;
  width: 100% !important;
  position: absolute !important;
  bottom: 52px !important;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardTags div.tag {
  font-size: 12px;
}

@media only screen and (max-width: 961px) {
  .fantastic-card-row.card-type-promo .fantastic-card .cardTags div.tag {
    font-size: 11px !important;
  }
}

.fantastic-card-row.card-type-promo .fantastic-card .cardBuffer {
  display: none !important;
}

.fantastic-card-row.card-type-promo .fantastic-card .cardFooter {
  position: relative !important;
  bottom: 1% !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

@media only screen and (max-width: 641px) {
  .fantastic-card-row.card-type-promo .fantastic-card .cardFooter {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.fantastic-card-row.card-type-info .fantastic-card-row.card-type-loader .fantastic-card {
  padding: 0px !important;
}

.fantastic-card-row.card-type-loader {
  min-height: 5vh;
  height: 100% !important;
}

.fantastic-card-row.card-type-loader .mask-bg {
  display: none !important;
}

.fantastic-card-row.card-type-loader.stack {
  height: auto !important;
}

.fantastic-card-row.card-type-loader.stack .fantastic-card {
  position: relative !important;
}

p.row.input, .fantastic-card-collection.active p.input.fantastic-card-row {
  font-size: 16px !important;
}

@media only screen and (max-width: 961px) {
  p.row.input, .fantastic-card-collection.active p.input.fantastic-card-row {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 641px) {
  p.row.input, .fantastic-card-collection.active p.input.fantastic-card-row {
    font-size: 12px !important;
  }
}

p {
  font-size: 14px;
  margin: 0;
  text-align: left;
}

@media only screen and (max-width: 961px) {
  p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 641px) {
  p {
    font-size: 12px;
  }
}

p.highlight {
  color: #cc1166;
}

.content {
  opacity: .9;
  margin: 0;
  top: 50px;
  left: 0;
  right: 0;
}

.rect {
  box-shadow: 0px 0px 400px 25px #222;
  width: 100% !important;
  min-height: 32.5vh !important;
  margin: 0 auto !important;
}

.card-rows-header {
  margin: 2.25% !important;
  font-size: 35px !important;
  margin-bottom: 1.5% !important;
  text-align: center;
  display: none;
}

@media only screen and (max-width: 961px) {
  .card-rows-header {
    margin: 2.5% !important;
  }
}

.card-row {
  border-radius: 18px !important;
  padding-top: 1.25% !important;
  max-width: 625px !important;
  margin: 3% !important;
  margin-top: 1% !important;
  margin: 0 auto !important;
  height: 46% !important;
}

@media only screen and (max-width: 961px) {
  .card-row {
    max-width: 100% !important;
  }
}

.card-row h3 {
  font-size: 22px !important;
  margin: 0px !important;
  margin-bottom: 3.25% !important;
  cursor: pointer !important;
  border-radius: 15px !important;
  width: 100% !important;
  text-align: center !important;
  padding: 10px !important;
  color: #fff !important;
  font-weight: bold !important;
  background: rgba(10, 10, 10, 0.25) !important;
}

@media only screen and (max-width: 961px) {
  .card-row h3 {
    border-radius: 15px !important;
  }
}

.card-row h3.no-posts-found {
  font-size: 25px !important;
  margin: 0 auto !important;
  padding: 2.5vw !important;
  border-radius: 10px !important;
  font-weight: 400 !important;
  max-width: 625px !important;
  width: 100% !important;
  color: #fff !important;
  background: rgba(103, 103, 103, 0.25) !important;
  font-weight: 500 !important;
  text-align: left !important;
}

@media only screen and (max-width: 961px) {
  .card-row h3.no-posts-found {
    font-size: 19px !important;
    width: 100% !important;
    max-width: 100% !important;
    text-align: center !important;
  }
}

@media only screen and (max-width: 641px) {
  .card-row h3.no-posts-found {
    font-size: 17px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.card-row .card-row-cards {
  margin-right: -1% !important;
  border-radius: 4px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  height: 100% !important;
  overflow-y: hidden !important;
}

.card-row .card-row-cards .fantastic-card-row.card-type-promo, .card-row .card-row-cards .fantastic-card-row.card-type-loader {
  margin-bottom: 4.5% !important;
  height: 100% !important;
  width: 100% !important;
  display: inline-block !important;
  overflow: hidden !important;
  height: fit-content !important;
}

@media only screen and (max-width: 641px) {
  .card-row .card-row-cards .fantastic-card-row.card-type-promo, .card-row .card-row-cards .fantastic-card-row.card-type-loader {
    margin-bottom: 5vh !important;
  }
}

.card-row .card-row-cards .fantastic-card-row.card-type-loader {
  height: 70vh !important;
  border-radius: 25px !important;
  background: #222 !important;
}

@media only screen and (max-width: 961px) {
  .card-row {
    margin: 0px !important;
    margin-bottom: 6% !important;
    height: 38% !important;
  }
  .card-row h3 {
    font-size: 24px !important;
    margin: 0px !important;
    margin-bottom: 3.5% !important;
  }
  .card-row .card-row-cards {
    overflow-x: hidden !important;
  }
  .card-row .card-row-cards .fantastic-card-row.card-type-promo, .card-row .card-row-cards .fantastic-card-row.card-type-loader {
    margin-right: 1% !important;
    height: 100% !important;
    width: 100% !important;
    display: inline-block !important;
    overflow: hidden !important;
    height: fit-content !important;
  }
  .card-row .card-row-cards .fantastic-card-row.card-type-loader {
    height: 60vh !important;
  }
}

@media only screen and (max-width: 641px) {
  .card-row {
    height: 15% !important;
    margin-bottom: 0% !important;
  }
  .card-row h3 {
    font-size: 18px !important;
    margin: 0px !important;
    margin-bottom: 3.5% !important;
  }
  .card-row .card-row-cards .fantastic-card-row.card-type-promo, .card-row .card-row-cards .fantastic-card-row.card-type-loader {
    width: 100% !important;
    height: 100% !important;
    display: inline-block !important;
    overflow: hidden !important;
    height: fit-content !important;
  }
  .card-row .card-row-cards .fantastic-card-row.card-type-loader {
    height: 5vh !important;
  }
}

.fantastic-card-collection.active.isScrolling .card-row-cards {
  overflow-x: hidden !important;
}

iframe {
  display: block;
  border: none;
  overflow: hidden;
}
